<template>
  <div class="flex flex-column items-start gap-2">
    <div
      class="flex flex-row justify-between items-center w-full"
      v-if="props.label"
    >
      <span
        class="text-sm text-black font-medium capitalize w-full"
        :class="{ 'text-alert': errorMessage }"
        >{{ props.label }}</span
      >
      <slot />
    </div>

    <div class="flex flex-wrap gap-3" :class="props.checkboxListClass">
      <div
        class="flex align-items-center"
        v-for="checkbox in props.options"
        :key="checkbox.value"
      >
        <RadioButton
          v-if="props.type === 'radioButton'"
          :id="props.name"
          v-model="inputValue"
          :inputId="checkbox.label"
          :name="checkbox.label"
          :value="checkbox.value"
        />
        <Checkbox
          v-if="props.type === 'checkbox'"
          v-model="inputValue"
          :id="props.name"
          :inputId="checkbox.label"
          :name="checkbox.label"
          :value="checkbox.value"
        >
          <template #icon="{ checked }">
            <div
              :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
            >
              <base-vite-icon
                v-if="checked"
                name="checkbox-selected-noborder"
                classes="w-[24px] h-[24px] stroke-primary stroke-2"
              />
            </div>
          </template>
        </Checkbox>

        <label
          :for="checkbox.label"
          class="ml-2 text-base text-black font-normal"
          >{{ checkbox.label }}</label
        >
      </div>
    </div>
    <small
      v-if="errorMessage && props.showErrorMessage"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import RadioButton from "primevue/radiobutton";
import { useField } from "vee-validate";
import { toRefs, watchEffect } from "vue";
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  options: {
    type: Object,
    default: null
  },
  value: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "radioButton"
  },
  checkboxListClass: {
    type: String,
    default: "flex-row"
  },
  showErrorMessage: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const { name, value } = toRefs(props);
//TODO:VALIDATION CHECKING
const { value: inputValue, errorMessage } = useField(name, undefined, {
  initialValue: value,
  validateOnMount: true
});

watchEffect(() => {
  emit("update:modelValue", inputValue);
});
</script>

<style lang="scss" scoped></style>
