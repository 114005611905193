<template>
  <div class="!inline-block">
    <div class="flex flex-wrap gap-3" :class="checkboxListClass">
      <RadioButton
        v-if="type === 'radioButton'"
        :id="name"
        v-model="inputValue"
        :inputId="label"
        :name="label"
      />
      <Checkbox
        v-if="type === 'checkbox'"
        v-model="inputValue"
        :id="name"
        :inputId="label"
        :name="label"
        :binary="true"
        input-class="w-5 h-5"
      >
        <template #icon="{ checked }">
          <div
            :class="`flex items-center justify-center w-5 h-5 border-2 rounded-md ${checked ? ' border-primary' : 'border-grey5'}`"
          >
            <base-vite-icon
              v-if="checked"
              name="checkbox-selected-noborder"
              classes="w-5 h-5 stroke-primary stroke-2"
            />
          </div>
        </template>
      </Checkbox>

      <label
        v-if="props.label"
        :for="name"
        class="ml-2 text-base text-black font-normal"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  modelValue: {
    type: [Boolean, String],
    default: false
  },
  name: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: "radioButton"
  },
  checkboxListClass: {
    type: String,
    default: "flex-row"
  }
});

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(props.modelValue);

watch(inputValue, (newVal) => {
  emit("update:modelValue", newVal);
});

watch(
  () => props.modelValue,
  (newVal) => {
    inputValue.value = newVal;
  }
);
</script>

<style lang="scss" scoped></style>
