import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { format } from "date-fns";
import { getApp } from "firebase/app";
import {
  getStorage,
  listAll,
  ref,
  getDownloadURL,
  getMetadata
} from "firebase/storage";
export async function createComment(data) {
  try {
    const validationRules = {
      context: {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: [
            "Application Notes (Why Fit)",
            "Internal Corporate Comment",
            "Internal Rejection to Startup",
            "Rialto Rejection",
            "Message to Startup",
            "MTB Internal Notes",
            "MTB Notes",
            "Public Rejection to Startup",
            "Rejection To Startup Application"
          ]
        }
      },
      text: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-createComment"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function updateComment(data) {
  try {
    const validationRules = {
      id: {
        presence: { allowEmpty: false },
        type: "string"
      },
      text: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-updateComment"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteComment(data) {
  try {
    const validationRules = {
      id: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-deleteComment"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getComments(data) {
  try {
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-getComments"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDocumentsComment(data) {
  try {
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);

    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    // Get the Firebase Storage instance
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, "gs://mtb_pitch-decks_opps");
    // Reference to the folder in Firebase Storage
    const folderRef = ref(storage, `/${data.startupId}`);

    // File list in the folder
    const folderSnapshot = await listAll(folderRef);

    // Filter the items to find the one with the correct challengeId
    const fileRef = folderSnapshot.items.find((itemRef) =>
      itemRef.name.includes(data.challengeId)
    );

    // If fileRef is null, the file was not found
    if (!fileRef) {
      return;
    }

    // Get metadata
    const metadata = await getMetadata(fileRef);

    return [
      {
        Contact__c: null,
        //DEBT: email
        Context__c: "Opportunity Pitch-deck",
        MTBer_Email__c: "scouting@minthebridge.org",
        SystemModstamp: metadata.timeCreated,
        Text__c: "pitch-deck.pdf",
        Pitcheck_URL: `/${data.startupId}/${fileRef.name}`
      }
    ];
  } catch (error) {
    console.error("error:", error);
  }
}

export async function getApplicationNotes(data) {
  try {
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-getApplicationNotes"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function timestampToUtc(timestamp) {
  if (!timestamp || !timestamp.seconds || !timestamp.nanoseconds) {
    const currentDate = new Date();
    return format(currentDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  }

  const milliseconds =
    timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000;
  const date = new Date(milliseconds);
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}
