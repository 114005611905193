import { computed, watch, onUnmounted, onMounted, watchEffect } from "vue";
import { bus } from "@/main.js";
import { convertFiltersToUrlQuery } from "@global/helpers/url.js";
export function useFilterLogic(
  filters,
  showFilters,
  updateFilterFromModalCallback,
  disable
) {
  const filterValue = computed(() => filters?.value || filters);

  const numberOfFilters = computed(() => {
    if (!filterValue.value) {
      return 0;
    }
    return Object.values(filterValue.value).reduce((acc, curr) => {
      if (curr.value && curr.value.length > 0) {
        acc++;
      }
      return acc;
    }, 0);
  });

  const forceExport = () => {
    if (numberOfFilters.value > 0) {
      bus.emit("filter-applied", numberOfFilters.value);
    }
  };
  const openFilterModal = (selectedCategory) => {
    showFilters(filterValue.value, selectedCategory);
  };

  watch(
    () => numberOfFilters.value,
    () => {
      bus.emit("filter-applied", numberOfFilters.value);
    },
    { immediate: true }
  );

  onUnmounted(() => {
    if (disable) {
      return;
    }
    bus.off("show-filter-modal");
    bus.off("update-filter-from-modal");
  });

  onMounted(() => {
    if (disable) {
      return;
    }
    bus.on("show-filter-modal", () => {
      showFilters(filterValue.value);
    });
    bus.on("update-filter-from-modal", (data) => {
      if (updateFilterFromModalCallback) {
        updateFilterFromModalCallback();
      }
      if (Object.keys(data)?.length > 0) {
        Object.keys(data).forEach((field) => {
          if (filterValue.value?.[field]) {
            filters.value[field].value = data?.[field];
          }
        });
      } else {
        Object.keys(filterValue.value).forEach((field) => {
          if (filterValue.value?.[field]) {
            filters.value[field].value = null;
          }
        });
      }
    });

    // bus.on("onTabChange")
  });
  return {
    forceExport,
    openFilterModal,
    //TESTING
    filterValue,
    numberOfFilters
  };
}
