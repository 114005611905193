export const demoInstitutionSponsoredStartups = [
  {
    accepted: true,
    active: true,
    companyId: "0014U00002ye6qxQAA",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002ye8Y6QAI",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002rKNaVQAW",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002o00G0QAI",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002l8n94QAA",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002l5zMEQAY",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002sPEo1QAG",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002q4WYoQAM",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00002SaNxyQAF",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00002dPuHIQA0",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002q4WZNQA2",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0014U00002q4WZDQA2",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  }
];

export const demoInstitutionSponsoredCorporates = [
  {
    accepted: true,
    active: true,
    companyId: "0014U00002f6S74QAE",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00002E4gcJQAR",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E000024zzwOQAQ",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00001z2wdsQAA",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00001z2weRQAQ",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00002dzQWJQA2",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00001z2wj2QAA",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  },
  {
    accepted: true,
    active: true,
    companyId: "0012E00002E4WSWQA3",
    expiringDate: null,
    sponsorId: "0014U000035na4QQAQ",
    sponsorName: "Venice Innovation Agency"
  }
];

export const demoInstitutionAnalyticsHomepage = [
  { label: "Global Exposure", metrics: "views", count: 2621 },
  { label: "International Exposure", metrics: "countries", count: 27 },
  {
    label: "Business & Innovation Potential",
    metrics: "opportunities",
    count: 256
  },
  { label: "Esitmated Business Outcomes", metrics: "pilots", count: 6 }
];

export const demoInstitutionAnalyticsStartup = {
  businessOpportunities: {
    label: "Business Opportunities",
    metrics: "applications",
    count: 191,
    series: [7, 12, 15, 18, 9, 13, 10, 12, 8, 15, 10, 18, 20, 15, 9]
  },
  businessResults: {
    label: "Business Results",
    metrics: "meetings",
    count: 53,
    series: [0, 1, 3, 4, 3, 5, 2, 4, 1, 6, 4, 7, 5, 6, 2]
  },
  businessPotential: {
    label: "Business Potential",
    metrics: "opportunities",
    count: 231,
    series: [6, 8, 10, 9, 13, 15, 13, 17, 18, 16, 21, 25, 19, 15, 26]
  },
  businessDevelopment: {
    label: "Innovation Push",
    metrics: "suggestions",
    count: 89
  },
  startupsGlobalExposure: {
    label: "Startup Global Exposure",
    metrics: "views",
    count: 1532
  },
  industryExposure: {
    label: "Industry Exposure",
    metrics: "corporates",
    count: 67
  },
  internationalExposure: {
    label: "International Exposure",
    metrics: "countries",
    count: 18,
    series: [
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "France"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "China"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "China"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Finland"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "China"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "China"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "China"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "China"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "China"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United States"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Japan"
      },
      {
        collection: "France"
      },
      {
        collection: "China"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "China"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "China"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "China"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "China"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Canada"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "China"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      },
      {
        collection: "China"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "China"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "China"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "China"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Japan"
      },
      {
        collection: "China"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "China"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "China"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "China"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Canada"
      },
      {
        collection: "China"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Japan"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "China"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Japan"
      },
      {
        collection: "United States"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "China"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Japan"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "China"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "China"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Japan"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "China"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "China"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "China"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "France"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "France"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "China"
      },
      {
        collection: "China"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Colombia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Singapore"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Japan"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Canada"
      },
      {
        collection: "Australia"
      }
    ]
  },
  businessOutcomes: {
    label: "Esitmated  Business Outcomes",
    metrics: "pilots",
    count: 24
  },
  economicImpact: {
    label: "Estimated Economic Impact",
    metrics: "dollars",
    count: "675K - 7.15M"
  }
};

export const demoInstitutionAnalyticsCorporate = {
  innovationPush: {
    label: "Innovation Push",
    metrics: "challenges",
    count: 32
  },
  innovationPotential: {
    label: "Innovation Potential",
    metrics: "opportunities",
    count: 523,
    series: [27, 28, 31, 34, 30, 38, 35, 30, 31, 33, 37, 40, 35, 48, 46]
  },
  innovationResults: {
    label: "Innovation Results",
    metrics: "meetings",
    count: 45,
    series: [0, 0, 2, 1, 3, 5, 2, 3, 5, 3, 5, 7, 4, 3, 2]
  },
  corporateGlobalExposure: {
    label: "Corporate Global Exposure",
    metrics: "views",
    count: 1089
  },
  internationalExposure: {
    label: "International Exposure",
    metrics: "countries",
    count: 14,
    series: [
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "France"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "France"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Australia"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Australia"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Spain"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United States"
      },
      {
        collection: "France"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "France"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "France"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Spain"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "United States"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Italy"
      },
      {
        collection: "France"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "United States"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Switzerland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "France"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "United States"
      },
      {
        collection: "Italy"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United States"
      },
      {
        collection: "Denmark"
      },
      {
        collection: "Finland"
      },
      {
        collection: "United States"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Sweden"
      },
      {
        collection: "France"
      },
      {
        collection: "Germany"
      },
      {
        collection: "Finland"
      },
      {
        collection: "Netherlands"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Korea, South"
      },
      {
        collection: "Australia"
      },
      {
        collection: "Italy"
      },
      {
        collection: "United States"
      },
      {
        collection: "Spain"
      },
      {
        collection: "United Kingdom"
      },
      {
        collection: "Finland"
      }
    ]
  },
  industryExposure: {
    label: "Industry Diversification",
    metrics: "technologies",
    count: 557,
    tooltip:
      "Number of technology verticals your corporates have been exposed to."
  },
  businessOutcomes: {
    label: "Esitmated  Business Outcomes",
    metrics: "pilots",
    count: 37,
    tooltip:
      "Number of estimated PoCs that were facilitated through the platform for your corporates."
  },
  businessImpact: {
    label: "Business Impact",
    metrics: "adoptions",
    count: 4,
    tooltip:
      "Number of estimated industrializations from collaboration with startups."
  }
};
