<template>
  <div
    class="mtb-card cursor-pointer"
    :class="size"
    @click.stop="click(props.companyId)"
  >
    <!-- === Logo ==== -->
    <base-logo
      v-if="props.cardType != 'featured'"
      classes="w-20 h-20"
      :companyId="companyId"
    />
    <div
      v-else
      class="w-20 h-20 bg-grey3 rounded flex items-center justify-center overflow-hidden"
    >
      <img
        :id="companyId"
        ref="imageRef"
        :src="`https://firebasestorage.googleapis.com/v0/b/mtb_featured_list_logos/o/logo_${props.subTitle == 'Partner' ? 'partner' : props.subTitle == 'Research' ? 'mtbcurated' : 'event'}.png?alt=media`"
        class="object-cover max-h-full max-w-full"
      />
    </div>
    <!-- === Right container ==== -->
    <div
      class="flex flex-col justify-between items-center h-20"
      style="width: calc(100% - 80px - 16px)"
    >
      <!-- === Title + subtitle === -->
      <div
        :class="`flex items-start gap-0.5 self-stretch ${
          props.cardType == 'featured' ? 'flex-col-reverse' : 'flex-col'
        }`"
      >
        <!-- === Title + sponsorship === -->
        <div class="flex items-center gap-1.5 self-stretch">
          <div class="text-lg font-bold text-black truncate capitalize">
            {{ props.title }}
          </div>
          <base-vite-icon
            v-if="props.sponsored"
            name="award"
            classes="fill-secondary stroke-white w-4 h-4"
          />
        </div>
        <span
          v-if="props.cardType == 'corporate' && props.sponsored"
          class="text-grey2 text-base font-normal text-left"
          >{{ `Partnership with ${props.subTitle}` }}</span
        >
        <span
          v-else-if="props.cardType == 'institution'"
          class="text-grey2 text-base font-normal text-left"
        >
          {{ props.subTitle }}
        </span>
        <span
          v-else-if="props.cardType == 'featured'"
          :class="`mtb-chip font-bold text-sm ${
            featuredListMapping[props.subTitle]?.parentClasses ||
            featuredListMapping.default.parentClasses
          }`"
        >
          {{
            featuredListMapping[props.subTitle]?.label ||
            currentFeaturedList?.List_Type__c
          }}
        </span>
      </div>
      <!-- === Footer === -->
      <div class="flex justify-between w-full items-center">
        <span class="text-sm text-grey2 font-normal">{{
          props.footerCaption
        }}</span>

        <base-vite-icon
          class="w-1/5"
          name="arrow-short-right"
          classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
          role="button"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { bus } from "@/main.js";
import { featuredListMapping } from "../configs/featured-list-mapping.js";
import { useRouter } from "vue-router";
const props = defineProps({
  //TODO: default value now is only for test
  companyId: {
    type: String,
    required: true,
    default: ""
  },
  size: {
    type: String,
    default: "card-md"
  },
  title: {
    type: String,
    required: true,
    default: "Testing title cbdsbdsfbdfdfbdfbdnfb"
  },
  subTitle: {
    type: String,
    default: "Subtitle here"
  },
  cardType: {
    type: String,
    default: "corporate"
  },
  sponsored: {
    type: Boolean,
    default: false
  },
  footerCaption: {
    type: String,
    default: "Footer caption here"
  },
  //TODO: replace default with null value(this is only for test)
  chipConfig: {
    type: Object,
    default: () => ({
      label: "Test chip dfhdbfhdfhdghhf",
      chipClasses: "bg-data1-light",
      icon: "ai-sparkle",
      iconClasses: "fill-data1 w-4 h-4"
    }),
    validator: (value) => {
      if (value) {
        return ["label", "chipClasses", "icon", "iconClasses"].every(
          (key) => key in value
        );
      }
    }
  },
  forceRouting: {
    type: Object,
    default: () => null
  },
  target: {
    type: String,
    default: "_self"
  }
});
const router = useRouter();
const arrowAction = (id) => {
  bus.emit("open-account-detail-sidebar", {
    id: id,
    accountType: props.cardType
  });
};
function click(companyId) {
  if (!props.forceRouting) {
    arrowAction(companyId);
  } else {
    router.push(props.forceRouting);
  }
}
</script>

<style lang="scss" scoped></style>
