<template>
  <div
    :class="[
      'dropzone w-full text-center relative bg-grey4 p-6 cursor-pointer',
      deckDragOver || isUploaded
        ? 'drag-enter bg-primary-light'
        : 'drag-leave bg-grey4'
    ]"
  >
    <div
      v-if="!isUploaded"
      class="flex items-center justify-center flex-col gap-y-3 text-grey2 text-base h-full w-full cursor-pointer"
    >
      <base-button
        label="Upload from files"
        icon="document"
        iconClass="stroke-primary stroke-2 w-4 h-4"
        severity="primary"
        :text="true"
      />
      <span> Or </span>
      <p class="font-bold">Drag it here.</p>

      <input
        title="Upload new Pitch Deck"
        type="file"
        accept="application/pdf"
        style="opacity: 0"
        class="absolute top-0 left-0 w-full h-full cursor-pointer"
        :disabled="
          pdfPreview['fileName'] && pdfPreview['fileName'].length !== 0
        "
        @change="(e) => uploadNewPitchdeck(e)"
        @dragenter="deckDragOver = true"
        @dragleave="deckDragOver = false"
      />
    </div>

    <div v-if="isUploaded" class="w-full">
      <div class="flex flex-row items-center justify-between w-full">
        <div
          class="flex gap-x-2 items-center"
          @click="openPreview"
          role="button"
        >
          <base-vite-icon
            name="document"
            classes="stroke-primary stroke-2 w-6 h-6 "
          />
          <span
            id="preview_pdf"
            class="text-primary text-left"
            style="cursor: pointer"
            >{{ pdfPreview["fileName"].toLowerCase() }}</span
          >
        </div>

        <base-vite-icon
          v-if="props.canEdit"
          role="button"
          name="close"
          classes="stroke-primary stroke-2 w-6 h-6 cursor-pointer"
          @click="deleteFile"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { bus } from "@/main.js";
import { ref, computed, watchEffect, watch, onMounted } from "vue";
import { getApp } from "firebase/app";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL
} from "firebase/storage";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useToast } from "primevue/usetoast";
import { userErrorHandling } from "../hooks/use-error-handling.js";

const userAuthStore = useUserAuthStore();
const { errorManager } = userErrorHandling();
const toast = useToast();
const props = defineProps({
  existingPitchdeckDetails: {
    type: Object,
    default: () => {}
  },
  storageName: {
    type: String,
    default: "gs://mtb_pitch-decks_opps"
  },
  canEdit: { type: Boolean, default: true }
});
const emits = defineEmits([
  "update:modelValue",
  "uploadedPitchdeck",
  "removedPitchdeck"
]);
const pdfPreview = ref({});
const deckDragOver = ref(false);
const newPitchdeck = ref({});
const isLoading = ref(false);
const isUploaded = computed(() => {
  return (
    pdfPreview.value?.linkPreview && pdfPreview.value.fileName.length !== 0
  );
});
const isDemoAccount = computed(() => {
  return userAuthStore.getters.isDemoAccount;
});
watchEffect(() => {
  emits("update:modelValue", newPitchdeck.value);
});
watch(
  () => props.existingPitchdeckDetails?.pitchdeckURL,
  (pitchdeck) => {
    const pitchdeckURL = pitchdeck && pitchdeck !== "null" ? pitchdeck : "";
    if (pitchdeckURL && pitchdeckURL?.length !== 0) {
      const pitchdeckName = pitchdeckURL.split("/")[1].split("_");
      pdfPreview.value.fileName = pitchdeckName
        .filter((ele, index) => index !== pitchdeckName.length - 2)
        .join("_");
      pdfPreview.value.linkPreview = pitchdeckURL;
    }
  },
  { immediate: true }
);
function deleteFile() {
  pdfPreview.value = {};
  newPitchdeck.value = {};
  emits("removedPitchdeck");
}

async function openPitchDeck(pitchdeckURL) {
  if (!pitchdeckURL) return;
  try {
    const firebaseApp = getApp();
    const storageBucket = isDemoAccount.value
      ? "gs://mtb_pitch-decks"
      : props.storageName;
    const storage = getStorage(firebaseApp, storageBucket);

    // Adjust the file path based on whether it's a demo account or not
    const filePath = isDemoAccount.value
      ? "/0014U000036I4rQQAS/SunSpark_2023-07-20"
      : pitchdeckURL;
    const fileRef = storageRef(storage, filePath);
    const url = await getDownloadURL(fileRef);
    window.open(url, "_blank");
  } catch (error) {
    errorManager({
      error,
      functionPath: "openPitchDeck",
      userErrorMessage: "Something went wrong, please retry."
    });
  }
}

async function openPreview() {
  if (
    !props.existingPitchdeckDetails?.pitchdeckURL ||
    pdfPreview.value.linkPreview.toLowerCase().includes("blob")
  ) {
    window.open(pdfPreview.value.linkPreview, "_blank");
  } else {
    await openPitchDeck(pdfPreview.value.linkPreview);
  }
}
function uploadNewPitchdeck(e) {
  deckDragOver.value = false;
  isLoading.value = true;
  const deck = e.target.files[0];
  if (!deck) {
    return toast.add({
      severity: "error",
      summary: `The file is not valid. Please retry to upload it again.`,
      life: 10000
    });
  }
  if (deck.size > 15000000) {
    return toast.add({
      severity: "error",
      summary: `The file is bigger than 15MB. Please compress it before uploading.`,
      life: 10000
    });
  }
  if (deck.type !== "application/pdf") {
    return toast.add({
      severity: "error",
      summary: `The file is not a PDF`,
      life: 10000
    });
  }
  pdfPreview.value["fileName"] = deck.name;
  const reader = new FileReader();
  reader.readAsArrayBuffer(deck);
  reader.onloadend = (e) => {
    newPitchdeck.value = e.target.result;

    emits("uploadedPitchdeck", newPitchdeck.value);

    const blob = new Blob([e.target.result], { type: "application/pdf" });
    const link = window.URL.createObjectURL(blob);
    pdfPreview.value["linkPreview"] = link;
  };
  reader.onerror = () => {
    errorManager({
      error: reader.error,
      functionPath: "uploadNewPitchdeck",
      userErrorMessage: "Something went wrong, please retry. "
    });
  };
}
</script>

<style lang="scss" scoped>
.logo-upload-button {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 80%;
  width: 80%;
}

.dropzone {
  border-radius: 16px;
}
</style>
