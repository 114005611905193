export function makeUniqueArray(arr, isEqual = (a, b) => a === b) {
  if (!Array.isArray(arr)) {
    throw new Error("Input must be an array.");
  }

  if (typeof isEqual !== "function") {
    throw new Error("isEqual parameter must be a function.");
  }

  if (arr.length === 0) {
    return [];
  }

  // Filter the array to remove any falsy values
  const filteredArr = arr.filter((item) => item);

  return filteredArr.reduce((uniqueArr, current) => {
    // Check if the current element is an object and if all of its keys are null
    const hasAllNullValues =
      typeof current === "object" && current !== null
        ? Object.values(current).every((value) => value === null)
        : false;

    if (hasAllNullValues) {
      return uniqueArr; // Skip adding this element if all its values are null
    }
    // Check for duplicates using the provided isEqual function
    const isDuplicate = uniqueArr.some((existing) =>
      isEqual(existing, current)
    );

    if (!isDuplicate) {
      uniqueArr.push(current);
    }

    // Return the updated array with only non-null key objects
    return uniqueArr;
  }, []);
}
