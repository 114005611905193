import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";

export function useSortModal() {
  const SortModal = defineAsyncComponent(
    () => import("@global/components/base-modal-sort.vue")
  );
  const dialog = useDialog();
  const showSort = () => {
    dialog?.open(SortModal, {
      data: {},
      props: {
        header: "Sort by column",
        style: {
          width: "43vw"
        },
        breakpoints: {
          "960px": "75vw",
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  };
  return {
    showSort
  };
}
