import {
  getEcosystemPublishedChallenges,
  getChallengesFromCorporateId
} from "@/global/services/challenges-service.js";
import { getStartupSuggestedChallenges } from "@/global/services/rialto-service.js";
import { getDislikedChallenges } from "@startups/services/applications-service.js";
import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useOpportunitiesStore } from "./opportunities-store.js";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import { useAppStateStore } from "./app-state-store.js";
import { useUserAuthStore } from "./user-auth-store.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";
// import { encryptSessionStorage } from "@/plugins/pinia.js";

export const useChallengesStore = defineStore(
  "challenges",
  () => {
    const opportunityStore = useOpportunitiesStore();
    const accountStore = useAccountsStore();
    const appStateStore = useAppStateStore();
    const userAuthStore = useUserAuthStore();
    const { errorManager } = userErrorHandling();

    const state = reactive({
      //TOTAL challenges (close, open, visible in ecosystem)
      ecosystemPublishedChallengesList: null,
      //TOTAL challenges per corporate
      accountChallenges: null,
      startupSuggestedChallenges: null,
      startupDislikeChallenges: null,
      startupLikeChallenges: null
    });
    const getters = reactive({
      //TOTAL open challenges of all corporates (close, open, visible in ecosystem)
      getEcosystemOpenChallenges: computed(() => {
        if (!state.ecosystemPublishedChallengesList) {
          return null;
        }
        return state.ecosystemPublishedChallengesList.filter(
          (ele) => ele.Challenge_State__c === "Open"
        );
      }),
      //List of open challenge per corporate
      getOpenChallenges: computed(() => {
        if (!state.accountChallenges) {
          return null;
        }
        return state.accountChallenges.reduce((acc, curr) => {
          if (curr.Challenge_State__c === "Open") {
            acc[curr.Id] = curr;
          }
          return acc;
        }, {});
      }),
      //List of draft challenge per corporate
      getDraftChallenges: computed(() => {
        if (!state.accountChallenges) {
          return null;
        }
        return state.accountChallenges.reduce((acc, curr) => {
          if (curr.Challenge_State__c === "Draft") {
            acc[curr.Id] = curr;
          }
          return acc;
        }, {});
      }),
      //List of closed challenge per corporate
      getClosedChallenges: computed(() => {
        if (!state.accountChallenges) {
          return null;
        }
        return state.accountChallenges.reduce((acc, curr) => {
          if (curr.Challenge_State__c === "Closed") {
            acc[curr.Id] = curr;
          }
          return acc;
        }, {});
      }),
      //List of all challenges (open, closed, draft) per corporate
      getAccountChallenges: computed(() => {
        return state.accountChallenges || [];
      }),
      //List of all suggested challenges per startup
      getStartupSuggestedChallenges: computed(() => {
        return state.startupSuggestedChallenges || [];
      }),
      //List of all open challenges for sponsored corporates
      getChallengesForSponsoredCorporates: computed(() => {
        const sponsoredCorporates =
          accountStore.getters.getSponsoredCorporatePerInstitution.map(
            (corporate) => corporate.companyId
          );
        if (!state.ecosystemPublishedChallengesList || !sponsoredCorporates) {
          return [];
        }
        return state.ecosystemPublishedChallengesList.filter((challenge) =>
          sponsoredCorporates.includes(challenge.Challenge_Corporate_Sponsor__c)
        );
      }),
      getKanbanSuggestedChallenges: computed(() => {
        if (
          !state.startupSuggestedChallenges ||
          !state.startupDislikeChallenges
        ) {
          return [];
        }
        return [
          ...state.startupSuggestedChallenges,
          ...state.startupDislikeChallenges.filter(
            (challenge) => challenge?.IsSuggestedFromRialto
          )
        ];
      })
    });

    const actions = reactive({
      setEcosystemPublishedChallengesList: async () => {
        try {
          appStateStore.actions.setStartProcess(
            "ecosystemPublishedChallengesList"
          );
          const isDemoAccount = userAuthStore.getters.isDemoAccount;
          const challengesList = await getEcosystemPublishedChallenges(
            isDemoAccount,
            userAuthStore.state.accountInfo?.id
          );
          state.ecosystemPublishedChallengesList = challengesList;
        } catch (error) {
          appStateStore.actions.setError(
            "ecosystemPublishedChallengesList",
            true
          );
          console.log("####ERROR", error);
          errorManager({
            error,
            functionPath: "setEcosystemPublishedChallengesList",
            userErrorMessage: "Unable to load all public challenges"
          });
          // throw new Error("Unable to load the challenges");
        } finally {
          appStateStore.actions.setLoading(
            "ecosystemPublishedChallengesList",
            false
          );
        }
      },
      setAccountChallengesList: async (corporateId) => {
        try {
          appStateStore.actions.setStartProcess("accountChallenges");
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD CHALLENGES for user: ", corporateId);
          }
          const challenges = await getChallengesFromCorporateId(corporateId);
          state.accountChallenges = challenges;
          if (challenges && challenges.length !== 0) {
            opportunityStore.actions.setChallengesOpportunities(challenges);
          }
        } catch (error) {
          appStateStore.actions.setError("accountChallenges", true);
          errorManager({
            error,
            functionPath: "setAccountChallengesList",
            userErrorMessage: `Unable to load the challenges for ${corporateId}`
          });
        } finally {
          appStateStore.actions.setLoading("accountChallenges", false);
        }
      },
      setStartupSuggestedChallenges: async (startupId, forceLoading = true) => {
        try {
          if (forceLoading) {
            appStateStore.actions.setStartProcess("startupSuggestedChallenges");
          }
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD SUGGESTED CHALLENGES for user: ", startupId);
          }
          const suggestedChallenges =
            await getStartupSuggestedChallenges(startupId);

          if (userAuthStore.getters.isDemoAccount) {
            await actions.setEcosystemPublishedChallengesList();
            state.startupSuggestedChallenges =
              state.ecosystemPublishedChallengesList
                ?.filter(
                  (ele) =>
                    ele.Challenge_State__c === "Open" &&
                    ele.Challenge_Corporate_Sponsor__c ===
                      "0014U00002ooVLDQA2" &&
                    !opportunityStore.state.startupOpportunities.find(
                      (app) => app.challengeId == ele.Id
                    )
                )
                ?.map((challenge) => ({
                  ...challenge,
                  startupStageName: false,
                  score: 0.61
                })) || [];
          } else {
            state.startupSuggestedChallenges = suggestedChallenges.map(
              (challenge) => ({
                ...challenge,
                startupStageName: false
              })
            );
          }

          // ...dislikedChallenges.map((challenge) => ({
          //   ...challenge,
          //   startupStageName: "disliked"
          // }))
        } catch (error) {
          appStateStore.actions.setError("startupSuggestedChallenges", true);
          errorManager({
            error,
            functionPath: "setStartupSuggestedChallenges",
            userErrorMessage: `Unable to load the challenges for ${startupId}`
          });
        } finally {
          appStateStore.actions.setLoading("startupSuggestedChallenges", false);
        }
      },
      setStartupDislikeChallenges: async (startupId, forceLoading = true) => {
        // startupDislikeChallenges
        try {
          if (forceLoading) {
            appStateStore.actions.setStartProcess("startupDislikedChallenges");
          }
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD DISLIKED CHALLENGES for user: ", startupId);
          }
          state.startupDislikeChallenges = await getDislikedChallenges(
            startupId,
            false
          );
        } catch (error) {
          throw error;
        }
      },
      setStartupLikeChallenges: async (startupId, forceLoading = true) => {
        // startupDislikeChallenges
        try {
          if (forceLoading) {
            appStateStore.actions.setStartProcess("startupDislikedChallenges");
          }
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD LIKED CHALLENGES for user: ", startupId);
          }
          state.startupLikeChallenges = await getDislikedChallenges(
            startupId,
            true
          );
        } catch (error) {
          throw error;
        }
      },

      setStartupSuggestedChallengeLocal: (data) => {
        state.startupSuggestedChallenges = data;
      },
      reset: () => {
        state.ecosystemPublishedChallengesList = null;
        state.accountChallenges = null;
      }
    });

    return {
      state,
      getters,
      actions
    };
  }
  // {
  //   persist: {
  //     storage: encryptSessionStorage
  //   }
  // }
);
