import { queryMemory } from "@/global/hooks/use-filter-url-bind.js";
import { useNavigationGuard } from "@/global/hooks/use-navigation.js";

const Module = () => import("@/global/Module.vue");
const CorporateChallenges = () => import("./views/corporate-challenges.vue");
const StartupsIndex = () => import("./views/corporate-startups-index.vue");
const MeetingsIndex = () => import("./views/corporate-meetings-index.vue");
const CorporateAnalytics = () => import("./views/corporate-analytics.vue");
const CorporateMeetings = () => import("./views/corporate-meetings.vue");

//CONSTANT FOR ROUTE NAME
export const CORPORATE_CHALLENGES_ROUTE_NAME = "corporate-challenges";
export const CORPORATE_CHALLENGE_DETAILS_ROUTE_NAME = "challenge-details";
export const CORPORATE_PIPELINE_ROUTE_NAME = "challenge-pipeline-view";
export const CORPORATE_STARTUPS_ROUTE_NAME = "corporate-startups";
export const CORPORATE_MEETINGS_ROUTE_NAME = "corporate-meetings";
export const CORPORATE_ANALYTICS_ROUTE_NAME = "corporate-analytics";

export const ACTIVE_CHALLENGES = "active-challenges";
export const CLOSED_CHALLENGES = "closed-challenges";
export const DRAFT_CHALLENGES = "draft-challenges";

export const REQUESTED_MEETINGS = "requested-meetings";
export const UPCOMING_MEETINGS = "upcoming-meetings";
export const PAST_MEETINGS = "past-meetings";
export const ALL_MEETINGS = "all-meetings";
export const ALL_STARTUPS = "all";
export const FAVOURITES_STARTUPS = "favorites";
export const CHALLENGES_STARTUPS = "challenges";

export const CORPORATE_MOUDULE_ROUTES = [
  {
    path: "/corporate",
    name: "corporate-pages",
    component: Module,
    meta: {
      navbar: true
    },
    redirect: () => {
      return { name: "homepage" };
    },
    children: [
      {
        path: "challenges/:section?",
        name: CORPORATE_CHALLENGES_ROUTE_NAME,
        component: CorporateChallenges,
        meta: {
          title: "My Challenges",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, _from, next) => {
            if (!to.params.section) {
              next({
                name: CORPORATE_CHALLENGES_ROUTE_NAME,
                params: { section: ACTIVE_CHALLENGES }
              });
            } else {
              next();
            }
          }
        }
      },
      {
        path: "challenge/:corporateId/:id",
        component: () =>
          import("./views/corporate-challenge-opportunities.vue"),
        redirect: () => {
          return { name: CORPORATE_PIPELINE_ROUTE_NAME };
        },
        meta: {
          forceRedirect: (from, to, next) => {
            const { userId } = useNavigationGuard();

            if (from.params?.corporateId == userId) {
              next();
            } else {
              next({
                name: "challenge-open",
                params: {
                  corporateId: from.params?.corporateId,
                  id: from.params?.id
                }
              });
            }
          }
        },

        children: [
          //TODO: wait for new UI, if filter change find a good compromise
          {
            //use pipeline instead of kanban
            name: CORPORATE_PIPELINE_ROUTE_NAME,
            path: "pipeline",
            component: () =>
              import("./components/corporate-challenge-show-pipeline.vue")
          },
          {
            name: "challenge-table-view",
            path: "table",
            component: () =>
              import("./components/corporate-challenge-show-table.vue")
          },
          {
            name: "challenge-details-view",
            path: "challenge-details",
            component: () =>
              import("./components/corporate-challenge-show-details.vue")
          }
        ].map((r) => {
          return {
            ...r,
            meta: {
              undo: true,
              undoLink: CORPORATE_CHALLENGES_ROUTE_NAME
            }
          };
        })
      },
      {
        path: "startups/:section?",
        name: CORPORATE_STARTUPS_ROUTE_NAME,
        component: StartupsIndex,
        meta: {
          title: "Startups",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, _from, next) => {
            if (!to.params.section) {
              next({
                name: CORPORATE_STARTUPS_ROUTE_NAME,
                params: { section: ALL_STARTUPS }
              });
            } else {
              next();
            }
          }
        }
      },
      {
        path: "meetings/:section?",
        name: CORPORATE_MEETINGS_ROUTE_NAME,
        component: CorporateMeetings,
        meta: {
          title: "Meetings",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, _from, next) => {
            if (!to.params.section) {
              next({
                name: CORPORATE_MEETINGS_ROUTE_NAME,
                params: { section: UPCOMING_MEETINGS }
              });
            } else {
              next();
            }
          }
        }
      },
      {
        path: "analytics",
        name: CORPORATE_ANALYTICS_ROUTE_NAME,
        component: CorporateAnalytics,
        redirect: () => {
          return {
            name: "analytics-challenges",
            query: queryMemory("analytics-challenges")
          };
        },
        children: [
          {
            name: "analytics-challenges",
            path: "challenges",
            component: () =>
              import("./components/corporate-analytics-challenges.vue")
          },
          {
            name: "analytics-startups",
            path: "startups",
            component: () =>
              import("./components/corporate-analytics-startups.vue")
          }
        ].map((r) => {
          return {
            ...r,
            meta: {
              title: "Analytics",
              size: "text-3xl",
              undo: false
            }
          };
        })
      }
    ].map((route) => {
      return {
        ...route,
        meta: {
          ...route.meta,
          navbar: true,
          auth: true
        },
        async beforeEnter(from, to, next) {
          const { newNavigation, mtbTeleportTrigger } = useNavigationGuard([
            "corporate"
          ]);
          mtbTeleportTrigger();
          const path = await newNavigation();
          if (!path && route.meta?.beforeEnter) {
            //I can go ahead with current route meta.beforeEnter
            route.meta.beforeEnter(from, to, next);
          } else if (route.meta?.forceRedirect) {
            route.meta.forceRedirect(from, to, next);
          } else {
            next(path);
          }
        }
      };
    })
  }
];
