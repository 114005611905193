export function createTableActions({
  numberOfFilters,
  tableSort,
  selectedRowGroupBy,
  selectedRowGroupByConfig,
  openFilterModal,
  showSortMenu,
  showGroupMenu,
  props,
  rowHeight,
  customActions = [], // Add customActions parameter,
  columnOrders,
  sortCallback,
  groupByCallback
}) {
  const fixedActions = [
    {
      name: "filter",
      type: "button",
      severity: () =>
        numberOfFilters.value === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        numberOfFilters.value === 0
          ? "Filter"
          : `Filtered (${numberOfFilters.value})`,
      icon: "filter",
      text: () => (numberOfFilters.value === 0 ? true : false),
      iconClass: () =>
        `w-4 h-4 stroke-2 ${
          numberOfFilters.value === 0 ? "stroke-primary" : "fill-primary"
        }`,
      clicked: () => openFilterModal()
    },
    {
      name: "sortBy",
      type: "dropdown",
      header: {
        title: "Sort by field",
        subTitle: "Sort within groups in ascending or descending order"
      },
      defaultSelectedOption: props.defaultSorting[1],
      sortSelectedValue: true,
      dropdownOptions: () => {
        return props.columnConfig.reduce((acc, column) => {
          if (column.sortConfig) {
            acc = [
              ...acc,
              {
                id: column.field,
                name: column.header,

                sort: true,
                sortOption: [
                  {
                    id: "sort-ascending",
                    name: "Sort ascending",
                    displayName: column.sortConfig.displayName,
                    label: column.sortConfig?.sortAscendingLabel
                  },
                  {
                    name: "Sort descending",
                    id: "sort-descending",
                    displayName: column.sortConfig.displayName,
                    label: column.sortConfig?.sortDescendingLabel
                  }
                ],
                titleClass: "text-black",
                handleSelection: (selectedOption) => {
                  sortCallback(column, selectedOption.sortOption[0]);
                }
              }
            ];
          }
          return acc;
        }, []);
      },
      filter: true,

      severity: () =>
        tableSort.value.length === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        tableSort.value.length === 0
          ? "Sort"
          : `Sorted by (${tableSort.value[1].displayName})`,
      icon: "sorting",
      text: () => (tableSort.value.length === 0 ? true : false),
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary`,
      clicked: () => showSortMenu()
    },
    {
      name: "groupBy",
      type: "dropdown",
      header: {
        title: "Group by field",
        subTitle: "Group data by specific fields and categories"
      },
      defaultSelectedOption: {
        id: selectedRowGroupByConfig.value.displayName,
        name: props.columnConfig?.find(
          (col) => col.field === selectedRowGroupByConfig.value.displayName
        )?.header
      },
      sortSelectedValue: true,
      dropdownOptions: () => {
        return props.columnConfig?.reduce((acc, column) => {
          if (column.groupByConfig) {
            acc = [
              ...acc,
              {
                id: column.field,
                name: column.header,
                titleClass: "text-black",
                handleSelection: () => {
                  // sortCallback(column, selectedOption.sortOption[0]);
                  groupByCallback(column);
                }
              }
            ];
          }
          return acc;
        }, []);
      },
      filter: true,
      severity: () =>
        selectedRowGroupBy.value.length === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        selectedRowGroupBy.value.length === 0
          ? "Group"
          : `Grouped by (${selectedRowGroupByConfig.value.displayName})`,
      icon: "group_by",
      text: () => (selectedRowGroupBy.value.length === 0 ? true : false),
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary fill-none`,
      clicked: () => showGroupMenu()
    },
    {
      name: "manageFields",
      type: "dropdown",
      header: {
        title: "Manage table columns",
        subTitle: "Hide, show and add new custom fields"
      },
      dropdownOptions: () => {
        return props.columnConfig.reduce((acc, column) => {
          //TODO:
          if (column.reorderColumn) {
            acc = [
              ...acc,
              {
                id: column.field,
                name: column.header,
                fieldVisibility: columnOrders.value.some(
                  (col) => col.column === column.field
                ),
                showInput: true,
                inputSwitch: columnOrders.value.find(
                  (col) => col.column === column.field
                ).show,
                titleClass: "text-black"
              }
            ];
          }
          return acc;
        }, []);
      },
      severity: () => "primary",
      size: "extra-small",
      label: () => "Manage Fields",
      icon: "settings",
      text: true,
      filter: true,
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary`
    },
    {
      name: "resize",
      type: "dropdown",
      header: {
        title: "Manage table row",
        subTitle: "Customize the table's row height"
      },
      defaultSelectedOption: {
        name: "Short",
        id: "row-height-short",
        icon: "row-small",
        iconClass: "w-4 h-4 stroke-black stroke-2",
        titleClass: "text-black text-base"
      },
      sortSelectedValue: false,
      dropdownOptions: () => [
        {
          name: "Short",
          id: "row-height-short",
          icon: "row-small",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "short";
          }
        },
        {
          name: "Medium",
          id: "row-height-medium",
          icon: "resize",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "medium";
          }
        },
        {
          name: "Fit content",
          id: "row-height-fit-content",
          icon: "fit-content",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "fit-content";
          }
        }
      ],
      severity: "primary",
      size: "extra-small",
      label: () => "Resize",
      icon: "resize",
      text: true,
      iconClass: () => `w-6 h-4 stroke-2 stroke-primary`
    }
  ];

  return [...fixedActions, ...customActions];
}

export function createTableActionConfig({
  columns,
  numberOfFilters,
  tableSort,
  groupByColumn,
  selectedRowGroupByConfig,
  openFilterModal,
  showSortMenu,
  showGroupMenu,
  props,
  rowHeight,
  customActions = [], // Add customActions parameter,
  sortCallback,
  groupByCallback
}) {
  return [
    {
      name: "filter",
      type: "button",
      severity: () =>
        numberOfFilters?.value === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        numberOfFilters?.value === 0
          ? "Filter"
          : `Filtered (${numberOfFilters?.value})`,
      icon: "filter",
      text: () => (numberOfFilters.value === 0 ? true : false),
      iconClass: () =>
        `w-4 h-4 stroke-2 ${
          numberOfFilters.value === 0 ? "stroke-primary" : "fill-primary"
        }`,
      clicked: () => {
        openFilterModal();
      }
    },
    {
      name: "sortBy",
      type: "dropdown",
      header: {
        title: "Sort by field",
        subTitle: "Sort within groups in ascending or descending order"
      },
      defaultSelectedOption: {
        id: tableSort.value[0]?.id,
        name: tableSort.value[0]?.name
      },
      sortSelectedValue: true,
      dropdownOptions: () => {
        return columns.value.reduce((acc, column) => {
          if (column.sortConfig) {
            acc = [
              ...acc,
              {
                id: column.field,
                name: column.header,
                sort: true,
                sortDirection: column.sortConfig.currentDirection,
                sortOption: [
                  {
                    id: "sort-ascending",
                    name: "Sort ascending",
                    direction: "asc",
                    displayName: column.sortConfig.displayName,
                    label: column.sortConfig?.sortAscendingLabel
                  },
                  {
                    name: "Sort descending",
                    id: "sort-descending",
                    direction: "desc",
                    displayName: column.sortConfig.displayName,
                    label: column.sortConfig?.sortDescendingLabel
                  }
                ],
                titleClass: "text-black",
                handleSelection: (selectedOption) => {
                  console.log("@@@@selectedOption", selectedOption);
                  const sort = {
                    id: selectedOption.id,
                    name: selectedOption.name,
                    direction: selectedOption.sortOption[0].direction
                  };
                  sortCallback(sort, selectedOption.id);
                }
              }
            ];
          }
          return acc;
        }, []);
      },
      filter: true,

      severity: () =>
        tableSort.value.length === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        tableSort.value.length === 0
          ? "Sort"
          : `Sorted by (${tableSort.value[0].name})`,
      icon: "sorting",
      text: () => (tableSort.value.length === 0 ? true : false),
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary`,
      clicked: () => {
        // showSortMenu();
      }
    },
    {
      name: "groupBy",
      type: "dropdown",
      header: {
        title: "Group by field",
        subTitle: "Group data by specific fields and categories"
      },
      defaultSelectedOption: {
        id: selectedRowGroupByConfig.value?.displayName,
        name: props.columnConfig?.find(
          (col) => col.field === selectedRowGroupByConfig.value?.displayName
        )?.header
      },
      sortSelectedValue: true,
      dropdownOptions: () => {
        return columns.value?.reduce((acc, column) => {
          if (column.groupByConfig) {
            acc = [
              ...acc,
              {
                id: column?.field,
                name: column.header,
                titleClass: "text-black",
                handleSelection: () => {
                  groupByCallback(column);
                }
              }
            ];
          }
          return acc;
        }, []);
      },
      filter: true,
      severity: () =>
        groupByColumn.value.length === 0 ? "primary" : "primary-light",
      size: "extra-small",
      label: () =>
        groupByColumn.value.length === 0
          ? "Group"
          : `Grouped by (${selectedRowGroupByConfig.value?.displayName})`,
      icon: "group_by",
      text: () => (groupByColumn.value.length === 0 ? true : false),
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary fill-none`
    },
    {
      name: "manageFields",
      type: "dropdown",
      header: {
        title: "Manage table columns",
        subTitle: "Hide, show and add new custom fields"
      },
      dropdownOptions: () => {
        return columns.value?.reduce((acc, column) => {
          //TODO:
          if (column.reorderColumn) {
            acc = [
              ...acc,
              {
                id: column.field,
                name: column.header,
                //to know if we show the input switch in the dropdown
                showInput: true,
                //input switch value
                inputSwitch: column.isColumnVisible,
                titleClass: "text-black"
              }
            ];
          }
          return acc;
        }, []);
      },
      severity: () => "primary",
      size: "extra-small",
      label: () => "Manage Fields",
      icon: "settings",
      text: true,
      filter: true,
      iconClass: () => `w-4 h-4 stroke-2 stroke-primary`
    },
    {
      name: "resize",
      type: "dropdown",
      header: {
        title: "Manage table row",
        subTitle: "Customize the table's row height"
      },
      defaultSelectedOption: {
        name: "Short",
        id: "row-height-short",
        icon: "row-small",
        iconClass: "w-4 h-4 stroke-black stroke-2",
        titleClass: "text-black text-base"
      },
      sortSelectedValue: false,
      dropdownOptions: () => [
        {
          name: "Short",
          id: "row-height-short",
          icon: "row-small",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "short";
          }
        },
        {
          name: "Medium",
          id: "row-height-medium",
          icon: "resize",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "medium";
          }
        },
        {
          name: "Fit content",
          id: "row-height-fit-content",
          icon: "fit-content",
          iconClass: "w-4 h-4 stroke-black stroke-2",
          titleClass: "text-black text-base",
          handleSelection: () => {
            rowHeight.value = "fit-content";
          }
        }
      ],
      severity: "primary",
      size: "extra-small",
      label: () => "Resize",
      icon: "resize",
      text: true,
      iconClass: () => `w-6 h-4 stroke-2 stroke-primary`
    }
  ];
}
