import { auth } from "@/plugins/firebase.js";
import { useNavigationGuard } from "./hooks/use-navigation.js";
import { encryptValue, isEncrypted } from "./helpers/hash.js";
export const GLOBAL_SIGNIN = "signin";
export const GLOBAL_REQUEST_ACCOUNT = "request-account";
export const GLOBAL_RESET_PASSWORD = "reset-password";
export const GLOBAL_HOME = "home";
export const GLOBAL_OPEN_CHALLENGES = "open-challenges";
export const GLOBAL_CORPORATES = "ecosystem-corporates";
export const GLOBAL_PARTNERS = "ecosystem-partners";
export const USER_PREFERENCES = "user-preferences";
//TODO: DEBT - double check when add it
export const GLOBAL_FEATURED = "ecosystem-featured";
const StartupRescheduleMeeting = () =>
  import("@startups/views/startup-reschedule-meeting.vue");

export const GLOBAL_ROUTES = [
  //Signin
  {
    path: "/signin",
    name: GLOBAL_SIGNIN,
    component: () => import("@/global/views/global-signin.vue"),
    meta: {
      navbar: true,
      hideHeader: true,
      auth: false,
      title: "Sign in - MTB Ecosystem"
    },
    beforeEnter(_to, from, next) {
      if (auth.currentUser) {
        if (!from.name) {
          next({ name: "homepage" });
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/request-account",
    name: GLOBAL_REQUEST_ACCOUNT,
    component: () => import("@/global/views/global-signup.vue"),
    meta: {
      navbar: true,
      hideHeader: true,
      auth: false
    },
    beforeEnter(_to, from, next) {
      if (auth.currentUser) {
        if (!from.name) {
          next({ name: "homepage" });
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/c",
    name: "c",
    component: () => import("@/global/views/create-new-user"),
    meta: {
      navbar: false,
      auth: false
    },
    beforeEnter(_to, from, next) {
      if (auth.currentUser) {
        if (!from.name) {
          next({ name: "homepage" });
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/reset-password",
    name: GLOBAL_RESET_PASSWORD,
    component: () => import("@/global/views/global-reset-password.vue"),
    meta: {
      navbar: false,
      auth: false
    },
    beforeEnter(_to, from, next) {
      if (auth.currentUser) {
        if (!from.name) {
          next({ name: "homepage" });
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  },

  {
    path: "/homepage",
    name: "homepage",
    component: () => import("@/global/views/global-homepage.vue"),
    meta: {
      title: "Home",
      size: "text-3xl",
      undo: false,
      navbar: true,
      auth: true
    },
    beforeEnter(_to, _from, next) {
      if (!auth.currentUser) {
        next({ name: GLOBAL_SIGNIN });
      } else {
        next();
      }
    }
  },
  {
    path: "/preferences",
    name: USER_PREFERENCES,
    component: () => import("@/global/views/user-preferences.vue"),
    meta: {
      title: "My Preferences",
      size: "text-3xl",
      navbar: true,
      auth: true
    },
    async beforeEnter(_to, from, next) {
      if (auth.currentUser) {
        const isMtber = (await auth.currentUser?.getIdTokenResult(true))?.claims
          ?.mtb;
        if (isMtber) {
          next({ name: "homepage" });
        } else {
          next();
        }
      } else {
        next({ name: GLOBAL_SIGNIN });
      }
    }
  },
  //List open challenges
  {
    name: "challenge-open",
    path: "/challenge/:corporateId?/:id?",
    component: () =>
      import("./components/corporate-challenge-open-details.vue"),
    meta: {
      banner: "startup",
      title: "Corporate Challenge Details",
      size: "text-2xl",
      undo: false,
      navbar: true
    },
    async beforeEnter(from, _to, next) {
      const { corporateId, id } = from.params;
      const { sharableCorporate, sharableChallenge } = from.query;
      const corporateIdParam = corporateId || sharableCorporate;
      const challengeIdParam = id || sharableChallenge;
      const handleEncryption = async () => {
        const corporateIdEncrypted = isEncrypted(corporateIdParam)
          ? corporateIdParam
          : await encryptValue(corporateIdParam);
        const idEncrypted = isEncrypted(challengeIdParam)
          ? challengeIdParam
          : await encryptValue(challengeIdParam);

        next({
          name: "challenge-open",
          params: {
            corporateId: corporateIdEncrypted,
            id: idEncrypted
          },
          query: {
            ...(from.query?._share && { _share: from.query?._share }),
            ...(from.query?._embed && { _embed: from.query?._embed })
          }
        });
      };

      if (isEncrypted(corporateIdParam) && isEncrypted(challengeIdParam)) {
        next();
      } else {
        await handleEncryption();
      }
    }
  },
  {
    path: "/open-challenges",
    name: GLOBAL_OPEN_CHALLENGES,
    component: () => import("@/global/views/open-challenges.vue"),
    meta: {
      title: "Open Challenges",
      size: "text-3xl",
      banner: "corporate",
      undo: false,
      navbar: true
      //TODO: Change here to open the page + beforeEnter
    }
    // async beforeEnter(_from, _to, next) {
    //   //TODO if you are an institution redirect on dedicated view
    //   //will we use different view for application?
    //   //if yes add specific redirect per user type it they aren't mtbers
    //   const { newNavigation, mtbTeleportTrigger } = useNavigationGuard(
    //     ["institution"],
    //     true
    //   );

    //   mtbTeleportTrigger();
    //   const path = await newNavigation();
    //   next(path);
    // }
  },
  {
    //editable params need to salesforce edit pitchdeck
    path: "/startup-profile/:id?/:editable?",
    name: "startup-profile",
    component: () => import("@/global/views/startup-profile-show.vue"),
    meta: {
      banner: "corporate",
      navbar: true,
      auth: false
    },
    async beforeEnter(from, _to, next) {
      const { sharableId, sharableMode } = from.query;
      if (sharableId || sharableMode) {
        next({
          name: "startup-profile",
          params: {
            id: sharableId,
            ...(sharableMode && { editable: sharableMode })
          },
          query: {
            ...(from.query?._share && { _share: from.query?._share }),
            ...(from.query?._embed && { _embed: from.query?._embed })
          }
        });
      } else {
        next();
      }
    }
  },
  //TODO: add a general name and add params to it.
  //DEBT: everyone with this url can open the page. Creating a token or something to control it.
  {
    path: "/schedule-meeting/:action",
    name: "schedule Meeting",
    component: StartupRescheduleMeeting,
    meta: {
      navbar: true,
      title: "Schedule Meetings",
      size: "text-3xl",
      undo: true,
      auth: false
    }
  },
  {
    path: "/partner-profile/:id?/:section?",
    name: "institution-profile",
    component: () => import("@/global/views/institution-profile-show.vue"),
    meta: {
      banner: "institution",
      navbar: true,
      undo: true,
      undoLink: GLOBAL_PARTNERS,
      auth: false
    },
    async beforeEnter(from, _to, next) {
      const { sharableId, sharableSection } = from.query;

      if (sharableId || sharableSection) {
        next({
          name: "institution-profile",
          params: {
            id: sharableId,
            ...(sharableSection && { section: sharableSection })
          },
          query: {
            ...(from.query?._share && { _share: from.query?._share }),
            ...(from.query?._embed && { _embed: from.query?._embed })
          }
        });
      } else {
        next();
      }
    }
  },
  //Search
  {
    path: "/search/:section?",
    name: "search",
    component: () => import("@/global/views/search.vue"),
    meta: {
      navbar: true,
      title: "Search",
      size: "text-2xl",
      undo: true,
      undoPath: "/homepage",
      auth: true
    },
    async beforeEnter(to, from, next) {
      if (auth.currentUser) {
        next();
      } else {
        next({ name: GLOBAL_SIGNIN });
      }
    }
  },
  {
    path: "/error",
    name: "internal-error",
    component: () => import("@/global/views/internal-error.vue")
  },

  //All corporate list
  {
    path: "/ecosystem-corporates",
    name: GLOBAL_CORPORATES,
    component: () => import("@/global/views/mtb-all-corporates.vue"),
    meta: {
      banner: "corporate",
      navbar: true,
      title: "Corporates",
      size: "text-2xl",
      preventUndoUnauth: true,
      undo: true,
      undoLink: "homepage",
      auth: false
    }
  },
  //All partners
  {
    path: "/ecosystem-partners",
    name: GLOBAL_PARTNERS,
    component: () => import("@/global/views/mtb-all-partners.vue"),
    meta: {
      navbar: true,
      banner: "institution",
      title: "Partners",
      size: "text-2xl",
      preventUndoUnauth: true,
      undo: true,
      undoLink: "homepage",
      auth: false
    }
  },
  //All featured list
  {
    path: "/featured-lists",
    name: GLOBAL_FEATURED,
    component: () => import("@/global/views/mtb-featured-lists.vue"),
    meta: {
      navbar: true,
      banner: "corporate",
      title: "Featured Lists",
      size: "text-2xl",
      undo: true,
      preventUndoUnauth: true,
      undoLink: "homepage",
      auth: false
    }
  },
  {
    path: "/featured-list/:id?",
    name: "featured-list",
    component: () => import("@/global/views/mtb-featured-list-show.vue"),
    meta: {
      navbar: true,
      banner: "corporate",
      title: "Featured List",
      size: "text-2xl",
      undo: true,
      undoLink: GLOBAL_FEATURED,
      auth: false
    },
    async beforeEnter(from, _to, next) {
      const { sharableId } = from.query;

      if (sharableId) {
        next({
          name: "featured-list",
          params: {
            id: sharableId
          },
          query: {
            ...(from.query?._share && { _share: from.query?._share }),
            ...(from.query?._embed && { _embed: from.query?._embed })
          }
        });
      } else {
        next();
      }
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    beforeEnter(_from, _to, next) {
      console.log("@ENTER not found", _from, _to);
      if (auth.currentUser) {
        next({ name: "homepage" });
      } else {
        next({ name: GLOBAL_SIGNIN });
      }
    }
  },
  //Home page
  {
    path: "/",
    name: GLOBAL_HOME,
    beforeEnter(_from, _to, next) {
      if (auth.currentUser) {
        next({ name: "homepage" });
      } else {
        next({ name: GLOBAL_SIGNIN });
      }
    }
  }
];
