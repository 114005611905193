<template>
  <div class="h-full w-full p-6">
    <div class="flex flex-wrap items-center justify-start gap-8 pb-4 pt-4">
      <Skeleton width="60px" height="18px" />
      <Skeleton width="190px" height="18px" />
      <Skeleton width="190px" height="18px" />
      <Skeleton width="150px" height="18px" />
      <Skeleton width="110px" height="18px" />
    </div>
    <div class="table-wrapper">
      <table class="w-full">
        <thead>
          <!-- Skeleton Group Header for Group 1 -->
          <tr>
            <th class="p-2 bg-gray-100">
              <div class="flex items-center">
                <Skeleton width="60px" height="20px" class="mr-2" />
                <Skeleton width="120px" height="20px" class="mr-2" />
                <Skeleton width="30px" height="20px" />
              </div>
            </th>
          </tr>
          <tr v-if="groupStates[1]">
            <td>
              <!-- Skeleton Rows for Group 1 (open by default) -->
              <div v-for="i in 5" :key="i" class="p-2 border-b">
                <Skeleton width="100%" height="20px" />
              </div>
            </td>
          </tr>

          <!-- Skeleton Group Header for Group 2 -->
          <tr>
            <th class="p-2 bg-gray-100">
              <div class="flex items-center">
                <Skeleton width="60px" height="20px" class="mr-2" />
                <Skeleton width="120px" height="20px" class="mr-2" />
                <Skeleton width="30px" height="20px" />
              </div>
            </th>
          </tr>

          <!-- Skeleton Group Header for Group 3 -->
          <tr>
            <th class="p-2 bg-gray-100">
              <div class="flex items-center">
                <Skeleton width="60px" height="20px" class="mr-2" />
                <Skeleton width="120px" height="20px" class="mr-2" />
                <Skeleton width="30px" height="20px" />
              </div>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

// Manage group open/close states
const groupStates = ref({
  1: true, // Group 1 open by default
  2: false, // Group 2 closed by default
  3: false // Group 3 closed by default
});
</script>

<style scoped>
.table-wrapper {
  overflow-x: auto;
  position: relative;
  height: calc(100% - 51px);
}

th,
td {
  border: 1px solid #ccc;
}

.skeleton-header {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}
</style>
