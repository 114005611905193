<template>
  <div
    :style="{
      width: size + 'px',
      minWidth: size + 'px',
      height: size + 'px',
      minHeight: size + 'px',
      fontSize: fontSize + 'px',
      display: inline && 'inline-flex',
      borderRadius: rounded && '50%'
    }"
    class="avatar noselect"
    :class="[displayBackground, displayColor]"
  >
    {{ displayName }}
  </div>
</template>

<script setup>
import { computed } from "vue";

const lightColors = [
  "bg-rose-800",
  "bg-red-600",
  "bg-amber-950",
  "bg-pink-600",
  "bg-orange-800",
  "bg-red-500",
  "bg-cyan-900",
  "bg-violet-600",
  "bg-amber-700",
  "bg-cyan-200",
  "bg-pink-700",
  "bg-fuchsia-700",
  "bg-lime-600",
  "bg-indigo-700",
  "bg-orange-100",
  "bg-indigo-200",
  "bg-pink-950",
  "bg-orange-950",
  "bg-green-100",
  "bg-rose-600",
  "bg-green-900",
  "bg-rose-500",
  "bg-cyan-700",
  "bg-indigo-600",
  "bg-fuchsia-200",
  "bg-red-700",
  "bg-orange-200",
  "bg-blue-600",
  "bg-amber-500",
  "bg-indigo-500",
  "bg-blue-500",
  "bg-cyan-500",
  "bg-amber-600",
  "bg-green-200",
  "bg-blue-700",
  "bg-rose-900",
  "bg-red-100",
  "bg-rose-100",
  "bg-lime-700",
  "bg-violet-200",
  "bg-indigo-800",
  "bg-blue-900",
  "bg-pink-100",
  "bg-violet-100",
  "bg-violet-500",
  "bg-amber-200",
  "bg-fuchsia-600",
  "bg-fuchsia-500",
  "bg-fuchsia-100",
  "bg-lime-500",
  "bg-violet-900",
  "bg-amber-900",
  "bg-pink-200",
  "bg-amber-100",
  "bg-green-700",
  "bg-red-800",
  "bg-lime-100",
  "bg-orange-600",
  "bg-orange-700",
  "bg-red-200",
  "bg-fuchsia-800",
  "bg-rose-950",
  "bg-lime-200",
  "bg-red-950",
  "bg-amber-800",
  "bg-cyan-100",
  "bg-indigo-950",
  "bg-pink-500",
  "bg-indigo-100",
  "bg-rose-200",
  "bg-rose-700",
  "bg-lime-950",
  "bg-blue-800",
  "bg-amber-800",
  "bg-red-900",
  "bg-fuchsia-950",
  "bg-lime-800",
  "bg-pink-800",
  "bg-blue-100",
  "bg-violet-800",
  "bg-violet-950",
  "bg-rose-100",
  "bg-violet-700",
  "bg-cyan-950",
  "bg-indigo-900",
  "bg-orange-500",
  "bg-rose-300",
  "bg-cyan-600",
  "bg-green-950",
  "bg-green-600",
  "bg-fuchsia-900",
  "bg-amber-300"
];

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  color: {
    type: String
  },
  background: {
    type: String
  },
  size: {
    type: Number
  },
  inline: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: true
  }
});

const fontSize = computed(() => {
  const size = props.size || 40;
  if (displayName.value.length == 1) return size / 2;
  else if (displayName.value.length == 2) return size / 2.5;
  if (displayName.value.length == 3) return size / 3;
  else return 14;
});

const displayName = computed(() => {
  //remove special characters from the name, then split into an array of names.
  // if two words, we take the first letter of each
  // if three of more words, we take the first 2 words and the last one
  let cleanedName = props.name.replace(/[^\w\s-]+/g, "").trim();
  let words = cleanedName.split(/[- ]/);
  words = words.filter((word) => word !== "");

  if (words.length >= 3) {
    return (
      words[0][0].toUpperCase() +
      words[1][0].toUpperCase() +
      words[words.length - 1][0].toUpperCase()
    );
  } else if (words.length == 2)
    return words[0][0].toUpperCase() + words[1][0].toUpperCase();
  else if (words.length == 1) return words[0][0].toUpperCase();
  else return "";
});
const displayBackground = computed(() => {
  return props.background ? props.background : lightColor.value;
});
const displayColor = computed(() => {
  return displayBackground.value.includes("100") ||
    displayBackground.value.includes("200")
    ? "text-black"
    : "text-white";
});
const lightColor = computed(() => {
  const username = props.name.trim();
  // Convert the username to a number by summing the char codes of its letters
  const sumCharCode = [...username].reduce(
    (sum, char) => sum + char.charCodeAt(0),
    0
  );
  // Use the modulus operation to get a valid index for lightColors
  const index = sumCharCode % lightColors.length;
  return lightColors[index];
});
</script>

<style scoped>
.avatar {
  color: white;
  background: navy;
  font-size: 14px;
  width: 45px;
  height: 45px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>
