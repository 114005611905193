import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";
import { useRouter, useRoute } from "vue-router";

export const useInternalTableModal = () => {
  const router = useRouter();
  const route = useRoute();

  const dialog = useDialog();
  const ModalHandlingApplication = defineAsyncComponent(
    () => import("@/modules/mtbers/components/handling-application-modal.vue")
  );

  function openHandleApplicationModal({ applications, action }) {
    const startupName = applications.map((item) => item.startupName).join(", ");

    dialog?.open(ModalHandlingApplication, {
      data: {
        applications,
        action: action,
        multiple: applications.length > 1
      },
      props: {
        header: `${action == "reject" ? "Reject" : "Accept"} ${startupName}`,
        style: {
          width: "560px"
        },
        breakpoints: {
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  }

  return {
    openHandleApplicationModal
  };
};
