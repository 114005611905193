<template>
  <div v-if="isLoading" class="pt-6 flex flex-col gap-y-2">
    <div
      v-for="index in preview ? 2 : 4"
      :key="index"
      class="flex w-full h-[85px]"
    >
      <Skeleton class="w-full h-full"></Skeleton>
    </div>
  </div>
  <div class="pt-10 flex flex-col gap-y-2" v-else-if="isError">
    <!-- <fallback-error @reload="reload" /> -->
  </div>
  <div
    v-else-if="props.startups.length === 0"
    class="pt-10 flex flex-col gap-y-2"
  >
    <!-- TODO: copy of this message -->
    <fallback-empty-state :message="emptyMessage" />
  </div>
  <DataTable
    v-else
    ref="startupsTable"
    :value="startups"
    :virtualScrollerOptions="
      preview || !isChromium
        ? null
        : {
            itemSize: 85
          }
    "
    :paginator="!preview && !isChromium"
    :paginatorTemplate="
      !preview && !isChromium
        ? 'PrevPageLink CurrentPageReport NextPageLink'
        : null
    "
    :currentPageReportTemplate="
      !preview && !isChromium ? '{currentPage} of {totalPages}' : null
    "
    :rows="!preview && !isChromium ? rows : null"
    scrollHeight="flex"
    class="pt-6 !h-[calc(100%-71px)] md:h-[calc(100%-48px)]"
    scrollable
    dataKey="Id"
    @row-click="openSidebar"
    filterDisplay="menu"
    :pt="getTableStyle()"
  >
    <Column
      field="Name"
      style="width: 20%"
      :frozen="true"
      :preview="props.preview || search"
    >
      <template #body="{ data }">
        <div class="flex flex-row items-center justify-start gap-4 w-[300px]">
          <baseFavouriteStartup
            v-if="starVisible"
            :startupId="data.Id"
          ></baseFavouriteStartup>
          <base-logo classes="w-8 h-8" :companyId="data.Id" />
          <div class="flex flex-col items-start gap-0.5">
            <div class="flex items-center gap-1.5">
              <span
                class="font-bold text-lg text-black capitalize max-w-[200px] text-ellipsis whitespace-nowrap overflow-hidden"
              >
                {{ data.Name || "--" }}
              </span>
              <base-sponsorship-badge :id="data.Id.companyId" />
            </div>
          </div>
        </div>
      </template>
    </Column>

    <Column
      field="HQ_Country__c"
      style="width: 15%"
      :preview="preview || search"
    >
      <template #body="{ data }">
        <div class="flex flex-row items-center justify-start gap-4 w-[130px]">
          <country-flag
            v-if="data.HQ_Country__c"
            :country="formatFlag(data.HQ_Country__c)"
            size="normal"
            class="mb-0"
          />

          <p
            class="text-base whitespace-nowrap text-ellipsis overflow-hidden max-w-[100px]"
          >
            {{ data?.HQ_Country__c || "--" }}
          </p>
        </div>
      </template>
    </Column>
    <Column
      field="Industry_Verticals__c"
      style="width: 40%"
      :preview="preview || search"
    >
      <template #body="{ data }">
        <div
          v-if="
            data.Industry_Verticals__c && data.Industry_Verticals__c.length > 0
          "
          class="flex gap-4"
        >
          <span
            v-for="(industry, index) in data.Industry_Verticals__c.slice(0, 3)"
            :key="index"
            class="mtb-chip text-sm bg-data6-light text-data6"
          >
            {{ industry }}
          </span>
          <span
            v-if="data.Industry_Verticals__c.length > 3"
            @mouseenter="toggleOverlay(data, $event)"
            @mouseleave="toggleOverlay(data, $event)"
            class="mtb-chip text-sm bg-data6-light text-data6"
            >+ {{ data.Industry_Verticals__c.length - 3 }}
          </span>
          <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
            {{
              selectedRowData.Industry_Verticals__c.slice(3)
                .map((industry) => industry)
                .join(", ")
            }}
          </OverlayPanel>
        </div>
        <span v-else class="text-lg">--</span>
      </template>
    </Column>
    <Column
      style="width: 2rem"
      :frozen="true"
      alignFrozen="right"
      :preview="preview || search"
    >
      <template #body>
        <span class="cursor-pointer flex justify-end">
          <base-vite-icon
            class="w-1/5"
            name="arrow-short-right"
            classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
        /></span>
      </template>
    </Column>
    <template #empty
      >No startups match your filter criteria. Please adjust your filters and
      try again.</template
    >
  </DataTable>
</template>
<script setup>
import { ref } from "vue";
import { useScreenHeight } from "@global/hooks/use-screen-height.js";
import { formatFlag } from "@/global/helpers/util.js";
import CountryFlag from "vue-country-flag-next";
import { default as constants } from "@share/envConstant.js";
import { bus } from "@/main.js";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
const accountsStore = useAccountsStore();
import { useTablePassthrough } from "@global/hooks/use-table-passthrough.js";
const { getTableStyle } = useTablePassthrough();

const props = defineProps({
  startups: {
    type: Array,
    default: () => []
  },
  preview: {
    type: Boolean,
    default: false
  },
  search: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: true
  },
  isError: {
    type: Boolean,
    default: false
  },
  starVisible: {
    type: Boolean,
    default: false
  },
  emptyMessage: {
    type: String,
    default: "You have no startups in this section yet"
  }
});

const isChromium = !!window.chrome;
//ROW CALC
const rows = useScreenHeight(85, 240);

const overlay = ref();
const selectedRowData = ref(null);
const toggleOverlay = (rowData, event) => {
  selectedRowData.value = rowData;
  overlay.value?.toggle(event);
};

function openSidebar(e) {
  e.originalEvent.stopImmediatePropagation();
  bus.emit("open-account-detail-sidebar", {
    id: e.data.Id,
    accountType: "startup"
  });
}
</script>
