import { v4 as uuidv4 } from "uuid";

export function useTablePassthrough() {
  const getTableStyle = () => {
    //NOTE: z-index number is important!!!!! (loading overlay(3) - header(2) - rowgroup header(2) - body cell(1) )
    //NOTE: set a fix height for table header - use it for the position of row group
    return {
      root: ({ props }) => {
        return {
          class: [
            "relative w-full",
            {
              "flex flex-col justify-between h-full whitespace-nowrap font-bold text-ellipsis gap-10":
                props.scrollable && props.scrollHeight === "flex"
            }
          ]
        };
      },
      // w-full box-border
      table: ({ props }) => {
        return {
          class: [
            "border-separate border-spacing-y-2.5 w-full",
            {
              "pr-2": !props.groupRowsBy
            }
          ]
        };
      },
      header: "sticky top-0 box-border bg-primary z-2",
      thead: "z-2 bg-grey4 top-0 sm:table-header-group hidden",
      rowGroupHeader: {
        class: ["sticky z-2 top-[5vh] bg-grey4 w-full"]
      },
      rowGroupHeaderCell: "bg-grey4 px-4 sticky z-2 top-4.5 left-0 w-[20px]",

      loadingoverlay: {
        class: [
          "fixed w-full h-full t-0 l-0 bg-gray-100/40",
          "transition duration-200",
          "absolute flex items-center justify-center z-3"
        ]
      },
      loadingicon: "w-8 h-8",
      wrapper: ({ props }) => {
        return {
          class: [
            {
              relative: props.scrollable,
              "overflow-visible": !props.scrollable,
              "flex flex-col grow":
                props.scrollable && props.scrollHeight === "flex"
            },
            "h-full"
          ]
        };
      },
      emptymessagecell: "mtb-card h-[85px] text-start px-4",
      bodyrow: ({ props }) => {
        //DEBT poor solution
        const disableClick =
          props.rowData?.Challenge_State__c?.index === "Draft";
        return {
          class: [
            "mtb-card border-none flex flex-col sm:table-row mb-2 sm:mb-0",
            { "cursor-pointer": !disableClick }
          ]
        };
      },
      column: {
        columnFilter: "inline-flex items-center",
        filterMenuButton: "cursor-pointer px-4",
        bodycell: ({ props, context, instance }) => {
          const totalColumns =
            instance?.$params?.parentInstance?.columns?.length || 0;

          const index = props.class === "rowgroup-table" ? 1 : 0;

          return {
            class: [
              "text-left border-none bg-white px-4 sm:h-[85px] h-auto",
              context?.index === index ? "py-3 ps-4 pe-0" : "sm:py-6 py-3 px-4",
              {
                "sm:rounded-bl-2xl rounded-tl-2xl rounded-bl-none rounded-tr-2xl sm:rounded-tr-none":
                  context?.index === index
              },
              {
                "sm:rounded-bl-none rounded-bl-2xl sm:rounded-tr-2xl rounded-tr-none rounded-br-xl":
                  context?.index === totalColumns - 1
              },
              //TODO: fix the frozen cell
              {
                "sticky z-[1]": props.frozen || props.frozen === "" // Frozen Columns
              }
            ]
          };
        },

        headercell: ({ props }) => {
          return {
            class: [
              { hidden: props.preview },
              "text-left text-base font-medium border-none bg-grey4 text-grey1 border-none px-4 pb-2 h-[5vh]",
              {
                "p-frozen-column sticky z-1":
                  props.frozen || props.frozen === "" // Frozen Columns
              }
            ]
          };
        },
        //TODO: ask this Remove for the date
        filteroverlay: {
          class: [
            "bg-white  border-1 border-grey3 rounded-[8px] min-w-[12.5rem]"
          ]
        },
        filtermatchmodedropdown: {
          root: "hidden"
        },
        filterconstraint: "p-5 border-b border-solid border-gray-300"
      }
    };
  };
  const getInternalTableStyle = () => {
    return {
      root: ({ props }) => {
        return {
          class: ["relative w-auto overflow-hidden h-full"]
        };
      },
      wrapper: ({ props }) => {
        return {
          class: ["overflow-auto whitespace-nowrap h-[calc(100%-50px)]"]
        };
      },
      rowGroupHeaderCell: {
        class: [
          "w-full py-3 !col-auto border-b border-b-1 border-grey3 text-start"
        ]
      },
      rowGroupHeader: {
        class: ["sticky z-2 bg-white w-full select-none"]
      },
      table: ({ props }) => {
        return {
          class: [
            "border-separate border-spacing-0 bg-white w-max",
            {
              "pr-2": !props.groupRowsBy
            }
          ]
        };
      },
      header: "sticky top-0 box-border font-bold py-4 border-none z-2",
      thead: "z-2 bg-grey4 top-0 sm:table-header-group hidden",
      headerRow: {
        class: ["bg-grey4 select-none"]
      },
      bodyrow: ({ props }) => {
        return {
          class: ["hover:bg-[#e9e9f0] bg-white"]
        };
      },
      column: {
        headercell: ({ props }) => {
          return {
            class: [
              "text-left text-base font-bold bg-grey4 text-black px-0 py-0 border-grey3 border-1 border-s-grey4 [&:nth-last-child(2)]:border-e-grey4 hover:bg-[#e9e9f0] relative",
              {
                "p-frozen-column sticky z-1":
                  props.frozen || props.frozen === "" // Frozen Columns
              },
              {
                "cursor-move": !props.frozen
              }
              // {
              //   "border-s-grey4": context.index === 0, // First column
              //   "border-e-grey4": isLastColumn // Last column
              // }
            ]
          };
        },
        headertitle: {
          class: ["whitespace-nowrap truncate"]
        },
        headercontent: {
          class: [
            "w-full flex flex-row-reverse justify-between align-items-center py-3 ps-2"
          ]
        },
        headercheckbox: {
          class: ["!hidden"]
        },
        columnResizer: {
          class: [
            "z-[999] bg-primary block absolute top-0 right-0 m-0 w-[0.5rem] h-full p-0 cursor-col-resize border-1 border-transparent"
          ]
        },
        bodycell: ({ context, props, instance }) => {
          return {
            class: [
              "text-left border-1 border-s-0 border-gray3 p-0 [&:nth-last-child(2)]:border-e-0 border-t-0 h-px bg-white",
              {
                "sticky z-[1]": props.frozen || props.frozen === "" // Frozen Columns
              }
            ]
          };
        }
      }
    };
  };

  return {
    getTableStyle,
    getInternalTableStyle
  };
}
