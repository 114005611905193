// import router from "@router";
// import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { default as errorUtil } from "@share/errorUtil";
import packageJson from "@root/package.json";
import router from "./router";

export function sentryErrorManagement(data) {
  Sentry.withScope(function (scope) {
    scope.setLevel(errorUtil.errorLevel[data.errorName] || "fatal");
    scope.setExtra(data.functionPath);
    scope.setTag("origin", "client");
    const exception = new Error();
    exception.name = data.errorName;
    exception.message = data.errorMessage;
    exception.stack = data.errorStack;
    Sentry.captureException(exception);
  });
}

export function initSentry(app) {
  if (process.env.NODE_ENV == "development") {
    console.info("==> sentry ready");
    return;
  }
  Sentry.init({
    app,
    ...errorUtil.sentryInitObject,
    // ...(process.env.NODE_ENV !== "development" && {
    integrations: [
      Sentry.browserTracingIntegration({ router, enableInp: true }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 0.9,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // }),

    release: packageJson.version, //add release version,
    beforeSend: function (event, hint) {
      if (
        event.exception?.values?.[0]?.value?.includes("dynamically imported")
      ) {
        window.location.reload(true);
        return event;
      }
      // Add your custom logic here to determine whether to send the event or not
      else if (shouldBlockEvent(event, hint)) {
        return null; // Block the event by returning null
      } else {
        return event; // Allow the event to be sent
      }
    }
  });
}

function shouldBlockEvent(event) {
  if (process.env.NODE_ENV === "development") return true;
  let isErrorBlocked = false;
  //TODO: add block on dynamic import if it's solved (keep track on helpdesk + sentry replay)
  const blockErrorList = [
    {
      type: "TypeError",
      value: "Cannot read properties of undefined (reading 'backdrop')"
    },
    {
      type: "ChunkLoadError"
    },
    {
      type: "Unauthenticated"
    },
    {
      type: "FirebaseError",
      value:
        "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
    }
  ];
  const exceptions = event.exception.values[0];
  if (exceptions) {
    isErrorBlocked = blockErrorList.some(
      (ele) =>
        (ele.type === exceptions.type && ele.value === exceptions.value) ||
        (ele.type === exceptions.type && !ele.value)
    );
    // ||
    //
  }

  return isErrorBlocked;
}
