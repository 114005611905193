import { ref } from "vue"; // Assuming you're using Vue.js

export function createTableHeaderConfig({
  columns,
  sortCallback,
  filterCallback,
  groupByCallback,
  moveColumnCallback,
  freezeColumnCallback,
  hideColumnCallback,
  customActions = []
}) {
  return ref([
    {
      name: "Sort ascending",
      id: "sort-ascending",
      icon: "arrow-up",
      iconClass: "w-4 h-4 stroke-black stroke-2",
      titleClass: "text-black text-base",
      optionClicked: (column) => sortCallback(column, "asc"),
      label: (column) => {
        return column.sortConfig?.sortAscendingLabel;
      },
      visible: (column) => {
        return column.sortConfig;
      }
    },
    {
      name: "Sort descending",
      id: "sort-descending",
      icon: "arrow-down",
      iconClass: "w-4 h-4 stroke-black stroke-2",
      titleClass: "text-black text-base",
      optionClicked: (column) => sortCallback(column, "desc"),
      label: (column) => {
        return column.sortConfig?.sortDescendingLabel;
      },
      visible: (column) => {
        return column.sortConfig;
      }
    },
    {
      name: "Filter by this field",
      id: "filter",
      icon: "filter",
      iconClass: "w-4 h-4 stroke-black stroke-2",
      titleClass: "text-black text-base",
      optionClicked: filterCallback,
      label: () => "Filter by this field",
      visible: (column) => {
        return column.filterConfig;
      }
    },
    {
      name: "Group by this field",
      id: "group",
      icon: "group_by",
      iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
      titleClass: "text-black text-base",
      optionClicked: groupByCallback,
      label: () => "Group by this field",
      visible: (column) => {
        return column.groupByConfig;
      }
    },
    {
      name: "Move column left",
      id: "move-column-left",
      icon: "arrow-left",
      iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
      titleClass: "text-black text-base",
      optionClicked: moveColumnCallback,
      label: () => "Move column left",
      visible: (column) => {
        const columnIndex = columns.value.findIndex(
          (col) => col.field === column.field
        );
        const lastFrozenColumnIndex = columns.value
          .filter(
            (col) =>
              col.frozenColumn &&
              col.field !== column.field &&
              col.isColumnVisible
          )
          .map((ele, index) => index)
          .sort((a, b) => b - a)[0];
        return !column.frozenColumn && columnIndex > lastFrozenColumnIndex + 1;
      }
    },
    {
      name: "Move column right",
      id: "move-column-right",
      icon: "arrow-right",
      iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
      titleClass: "text-black text-base",
      optionClicked: moveColumnCallback,
      label: () => "Move column right",
      visible: (column) => {
        return !column.frozenColumn;
      }
    },
    {
      name: "Freeze column",
      id: "freeze-column",
      icon: "group_by",
      iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
      titleClass: "text-black text-base",
      optionClicked: freezeColumnCallback,
      label: () => "Freeze column",
      visible: (column) => {
        return !column.frozenColumn && column.reorderColumn;
      }
    },
    {
      name: "Unfreeze column",
      id: "unfreeze-column",
      icon: "group_by",
      iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
      titleClass: "text-black text-base",
      optionClicked: freezeColumnCallback,
      label: () => "UnFreeze column",
      visible: (column) => {
        return column.frozenColumn && column.reorderColumn;
      }
    },
    // {
    //   name: "Lock column",
    //   id: "lock-column",
    //   icon: "lock",
    //   iconClass: "w-4 h-4 stroke-black stroke-2 fill-white",
    //   titleClass: "text-black text-base",
    //   optionClicked: lockColumnCallback
    // },
    {
      name: "Hide column",
      id: "hide-column",
      icon: "hide",
      iconClass: "w-4 h-4 fill-black stroke-none",
      titleClass: "text-black text-base",
      optionClicked: hideColumnCallback,
      label: () => "Hide column",
      visible: () => {
        return true;
      }
    },
    ...customActions // Allow additional custom actions to be added
  ]);
}
