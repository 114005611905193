<template>
  <div class="px-6 py-6 h-full">
    <DataTable
      v-if="filters"
      v-model:filters="filters"
      :value="dataTableFormatted"
      expandableRowGroups
      v-model:expandedRowGroups="expandedRowGroups"
      :groupRowsBy="selectedRowGroupBy"
      rowGroupMode="subheader"
      sortMode="multiple"
      :multiSortMeta="tableSort"
      row
      showGridlines
      scrollable
      scrollHeight="flex"
      tableStyle="overflow: auto!important"
      :pt="getInternalTableStyle()"
    >
      <!-- ========== GROUP-BY HEADER ============  -->
      <template #groupheader="slotProps">
        <base-checkbox-single
          v-if="
            !props.nonSelectCategory?.includes(
              slotProps?.data?.[selectedRowGroupBy]
            ) && props.nonSelectCategory?.length > 0
          "
          class="align-sub me-3"
          label=""
          name="multiselection"
          type="checkbox"
          checkboxListClass="flex-col"
          :model-value="allCheckedState[slotProps.data[selectedRowGroupBy]]"
          :showErrorMessage="false"
          @update:model-value="
            (value) =>
              selectEntireCategory(value, slotProps.data[selectedRowGroupBy])
          "
        />

        <div
          v-if="allCheckedState[slotProps.data[selectedRowGroupBy]]"
          class="inline-flex items-center align-sub gap-x-3"
        >
          <base-button
            v-tooltip.top="'Reject all'"
            class=""
            size="extra-small"
            label=""
            icon="close_circle"
            iconClass="w-6 h-6 stroke-2 stroke-grey3 hover:stroke-alert"
            :text="true"
            @click="handleApplicationsGroup('reject')"
          />
          <base-button
            v-tooltip.top="'Accept all'"
            class=""
            size="extra-small"
            label=""
            icon="check_circle"
            iconClass="w-6 h-6 stroke-2 stroke-grey3 hover:stroke-success"
            :text="true"
            @click="handleApplicationsGroup('accept')"
          />
          <!-- <base-button
            class=""
            size="extra-small"
            label="Reject All"
            severity="alert"
            :outlined="true"
          />
          <base-button
            class=""
            size="extra-small"
            label="Accept All"
            severity="success"
          /> -->
        </div>

        <span
          class="text-black text-base font-bold align-text-bottom leading-6 ml-2 capitalize"
          >{{ selectedRowGroupByConfig.displayName }}:
          {{ slotProps.data[selectedRowGroupBy] }} ({{
            dataTableFormatted.filter(
              (ele) =>
                ele[selectedRowGroupBy] == slotProps.data[selectedRowGroupBy]
            ).length
          }})</span
        >
      </template>

      <!-- ========== ACTION BAR (FILTER, SORT, GROUP BY, MANGE FILED, RESIZE) ============  -->
      <template #header>
        <div class="flex flex-wrap items-center justify-start gap-8">
          <div v-for="action in actions" :key="action.name">
            <base-button
              v-if="action.type === 'button'"
              :severity="action.severity()"
              :size="action?.size"
              :text="action.text()"
              :label="action.label()"
              :icon="action.icon"
              :iconClass="action.iconClass()"
              @click="action.clicked()"
            />

            <base-dropdown
              v-if="action.type === 'dropdown'"
              :titleIcon="action.icon"
              :title="action.label()"
              :header="action?.header"
              titleStyle="text-primary font-bold text-base"
              :titleIconStyle="action.iconClass()"
              :icon-style="action.iconClass()"
              :isIconOnly="true"
              :filter="action.filter"
              :isCustomDropdown="
                ['manageFields', 'sortBy', 'groupBy'].includes(action.name)
                  ? true
                  : false
              "
              optionLabel="name"
              :options="action.dropdownOptions()"
              :defaultSelectedOption="action.defaultSelectedOption"
              :sortSelectedValue="action.sortSelectedValue"
              @on-toggle="onToggleColumn"
              @on-sort-change="onSortColumn"
              @update:modelValue="handleDropdownSelection"
              v-model="selectedOption"
            />
          </div>
        </div>
      </template>
      <!-- ========== COLUMNS ============  -->
      <Column
        v-for="(col, index) in selectedColumns"
        :key="col.field"
        :field="col.field"
        :frozen="col.frozenColumn"
        draggable="true"
        :reorderableColumn="col.reorderColumn"
        alignFrozen="left"
        :style="{
          width: col.width + 'px !important',
          maxWidth: col.width + 'px !important'
        }"
      >
        <template #header>
          <div class="flex items-center justify-between h-[25px] w-full">
            <div
              class="flex items-center justify-between h-[25px] w-full"
              :draggable="!col.frozenColumn"
              @dragstart="onDragStart(col, index)"
              @dragover.prevent
              @dragleave="onDragLeave"
              @drop="onDrop(index)"
              @mousedown.stop="onDragStart"
            >
              <span class="w-3/5">{{ col.header }}</span>
              <div class="flex items-center justify-end w-2/5">
                <!-- sorting icon -->
                <base-vite-icon
                  name="arrow-up"
                  v-if="
                    tableSort.some(
                      (ele) => ele.field.includes(col.field) && ele.order == 1
                    )
                  "
                  @click="sortCallback(col, { id: 'sort-descending' })"
                  role="button"
                  classes="stroke-secondary stroke-2 w-4 h-4 bg-secondary-light me-2"
                ></base-vite-icon>
                <base-vite-icon
                  name="arrow-down"
                  v-if="
                    tableSort.some(
                      (ele) => ele.field.includes(col.field) && ele.order == -1
                    )
                  "
                  @click="sortCallback(col, { id: 'sort-ascending' })"
                  role="button"
                  classes="stroke-secondary stroke-2 w-4 h-4 bg-secondary-light me-2"
                ></base-vite-icon>
                <!-- frozen icon -->
                <base-vite-icon
                  name="pin"
                  v-if="col.frozenColumn"
                  @click="freezeColumnCallback(col)"
                  :role="col.reorderColumn ? 'button' : null"
                  :classes="`
                    'w-4 h-4 me-2',
                    ${
                      col.reorderColumn
                        ? 'fill-secondary bg-secondary-light'
                        : 'fill-grey2 bg-grey3'
                    }
                  `"
                ></base-vite-icon>
                <base-cascadeselect
                  dropdownicon="chevron-down"
                  iconClasses="stroke-black stroke-2 w-5 h-5 hover:bg-primary hover:stroke-white rounded-sm"
                  :calendarOptions="getTableHeaderConfig(col)"
                  :internalTableHeader="true"
                  @update:modelValue="
                    (selectedValue) => {
                      if (!selectedValue) {
                        return;
                      }
                      handleColumnSelection(selectedValue, col);
                    }
                  "
                  @show="handleShow(index)"
                  @hide="handleHide(index)"
                  :ref="(el) => setCascadeRef(index, el)"
                />
              </div>
            </div>
            <div
              class="h-[50px] w-4 bg-transparent active:bg-primary active:me-[-9px] cursor-ew-resize me-[-8px] relative z-[9999] border-1 border-transparent"
              @mousedown.stop="startColumnResize($event, index)"
            ></div>
          </div>
        </template>
        <template #body="{ data }">
          <base-table-body-cell
            :data="data"
            :fields="col.fieldCellConfig"
            :field="col.field"
            :containerWith="col?.width - 16"
            :size="rowHeight"
            :group="selectedRowGroupBy"
            @row-selected="handleSingleRowSelect"
          />
        </template>
      </Column>
      <Column field="" header="" style="display: none"></Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, onMounted, onUnmounted } from "vue";
import { useTablePassthrough } from "@global/hooks/use-table-passthrough.js";
const { getInternalTableStyle } = useTablePassthrough();
import { useFilterModal } from "@/global/hooks/use-filter-modal.js";
const { showFilters } = useFilterModal();
import { useFilterLogic } from "@global/hooks/use-filter-logic.js";
import { makeUniqueArray } from "@global/helpers/array.js";
import { sortArray } from "@global/helpers/util.js";
import { bus } from "@/main.js";
import { useInternalTableModal } from "@mtbers/hooks/use-internal-table-modal.js";

const { openHandleMultipleApplicationModal } = useInternalTableModal();

//TODO: add validator
const props = defineProps({
  nonSelectCategory: {
    type: Array,
    default: () => [],
    require: true
  },
  groupRowsBy: {
    type: String,
    default: "",
    require: true
  },
  defaultSorting: {
    type: Array,
    default: () => [],
    required: true
  },
  tableData: {
    type: Array,
    default: () => [],
    required: true
  },
  columnConfig: {
    type: Array,
    default: () => [],
    required: true
  },
  additionalHeaderMenuConfig: {
    type: Array,
    default: () => []
  },
  additionalTableActions: {
    type: Array,
    default: () => []
  }
});
const { groupRowsBy, tableData } = toRefs(props);
const expandedRowGroups = ref(["To be evaluated"]);
const selectedOption = ref({});

const rowHeight = ref("short");
const numberOfFilters = ref(0);
const columns = ref(props.columnConfig);
const selectedColumns = ref(columns.value);
const columnOrders = ref(
  selectedColumns.value.map((column, index) => ({
    column: column.field,
    originalIndex: index,
    tempIndex: index,
    show: true,
    reorderable: column.reorderColumn,
    frozenColumn: column.frozenColumn
  }))
);

const selectedRowGroupBy = ref(groupRowsBy.value);
const selectedRowGroupByConfig = computed(
  () =>
    props.columnConfig.find(
      (column) => column.groupByConfig?.name === selectedRowGroupBy.value
    )?.groupByConfig
);
const tableSort = ref(props.defaultSorting);

import { createTableActions } from "@global/configs/table-action-config.js";
const actions = computed(() =>
  createTableActions({
    numberOfFilters,
    tableSort,
    selectedRowGroupBy,
    selectedRowGroupByConfig,
    openFilterModal,
    showSortMenu,
    showGroupMenu,
    props,
    rowHeight,
    customActions: props.additionalTableActions, // Pass custom actions here,
    columnOrders,
    sortCallback,
    groupByCallback
  })
);

const dataTableFormatted = computed(() => {
  return tableData.value.map((item) => ({
    ...item,
    actions: selectedRows.value?.some((row) => row.id === item.id) || false,
    [selectedRowGroupBy.value]: selectedRowGroupByConfig.value?.formatter(item)
  }));
});

// --------------------- TABLE FILTER FUNCTIONALITY START

const filters = ref(
  props.columnConfig.reduce((acc, column) => {
    if (column.filterConfig) {
      acc[column.field] = {
        value: null,
        ...column.filterConfig
      };
    }

    return acc;
  }, {})
);
const { openFilterModal } = useFilterLogic(filters, showFilters, null);
// --------------------- TABLE FILTER FUNCTIONALITY END

// --------------------- TABLE RESIZE FUNCTIONALITY START
let resizingColumnIndex = null;
let startX = 0;
let startWidth = 0;

const startColumnResize = (event, index) => {
  resizingColumnIndex = index;
  startX = event.clientX;
  startWidth = columns.value[index].width;

  document.addEventListener("mouseup", onColumnResize);
};

const onColumnResize = (event) => {
  if (resizingColumnIndex !== null) {
    const deltaX = event.clientX - startX;
    const newWidth = startWidth + deltaX;

    if (newWidth > 110) {
      // Ensure the width does not go below the minimum
      columns.value[resizingColumnIndex].width = newWidth;
      console.log("@@ resize started", newWidth);
    }
  }
  stopColumnResize();
};

const stopColumnResize = () => {
  // Remove the min-width and max-width restrictions after resizing
  columns.value.forEach((col) => {
    col.minWidth = null;
    col.maxWidth = null;
  });

  resizingColumnIndex = null;
  // document.removeEventListener("mousemove", onColumnResize);
  document.removeEventListener("mouseup", stopColumnResize);
};

// --------------------- TABLE RESIZE FUNCTIONALITY END

const showSortMenu = () => {
  // bus.emit("show-sort-menu");
};
const showGroupMenu = () => {};

//--------------------- HANDLE CASCADE SELECT MENU BEHAVIOUR (on click on another dropdown will close the menu) START
import { useCascadeSelect } from "@global/hooks/use-cascade-select.js";
const { cascadeRefs, handleShow, handleHide, setCascadeRef } =
  useCascadeSelect();
//--------------------- HANDLE CASCADE SELECT MENU BEHAVIOUR END

//--------------------- HANDLE CHECKBOX IN TABLE START

const selectedRows = ref([]);
const allCheckedState = ref({});

const selectEntireCategory = (checked, selectedGroup) => {
  console.log("DEBUG: enter selectEntireCategory");
  const selectedRowsClone = [...selectedRows.value];
  //checked = true => selected all rows and set state of that row to true
  if (checked) {
    selectedRows.value = dataTableFormatted.value.filter(
      (ele) => ele[selectedRowGroupBy.value] === selectedGroup
    );
    allCheckedState.value[selectedGroup] = true;
  } else if (allCheckedState.value[selectedGroup]) {
    //allCheck = true => remove all the selected rows in the group and set state to false
    selectedRows.value = selectedRowsClone.filter(
      (row) => row[selectedRowGroupBy.value] !== selectedGroup
    );
    allCheckedState.value[selectedGroup] = false;
  }
};

const handleSingleRowSelect = (modelValue, rowData) => {
  const selectedRowsClone = [...selectedRows.value];
  const selectedGroup = rowData[selectedRowGroupBy.value];
  console.log("DEBUG: handleSingleRowSelect", modelValue, rowData);
  if (modelValue) {
    //add it to the selectedRows
    selectedRows.value = makeUniqueArray(
      [...selectedRows.value, rowData],
      (a, b) => a.id === b.id
    );
    //Check if all rows are selected
    if (isAllRowSelected(selectedGroup)) {
      allCheckedState.value[selectedGroup] = true;
    }
  } else {
    //If one row is unselected => the group is not selected
    allCheckedState.value[selectedGroup] = false;
    selectedRows.value = selectedRowsClone.filter(
      (row) => row.id !== rowData.id
    );
  }
};

const isAllRowSelected = (selectedGroup) => {
  const allItemsPerGroup = dataTableFormatted.value.filter(
    (ele) => ele[selectedRowGroupBy.value] === selectedGroup
  ).length;
  const allSelectedItemsPerGroup = selectedRows.value.filter(
    (ele) => ele[selectedRowGroupBy.value] === selectedGroup
  ).length;
  console.log(
    "DEBUG: isAllRowSelected",
    allItemsPerGroup,
    allSelectedItemsPerGroup
  );
  return allItemsPerGroup === allSelectedItemsPerGroup;
};

const handleApplicationsGroup = (action) => {
  const objectToSend = selectedRows.value.map((ele) => {
    return {
      startupName: ele.startup.name,
      startupId: ele.startup.index,
      challengeName: ele.challenge.name,
      challengeId: ele.challenge.index
    };
  });
  openHandleMultipleApplicationModal(objectToSend, action);
};
//--------------------- HANDLE CHECKBOX IN TABLE END

//--------------------- TOGGLE COLUMN VISIBILITY START

const onToggleColumn = (checked, column) => {
  columnOrders.value = columnOrders.value.map((col) => ({
    ...col,
    show: column === col.column ? checked : col.show
  }));

  selectedColumns.value = columnOrders.value.reduce((acc, curr) => {
    const column = columns.value.find((col) => col.field === curr.column);
    if (curr.show) {
      acc = [...acc, column];
    }

    return acc;
  }, []);
  console.log("DEBUG toggle selectedColumns", selectedColumns.value);
  console.log("DEBUG toggle columnOrders", columnOrders.value);
};
//--------------------- TOGGLE COLUMN VISIBILITY END

const handleDropdownSelection = (selectedOption) => {
  console.log("@@@@selectedOption", selectedOption);
  selectedOption.handleSelection(selectedOption);
};

//--------------------- TABLE HEADER MENU START
//menu dropdown on select

const handleColumnSelection = (selectedOption, column) => {
  console.log("====>selectedOption", selectedOption);
  selectedOption.optionClicked(column, selectedOption);
};

const groupByCallback = (column) => {
  //assign the column to group-by
  selectedRowGroupBy.value = column?.groupByConfig?.name;
  //assign the sort field
  tableSort.value = [
    {
      field: selectedRowGroupBy.value,
      displayName: column.sortConfig.displayName,
      order: column?.groupByConfig.sortOrder,
      sortGroupBy: true
    },

    tableSort.value[1]
  ];
  //Calculate the new groups based on the group by column
  const sortOrder = tableSort.value[0].order !== 1;

  const groups = sortArray(
    makeUniqueArray(
      dataTableFormatted.value.map((item) => item[selectedRowGroupBy.value])
    ),
    { descending: sortOrder }
  );

  //Assign the default expanded group
  expandedRowGroups.value = [groups[0]];
};
const sortCallback = (column, option) => {
  console.log("DEBUG SORT column", column);
  console.log("DEBUG SORT option", option);
  tableSort.value = [
    tableSort.value[0],
    {
      field: column.sortConfig.name,
      displayName: column.sortConfig.displayName,
      order: option?.id === "sort-ascending" ? 1 : -1
    }
  ];
};
const filterCallback = (column) => {
  openFilterModal(column.filterConfig);
};

const handleReorder = (event) => {
  /**
   * Handle reorder column (IMPORTANT: this is used also when we use the reorder from primevue and our own reorder functionality)
   * @param {Object} event
   * @param {Number} event.dragIndex - the starting index of dragged column
   * @param {Number} event.dropIndex - the destination index we wish the drop the column
   * @param {Boolean} event.frozenColumn - if the column is frozen or not
   * * @param {Boolean} event.show - if the column is visible or not
   * @returns {Array}  columnOrders changes. the item is dropped in the dropped index
   *
   * Example usage:
   */

  const { dragIndex, dropIndex } = event;

  //Prevent to re-oder if it is not orderable from the config file
  if (!columnOrders.value[dropIndex].reorderable) {
    return;
  }

  const cloneColumnOrder = [...columnOrders.value];

  //Re-order the field in columnOrders and assign the new tempIndex and frozenColumn
  columnOrders.value.splice(dragIndex, 1)[0];
  columnOrders.value.splice(dropIndex, 0, {
    ...cloneColumnOrder[dragIndex],
    tempIndex: dropIndex,
    show: event?.show || true,
    ...(event?.frozenColumn ? { frozenColumn: event.frozenColumn } : {})
  });

  columnOrders.value.forEach((obj, idx) => {
    obj.tempIndex = idx;
  });

  const tempSelectedColumns = [...selectedColumns.value];
  //Assign new value to selectedColumns to trigger the re-oder
  selectedColumns.value = columnOrders.value.reduce((acc, curr) => {
    const column = tempSelectedColumns.find((col) => col.field === curr.column);
    if (curr.show) {
      acc = [...acc, column];
    }

    return acc;
  }, []);
  // console.log("DEBUG handleReorder selectedColumns", selectedColumns.value);
  // console.log("DEBUG handleReorder columnOrders", columnOrders.value);
};
const moveColumnCallback = (column, option) => {
  const direction = option.id === "move-column-left" ? "left" : "right";

  const indexColumn = columnOrders.value.find(
    (col) => col.column === column.field
  ).tempIndex;

  const event = {
    dragIndex: indexColumn,
    dropIndex: direction === "left" ? indexColumn - 1 : indexColumn + 1,
    frozenColumn: false,
    show: true
  };
  handleReorder(event);
};
const freezeColumnCallback = (column, option) => {
  console.log("DEBUG columnOrders starts", columnOrders);

  if (!column.reorderColumn) {
    return;
  }
  // Find the index of the current column in columnOrders
  const indexColumn = columnOrders.value.find(
    (col) => col.column === column?.field
  ).tempIndex;
  // Find the index of the current column in selectedColumns
  const selectedColIndex = selectedColumns.value.findIndex(
    (ele) => ele.field === column.field
  );

  if (column.frozenColumn) {
    columnOrders.value[indexColumn].frozenColumn = false;
    selectedColumns.value[selectedColIndex].frozenColumn = false;

    const lastColumnFrozen = columnOrders.value
      .filter((col) => col.frozenColumn && col.column !== column.field)
      .map((ele) => ele.tempIndex)
      .sort((a, b) => b - a)[0];

    const event = {
      dragIndex: indexColumn,
      dropIndex: lastColumnFrozen,
      frozenColumn: false, // Unfreeze the column,
      show: true
    };

    // Reorder columns
    handleReorder(event);
  } else {
    // Find the last index of frozen columns
    const lastColumnFrozen = columnOrders.value
      .filter((col) => col.frozenColumn && col.column !== column.field)
      .map((ele) => ele.tempIndex)
      .sort((a, b) => b - a)[0];

    console.log("====>lastColumnFrozen", lastColumnFrozen);

    columnOrders.value[indexColumn].frozenColumn = true;
    selectedColumns.value[selectedColIndex].frozenColumn = true;

    const event = {
      dragIndex: indexColumn,
      dropIndex: lastColumnFrozen + 1,
      frozenColumn: true, // Freeze the column
      show: true
    };

    // Reorder columns
    handleReorder(event);
  }
};

const hideColumnCallback = (column, option) => {
  onToggleColumn(false, column.field);
};

import { createTableHeaderConfig } from "@global/configs/table-header-config.js";
const tableHeaderConfig = createTableHeaderConfig({
  sortCallback: sortCallback,
  filterCallback: filterCallback,
  groupByCallback,
  moveColumnCallback: moveColumnCallback,
  freezeColumnCallback: freezeColumnCallback,
  hideColumnCallback: hideColumnCallback,
  customActions: props.additionalHeaderMenuConfig
});
const getTableHeaderConfig = (column) => {
  return tableHeaderConfig.value.reduce((acc, item) => {
    if (item.visible(column)) {
      acc = [...acc, { ...item, name: item.label(column) }];
    }
    return acc;
  }, []);
};

const onSortColumn = (value, option) => {
  const column = columns.value.find((col) => col.field === option?.id);
  sortCallback(column, value.value);
};

onMounted(() => {
  bus.on("filter-applied", (data) => {
    numberOfFilters.value = data;
  });
});

onUnmounted(() => {
  bus.off("filter-applied");
});

const draggedColumnIndex = ref(null);

const onDragStart = (column, index) => {
  draggedColumnIndex.value = index;
};
const onDragOver = (event) => {
  event.preventDefault();
  console.log("Drag Over", event);
};
const onDragLeave = (event) => {};
const onDrop = (index) => {
  const event = {
    dragIndex: draggedColumnIndex.value,
    dropIndex: index,
    frozenColumn: false, // Freeze the column
    show: true
  };
  console.log("DROPPED!!!!", index);
  handleReorder(event);
};
//--------------------- TABLE HEADER MENU END
</script>

<style scoped>
/* Additional styles if needed */
</style>
