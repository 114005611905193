import { isValidDateString } from "@global/helpers/date.js";
import { formatISO } from "date-fns";

export function getUrl(path) {
  let host = window.location.host;
  if (window.location.host == "app.mtbecosystem.com") {
    host = "mtb-ecosystem.web.app";
  }
  return `${window.location.protocol}//${host}/${path}`;
}
export function convertFiltersToUrlQuery(filters) {
  const params = new URLSearchParams();

  // Loop through each filter in the filters object
  Object.keys(filters).forEach((filterKey) => {
    const filter = filters[filterKey];
    const filterValue = filter?.value;
    if (!filterValue) return;

    // Case 1: If filterValue is an array (could be an array of dates or other values)
    if (Array.isArray(filterValue) && filterValue.length > 0) {
      // Check if it's an array of date strings
      if (filterValue.every((ele) => isValidDateString(ele))) {
        const startDate = new Date(filterValue[0]);
        const endDate = new Date(filterValue[1]);

        // Handle date range filtering
        if (startDate) {
          params.append(`filter[${filter.name}][gte]`, formatISO(startDate));
        }
        if (endDate) {
          params.append(`filter[${filter.name}][lte]`, formatISO(endDate));
        }
      } else {
        // If not dates, handle as a semicolon-separated string for other arrays
        const combinedValues = filterValue
          .filter(
            (item) =>
              item && typeof item === "object" && item.index && item.name
          )
          .map((item) => item.index)
          .join(";");
        if (combinedValues) {
          params.append(`filter[${filter.name}]`, combinedValues);
        }
      }

      // Case 2: If filterValue is a string (normal string or date string)
    } else if (typeof filterValue === "string") {
      if (isValidDateString(filterValue)) {
        // If it's a valid date string, you can handle it as a specific date filter
        params.append(`filter[${filter.name}]`, filterValue);
      } else {
        // Otherwise, it's just a normal string
        params.append(`filter[${filter.name}]`, filterValue);
      }

      // Case 3: If filterValue is an object (normal object case)
    } else if (
      typeof filterValue === "object" &&
      filterValue.index &&
      filterValue.name
    ) {
      params.append(`filter[${filter.name}]`, filterValue.index);
    }
  });

  console.log(
    "Generated URL Query Params:",
    decodeURIComponent(params.toString())
  );

  // Convert to string and return the final query
  return params.toString();
}

export function convertSortToUrlQuery(sorting) {
  const params = new URLSearchParams();

  // Build the sort_by query string with the appropriate format
  const sortBy = sorting
    .map((sort) => {
      if (sort && sort.id && sort.direction) {
        // Determine the prefix for sorting direction: + for ascending, - for descending
        const prefix = sort.direction === "asc" ? "+" : "-";
        return `${prefix}${sort.id}`;
      }
      return null;
    })
    .filter(Boolean) // Filter out any invalid sort options
    .join(","); // Join them by commas

  if (sortBy) {
    params.append("sort", sortBy);
  }

  console.log(
    "Generated URL Query Params:",
    decodeURIComponent(params.toString())
  );

  // Convert to string and return the final query
  return params.toString();
}

export function convertGroupByToUrlQuery(groupBy) {
  const params = new URLSearchParams();

  if (groupBy) {
    params.append("group", groupBy);
  }

  console.log(
    "Generated GroupBy URL Query Params:",
    decodeURIComponent(params.toString())
  );

  // Convert to string and return the final query
  return params.toString();
}
export function mapUrlQueryToState(routeQuery) {
  const filters = {};
  const tableSort = [];
  let groupBy = "";

  // Parse filters from the query
  Object.keys(routeQuery).forEach((key) => {
    if (key.startsWith("filter[")) {
      const filterKey = key.match(/filter\[(.*?)\]/)?.[1]; // Extract the filter name
      if (filterKey) {
        const condition = key.match(/\[(gte|lte)\]/)?.[1]; // Extract the condition (gte or lte)
        if (condition) {
          // Handle date range filters (e.g., gte and lte) with proper decoding
          if (!filters[filterKey]) {
            filters[filterKey] = { name: filterKey, value: [] };
          }

          filters[filterKey].value[condition === "gte" ? 0 : 1] =
            decodeAndParseDate(routeQuery[key]);
        } else {
          // Handle normal filters (non-date)
          filters[filterKey] = {
            name: filterKey,
            value: routeQuery[key].includes(";")
              ? routeQuery[key].split(";")
              : routeQuery[key]
          };
        }
      }
    }
  });

  // Parse sorting from the query (e.g., sort_by=-last_modified,+email)
  if (routeQuery.sort) {
    const sortFields = routeQuery.sort.split(","); // Split multiple sort fields
    sortFields.forEach((sortField) => {
      const direction = sortField.startsWith("-") ? "desc" : "asc"; // Determine direction from + or -
      const field = sortField.replace(/^[-+]/, ""); // Remove + or - to get the field name

      tableSort.push({
        id: field,
        name: field,
        direction: direction
      });
    });
  }

  // Parse groupBy from the query (e.g., group_by=category)
  if (routeQuery.group) {
    groupBy = routeQuery.group; // Multiple group fields are separated by commas
  }

  console.log("@@@@tableSort", tableSort);

  return { filters, tableSort, groupBy };
}
// Helper function to decode and parse dates
const decodeAndParseDate = (dateString) => {
  try {
    // Decode the date string and return a new Date object
    return new Date(decodeURIComponent(dateString));
  } catch (e) {
    console.error("Error decoding and parsing date:", e);
    return null;
  }
};
