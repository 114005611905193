// Define the mapping dictionary for different data types and orders.
const sortMapping = {
  alphabetical: {
    asc: "Sort A-Z",
    desc: "Sort Z-A"
  },
  numerical: {
    asc: "Sort 1-9",
    desc: "Sort 9-1"
  },
  date: {
    asc: "Sort Oldest to Newest",
    desc: "Sort Newest to Oldest"
  }
};

/**
 * Get the label for a given data type and order.
 *
 * @param {string} dataType - The type of data (e.g., 'alphabetical', 'numerical', 'date').
 * @param {string} order - The order type (e.g., 'ascending', 'descending').
 * @returns {string} - The corresponding label or description.
 */
export function getSortLabel(dataType, order) {
  // Validate inputs using Object.prototype.hasOwnProperty.call
  if (!Object.keys(sortMapping).includes(dataType)) {
    return `Error: '${dataType}' is not a recognized data type.`;
  }
  if (!["asc", "desc"].includes(order)) {
    return `Error: '${order}' is not a recognized order for data type '${dataType}'.`;
  }

  return sortMapping[dataType][order];
}
