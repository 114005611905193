import { COUNTRY_TO_CODE } from "../constants/country-code.js";
import { useUserAuthStore } from "@global/stores/user-auth-store.js";
import { useAccountsStore } from "@global/stores/accounts-store.js";
import { default as stageMapping } from "@share/stageMappingConfig.js";

export function safAccObj(obj, path) {
  return path
    .replace("][", ".")
    .replace("[", ".")
    .replace("]", "")
    .split(".")
    .reduce((object, path) => {
      return (object || {})[path];
    }, obj);
}

export function formatFlag(country) {
  return COUNTRY_TO_CODE.find((cou) => cou.name == country).code;
}
export function dynamicSort(
  array,
  fieldName,
  sortType,
  sortOrder = "asc",
  nullLast = true
) {
  const sortedArray = [...array].sort((a, b) => {
    const fieldA = a[fieldName];
    const fieldB = b[fieldName];

    // Handle null/undefined values
    if (fieldA == null && fieldB == null) return 0;
    if (fieldA == null) return nullLast ? 1 : -1;
    if (fieldB == null) return nullLast ? -1 : 1;

    let result;
    switch (sortType) {
      case "string":
        result = fieldA.localeCompare(fieldB);
        break;
      case "number":
        result = fieldA - fieldB;
        break;
      case "date":
        result = new Date(fieldA) - new Date(fieldB);
        break;
      case "boolean":
        // For boolean sorting, false values come before true values
        result = fieldA === fieldB ? 0 : fieldA ? 1 : -1;
        break;
      default:
        result = 0; // If sortingType is not recognized, return 0
    }
    return sortOrder === "asc" ? result : -result;
  });
  return sortedArray;
}
export function isCorporateChallengeVisible(
  challengeInfo,
  opportunityInfo = {}
) {
  const userAuthStore = useUserAuthStore();
  const accountsStore = useAccountsStore();

  //SPONSORED
  let isSponsoredCorporate = false;
  //TODO: userAuthStore.state.accountInfo.type is not defined
  //included in the test in case we lost the accountInfo
  //Solution: action needed
  if (userAuthStore.state.accountInfo?.type === "institution") {
    //do we want to exclude the active:false sponsorships?
    isSponsoredCorporate =
      accountsStore.getters.getSponsoredCorporatePerInstitution.some(
        (corporate) =>
          corporate.companyId === challengeInfo.Challenge_Corporate_Sponsor__c
      );
  }
  //if MTBer and demo mode is not active
  const isMtberNodemo =
    userAuthStore.getters.isMtbUser && !userAuthStore.state.isDemoModeActive
      ? true
      : false;

  //else if demo mode is active or normal user, add logic for opportunity stage
  const isOpportunityAfterMeeting = [
    ...stageMapping.stageMap.meeting.includedStage,
    ...stageMapping.stageMap.evaluation.includedStage
  ].includes(opportunityInfo?.StageName);

  const isChallengePublic =
    challengeInfo.Challenge_Visibility__c === "Public" ? true : false;

  return (
    isMtberNodemo ||
    isSponsoredCorporate ||
    isChallengePublic ||
    isOpportunityAfterMeeting
  );
}

// Helper function to format numbers into 'K' for thousands or 'M' for millions
export function formatNumber(num) {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(2)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(0)}K`;
  } else {
    return num.toString();
  }
}
var countries = require("i18n-iso-countries");
countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
export function formatIsoToTextGeo(isoCode) {
  return countries.getName(isoCode, "en", { select: "alias" });
}

export function sortArray(arr, options = {}) {
  /**
   * Sorts an array of any data type (numbers, strings, booleans, objects).
   *
   * @param {Array} arr - The array to be sorted.
   * @param {Object} options - Optional sorting options.
   * @param {boolean} options.descending - If true, sorts in descending order. Defaults to false (ascending order).
   * @param {string} options.key - The key to sort by if the array contains objects. Defaults to null.
   * @returns {Array} - A new sorted array.
   *
   * Example usage:
   * let sortedArray = sortArray(["banana", "apple", "cherry"]);
   * sortArray(people, { key: 'name' });
   * sortArray(people, { key: 'isActive' });
   */
  const { descending = false, key = null } = options;

  return arr.slice().sort((a, b) => {
    let valueA = key ? a[key] : a;
    let valueB = key ? b[key] : b;

    // Handle boolean values
    if (typeof valueA === "boolean" && typeof valueB === "boolean") {
      return descending ? valueB - valueA : valueA - valueB;
    }

    // Handle string values
    if (typeof valueA === "string" && typeof valueB === "string") {
      return descending
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    }

    // Handle numbers and other values
    return descending ? valueB - valueA : valueA - valueB;
  });
}
