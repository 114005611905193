<template>
  <div class="relative z-[999]">
    <div
      ref="suggestion"
      class="z-10 absolute top-[45%] left-4 border-1 border-grey4 rounded-lg overflow-x-hidden bg-white min-w-[200px] max-h-[125px] overflow-y-auto"
      v-show="isVisible && availableSuggestion?.length !== 0"
    >
      <div
        v-for="(suggestion, i) in availableSuggestion"
        :key="i"
        @click="selectSuggestion(suggestion)"
        :class="[
          'px-4 py-2  border-grey4 hover:bg-primary-light cursor-pointer text-sm',
          { 'border-b': i !== availableSuggestion?.length - 1 }
        ]"
      >
        {{ suggestion }}
      </div>
    </div>
    <Editor
      ref="editor"
      :tabindex="-1"
      :formats="['bold', 'italic', 'link', 'underline', 'list']"
      id="base-editor"
      @selection-change="openSuggestions(!!$event.range)"
      @click="openSuggestions(true)"
      @input="openSuggestions(true)"
      v-model="inputValue"
      :placeholder="props.placeholder"
      :editorStyle="editorStyle"
      :class="[
        { 'p-invalid border-alert border-1 rounded-lg': errorMessage },
        { 'border-0': !errorMessage },
        { 'force-border-0': light }
      ]"
      aria-describedby="dd-error"
      :pt="_ptEditor"
    >
      <template v-slot:toolbar>
        <span class="ql-formats">
          <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
          <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
          <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
        </span>
        <span class="ql-formats">
          <button
            v-tooltip.bottom="'List'"
            class="ql-list"
            value="bullet"
          ></button>
          <button
            v-tooltip.bottom="'List'"
            class="ql-list"
            value="ordered"
          ></button
        ></span>
        <span class="ql-formats"
          ><button v-tooltip.bottom="'Link'" class="ql-link"></button
        ></span>
      </template>
    </Editor>

    <span
      v-if="maxLength > 0"
      class="text-grey2 font-normal text-xs self-end block mt-4 me-2 text-end"
      >{{ formatRichTextToPlainText(inputValue).length }}/{{ maxLength }}</span
    >
    <small
      v-if="errorMessage || injectedError"
      class="p-error absolute bottom-[0px] text-alert left-0 text-base flex items-center"
      id="dd-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />{{
        errorMessage || injectedError
      }}</small
    >
  </div>
</template>

<script setup>
import { watch, watchEffect, computed, ref, onMounted } from "vue";
import Editor from "primevue/editor";
import { useField } from "vee-validate";
import { formatRichTextToPlainText } from "../helpers/text";
const emits = defineEmits(["update:modelValue"]);
const editor = ref();
const isVisible = ref(false);
const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    required: true
  },
  injectedError: {
    type: String,
    default: ""
  },
  editorStyle: {
    type: String,
    default: "height:120px"
  },
  maxLength: {
    type: Number,
    default: 2000
  },
  suggestions: {
    type: Array,
    default: () => null
  },
  light: {
    type: Boolean,
    default: false
  },
  sessionKey: String // Unique key for storing editor content in sessionStorage
});

const { value: inputValue, errorMessage } = useField(props.name, undefined, {
  initialValue: props.modelValue,
  validateOnMount: false
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (inputValue.value !== newValue) {
      inputValue.value = props.modelValue;
    }
  }
);

watchEffect(() => {
  emits("update:modelValue", inputValue.value);
});
const plainText = computed(() => {
  return inputValue.value
    ? formatRichTextToPlainText(inputValue.value)
    : inputValue.value;
});
const availableSuggestion = computed(() => {
  if (!props.suggestions) {
    return null;
  }
  if (!plainText.value) {
    return props.suggestions;
  }

  return props.suggestions.filter((sugg) => {
    return sugg.indexOf(plainText.value) !== -1 && plainText.value !== sugg;
  });
});

function openSuggestions(open) {
  if (!props.suggestions || availableSuggestion.value.length == 0) {
    isVisible.value = false;
    //Save un saved content in the session storage
    if (props.sessionKey && props.modelValue !== inputValue.value) {
      sessionStorage.setItem(props.sessionKey, inputValue.value);
      emits("update:modelValue", inputValue.value); // Emit updated value to parent component
    }

    return;
  }
  isVisible.value = open;
}
function selectSuggestion(suggestion) {
  inputValue.value = suggestion;
}

// Load the content from sessionStorage when the component is mounted
onMounted(() => {
  //Take the save content in sessionStorage
  if (props.sessionKey) {
    const savedContent = sessionStorage.getItem(props.sessionKey);
    if (savedContent) {
      inputValue.value = savedContent;
      emits("update:modelValue", inputValue.value); // Sync with parent component
    }
  }
});

const _ptEditor = {
  content: {
    class: `overflow-y-auto bg-white ${props.light ? "" : "rounded-b-lg"} `
  },
  toolbar: {
    class: `flex ${props.light ? "bg-white" : "bg-grey4 border-grey3 border-1  rounded-t-lg"} `
  }
};
</script>
<style lang="scss" scoped>
:deep(.ql-editor) {
  p {
    font-size: 14px;
  }
}
:deep(.ql-editor.ql-blank::before) {
  color: #757575 !important;
  font-style: normal !important;
  font-size: 14px;
  content: attr(data-placeholder);
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
:deep(.force-border-0) * {
  border: 0px !important;
}
</style>
