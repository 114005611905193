import { computed } from "vue";
import {
  STATE_ACCOUNT_INFO,
  STATE_CORPORATE_CAMPAIGN,
  STATE_USER_INFO
} from "../constants/loading-state";
import { useAppStateStore } from "../stores/app-state-store.js";

export const useLoading = () => {
  const appStateStore = useAppStateStore();
  //GLOBAL
  const isUserLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.[STATE_ACCOUNT_INFO] ||
      !!appStateStore.state.isLoading?.[STATE_USER_INFO]
    );
  });
  const isGlobalUserInfoLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.[STATE_ACCOUNT_INFO] ||
      !!appStateStore.state.isLoading?.[STATE_USER_INFO] ||
      !!appStateStore.state.isLoading?.["sponsorshipsList"]
    );
  });
  const isStartupListLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.basicStartupInfo ||
      !!appStateStore.state.isLoading?.additionalStartupInfo ||
      !!appStateStore.state.isLoading?.sponsorshipsList
    );
  });
  const isSponsorshipListLoading = computed(
    () => !!appStateStore.state.isLoading?.sponsorshipsList
  );
  const isCorporateListLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.basicCorporateInfo ||
      !!appStateStore.state.isLoading?.additionalCorporateInfo ||
      !!appStateStore.state.isLoading?.sponsorshipsList
    );
  });
  const isInstitutionListLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.basicInstitutionInfo ||
      !!appStateStore.state.isLoading?.additionalInstitutionsInfo ||
      !!appStateStore.state.isLoading?.sponsorshipsList
    );
  });
  const isFeaturedListLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.featuredList ||
      !!appStateStore.state.isLoading?.additionalInfoFeaturedList
    );
  });
  const isFavouriteListLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.additionalInfoFavouriteLists ||
      !!appStateStore.state.isLoading?.favouriteLists
    );
  });

  //STRATUP-INSTITUTION
  const isAllChallengesListLoading = computed(
    () => !!appStateStore.state.isLoading?.ecosystemPublishedChallengesList
  );

  //CORPORATE
  const isCorporateSuggestionsLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.accountChallenges ||
      !!appStateStore.state.isLoading?.globalChallengeSuggestions
    );
  });
  const isCorporateOpportunitiesLoading = computed(() => {
    return (
      !!appStateStore.state.isLoading?.accountChallenges ||
      !!appStateStore.state.isLoading?.globalChallengeOpportunities
    );
  });
  const isCampaignLoading = computed(
    () => !!appStateStore.state.isLoading?.[STATE_CORPORATE_CAMPAIGN]
  );
  const isAccountChallengesLoading = computed(
    () => !!appStateStore.state.isLoading?.accountChallenges
  );

  //INSTITUTION
  const isInstitutionExposureLoading = computed(
    () => !!appStateStore.state.isLoading?.institutionProfileExposure
  );

  const isCorporateExposureLoading = computed(
    () => !!appStateStore.state.isLoading?.sponsoredCorporateExposure
  );
  const isStartupExposureLoading = computed(
    () => !!appStateStore.state.isLoading?.sponsoredStartupExposure
  );
  const isSuggestionEmailLoading = computed(
    () => !!appStateStore.state.isLoading?.challengeSuggestionEmail
  );
  const isMyEcosystemStartupLoading = computed(() => {
    return !!appStateStore.state.isLoading?.startupsAdditionalInfo;
  });
  const isMyEcosystemCorporateLoading = computed(() => {
    return !!appStateStore.state.isLoading?.corporatesAdditionalInfo;
  });
  const isCorporateMeetingLoading = computed(() => {
    return !!appStateStore.state.isLoading?.corporateMeetings;
  });

  //STARTUP
  const isStartupSuggestedChallengesLoading = computed(
    () => !!appStateStore.state.isLoading?.startupSuggestedChallenges
  );
  const isStartupOpportunitiesLoading = computed(
    () => !!appStateStore.state.isLoading?.startupOpportunities
  );
  const isAllMatchingCalendarLoading = computed(
    () => !!appStateStore.state.isLoading?.matchingCalendars
  );

  return {
    isUserLoading,
    isGlobalUserInfoLoading,
    isSponsorshipListLoading,
    //
    isStartupListLoading,
    isCorporateListLoading,
    isInstitutionListLoading,
    //
    isFeaturedListLoading,
    isAllChallengesListLoading,
    //
    isCorporateSuggestionsLoading,
    isCorporateOpportunitiesLoading,
    isAccountChallengesLoading,
    isFavouriteListLoading,
    isCorporateMeetingLoading,
    //
    isCampaignLoading,
    //
    isInstitutionExposureLoading,
    isCorporateExposureLoading,
    isStartupExposureLoading,
    //
    isMyEcosystemStartupLoading,
    isMyEcosystemCorporateLoading,
    //
    isStartupSuggestedChallengesLoading,
    //
    isSuggestionEmailLoading,

    isStartupOpportunitiesLoading,
    isAllMatchingCalendarLoading
  };
};
