<template>
  <div
    :class="[
      'flex items-center p-2 gap-x-3 relative w-full text-left',
      !showOverlay && 'border hover:border-primary border-white text-base',
      props.size === 'short'
        ? 'h-[48px]'
        : props.size === 'medium'
          ? 'h-[73px]'
          : 'h-full'
    ]"
  >
    <!-- showOverlay: will show overlay text on expand  -->
    <div
      v-for="field in props.fields.filter((field) => field.visible)"
      :key="field.name"
      class="flex max-w-full"
      :class="[
        !showOverlay
          ? ''
          : `absolute left-0 bg-white border-primary border-1 p-2 z-[1200] top-[0px]`,
        (field.type === 'editor' && showEditor) ||
        (field.type == 'editor' && !value)
          ? 'h-full w-full'
          : '',
        !isFieldVisible(field) && 'hidden',
        field.type === 'button' &&
          field.name == 'btn-remove-feedback' &&
          'w-full'
      ]"
    >
      <div
        v-if="field.type === 'text' && isFieldVisible(field)"
        class="w-full h-full"
      >
        <base-text
          :class="[{ 'cursor-pointer': field.clicked }]"
          @expand="openOverlay"
          @close-expand="closeOverlay"
          :isExpanded="showOverlay"
          :field-index="props.data.id + '_' + field.name"
          :expanded-field-index="expandedFieldIndex"
          :size="props.size"
          :icons="field.icons"
          :containerClasses="props.containerWith + 'px'"
        >
          <span
            v-html="value ? formatText(value) : '--'"
            @click.stop="field.clicked ? field.clicked(data) : null"
          ></span>
          <template
            #icons
            v-if="field.name == 'startup' && field.icons.length > 0"
          >
            <div class="flex gap-2">
              <a
                v-for="(icon, index) in field.icons"
                :href="props.data[icon.name]"
                target="_blank"
                :key="index"
                v-tooltip.top="icon.label"
                data-table-no-select="true"
              >
                <base-vite-icon
                  :name="icon.iconName"
                  :classes="icon.iconClass"
                ></base-vite-icon>
              </a>
            </div>
          </template>
        </base-text>
      </div>

      <base-text
        @expand="openOverlay"
        @close-expand="closeOverlay"
        :size="props.size"
        :isExpanded="showOverlay"
        :containerClasses="props.containerWith + 'px'"
        :field-index="props.data.id + '_' + field.name"
        :expanded-field-index="expandedFieldIndex"
        v-else-if="field.type === 'date' && isFieldVisible(field)"
        >{{ value ? parseDateTime(value) : "--" }}</base-text
      >
      <base-vite-icon
        v-else-if="field.type === 'icon' && isFieldVisible(field)"
        :name="field.icon"
        :classes="field.iconClasses"
      />
      <base-button
        v-tooltip.top="field.tooltip(props.data)"
        v-else-if="field.type === 'button' && isFieldVisible(field)"
        class="m-auto"
        size="extra-small"
        :label="field.label"
        :icon="field.icon"
        :iconClass="field.iconClasses"
        :severity="field.severity"
        :disabled="isFieldDisabled(field)"
        :outlined="field.outlined"
        :text="field.text"
        @click="field.clickFunc ? field.clickFunc(props.data) : null"
        data-table-no-select="true"
      />
      <base-chip
        v-else-if="field.type === 'chip' && isFieldVisible(field)"
        :type="field.type"
        :icon="field.chipConfig(data).icon"
        :label="field.chipConfig(data).label"
        :iconClasses="field.chipConfig(data).iconClasses"
        :chipClasses="field.chipConfig(data).chipClasses"
      />
      <div
        v-else-if="field.type === 'avatar' && isFieldVisible(field)"
        class="flex gap-x-3 items-center w-full"
      >
        <base-avatar
          :name="value"
          :size="field.size"
          :class="field.classes"
          v-if="value"
        />
        <base-text
          @expand="openOverlay"
          @close-expand="closeOverlay"
          :isExpanded="showOverlay"
          :size="props.size"
          :field-index="props.data.id + '_' + field.name"
          :expanded-field-index="expandedFieldIndex"
          :containerClasses="props.containerWith - 36 + 'px'"
          :text="value"
        >
          {{ value || "--" }}
        </base-text>
      </div>
      <base-checkbox-single
        v-else-if="field.type === 'checkbox' && isFieldVisible(field)"
        class="w-full"
        :label="field.label"
        :name="field.name"
        type="checkbox"
        :modelValue="value"
        checkboxListClass="flex-col"
        :showErrorMessage="false"
        @update:model-value="(value) => handCheckboxChange(value, field)"
      />

      <base-input-text
        v-else-if="field.type === 'input' && isFieldVisible(field)"
        class="w-full absolute top-0 left-0"
        :label="field.label"
        :name="field.name"
        type="input-text"
        :placeholder="field.placeholder"
        @update:modelValue="field.updateValue"
        :value="value"
      />

      <div
        v-else-if="field.type === 'editor' && isFieldVisible(field)"
        class="w-full h-full"
      >
        <base-text
          v-if="!showEditor && tempEditorText"
          @expand="openOverlay"
          @close-expand="closeOverlay"
          :field-index="props.data.id + '_' + field.name"
          :expanded-field-index="expandedFieldIndex"
          :editable="true"
          :isExpanded="showOverlay"
          @dblclick="handleShowEditor"
          @mouseenter="showHover = true"
          @mouseleave="showHover = false"
          :size="props.size"
          :containerClasses="props.containerWith + 'px'"
        >
          <div
            v-html="formatText(tempEditorText)"
            class="italic flex items-center text-grey2 gap-1"
          ></div>
          <template #icons>
            <base-button
              v-tooltip.top="'Delete draft'"
              class="m-auto"
              size="extra-small"
              icon="trash"
              iconClass="w-4 h-4 stroke-2 stroke-alert"
              severity="alert"
              :text="true"
              data-table-no-select="true"
              @click="clearDraftText(field)"
          /></template>

          <!-- <span
            class="blinking-cursor not-italic"
            :class="showHover ? 'visible' : 'invisible'"
            >|</span> -->
        </base-text>
        <base-text
          v-if="!showEditor && value && !tempEditorText"
          @expand="openOverlay"
          @close-expand="closeOverlay"
          :field-index="props.data.id + '_' + field.name"
          :expanded-field-index="expandedFieldIndex"
          :editable="true"
          :isExpanded="showOverlay"
          @dblclick="handleShowEditor"
          @mouseenter="showHover = true"
          @mouseleave="showHover = false"
          :size="props.size"
          :containerClasses="props.containerWith + 'px'"
        >
          <span v-html="value ? formatText(value) : '--'"></span
          ><span
            class="blinking-cursor not-italic"
            :class="showHover ? 'visible' : 'invisible'"
            >|</span
          ></base-text
        >

        <base-text
          v-else-if="!showEditor && !value && !tempEditorText"
          :field-index="props.data.id + '_' + field.name"
          :expanded-field-index="expandedFieldIndex"
          @expand="openOverlay"
          @close-expand="closeOverlay"
          :isExpanded="showOverlay"
          @dblclick="handleShowEditor"
          @mouseenter="showHover = true"
          @mouseleave="showHover = false"
          :editable="true"
          :size="props.size"
          class="w-full"
          :containerClasses="props.containerWith + 'px'"
          ><span
            class="italic relative"
            :class="showHover ? 'visible' : 'invisible'"
            ><span class="blinking-cursor not-italic">|</span>Add internal
            notes</span
          ></base-text
        >
        <div
          v-if="showEditor"
          class="m-[-9px] border-primary border-1 z-[999] relative bg-white"
        >
          <base-editor
            v-click-outside="closeEditor"
            placeholder="Write your message here"
            :modelValue="value"
            :light="true"
            :maxLenght="2000"
            name="internalMessage"
            :sessionKey="getSessionKey(field)"
            @update:modelValue="handleValueChange"
          />
          <div
            class="flex items-center justify-end z-[999] relative gap-4 pb-2 pe-2"
          >
            <base-button
              class=""
              label="Cancel"
              size="small"
              :text="true"
              severity="alert"
              data-table-no-select="true"
              @click="closeEditor"
            />
            <base-button
              class=""
              label="Save"
              size="small"
              severity="primary"
              data-table-no-select="true"
              @click="saveText(field)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { parseDateTime } from "@global/helpers/date.js";
import { bus } from "@/main.js";
import { v4 as uuidv4 } from "uuid";
import vClickOutside from "@global/directives/v-click-outside-new.js"; // Import the directive

const emits = defineEmits([
  "row-selected",
  "save-editor",
  "show-editor",
  "close-editor",
  "text-expand",
  "text-close-expand"
]);
const props = defineProps({
  fields: {
    type: Array,
    default: () => [],
    validate: (value) => {
      value.forEach((element, index) => {
        if (!["name", "type"].includes(Object.keys(value))) {
          console.error(`field with index ${index} must have type and name.`);
          return false;
        } else if (
          ![
            "text",
            "button",
            "icon",
            "editor",
            "avatar",
            "input",
            "chip",
            "date",
            "checkbox"
          ].includes(element.type)
        ) {
          console.error(
            `field ${element.name} must have new type that is not registered.`
          );
          return false;
        }
        return true;
      });
    }
  },
  data: {
    type: Object,
    default: () => {}
  },
  actionable: {
    type: Boolean,
    default: true
  },
  field: {
    type: String,
    default: ""
  },
  containerWith: {
    type: Number,
    default: 100
  },
  size: {
    type: String,
    default: "whitespace-normal"
  },
  group: {
    type: String,
    default: ""
  },
  isShowEditor: {
    type: Boolean,
    default: false
  },
  isExpandingText: {
    type: Boolean,
    default: false
  }
});
const showEditor = ref(props.isShowEditor);
const showHover = ref(false);
const tempEditorText = ref("");
const expandedFieldIndex = ref(null); // Track expanded field
const showOverlay = ref(props.isExpandingText);

//cell value
const value = computed(() => {
  const fieldData = props.data[props.field];
  let newData = fieldData;
  // If 'name' exist in fieldData, return it
  if (fieldData && typeof fieldData == "object" && "name" in fieldData) {
    // return fieldData.name;
    newData = fieldData.name;
  }
  if (typeof newData === "string") {
    return newData
      .replaceAll("<p>", "<span style='display: inline-block'>")
      .replaceAll("</p>", "</span>");
  }
  return newData;
});

const isFieldDisabled = (field) => {
  return field.checkFieldVisibility(props.data) === "disabled";
};
const isFieldVisible = (field) => {
  return field.checkFieldVisibility(props.data);
};

//--------------------- BASE CHECKBOX LOGIC
const handCheckboxChange = (modelValue, field) => {
  if (field.role === "row-selector") {
    emits("row-selected", modelValue, props.data);
  }
};

//--------------------- BASE EDITOR LOGIC
//On editor text change => assign the value to draft value
const handleValueChange = (tempValue) => {
  if (tempValue && tempValue !== value.value) {
    tempEditorText.value = `<span class='text-warning'>Draft:</span>${tempValue.replace("<p>", "").replace("</p>", "")}`;
  }
};
//on save text
const saveText = (field) => {
  if (tempEditorText.value) {
    //emit event to save value
    emits(
      "save-editor",
      tempEditorText.value
        .replace("<span class='text-warning'>Draft:</span>", "")
        .trim()
    );
    clearDraftText(field);
  }
  closeEditor();
};
//show editor
const handleShowEditor = () => {
  showOverlay.value = false;
  showHover.value = false;
  emits("show-editor");
};

const clearDraftText = (field) => {
  //Clear draft value
  tempEditorText.value = null;
  //Clear draft value in the session storage
  const sessionKey = getSessionKey(field);
  sessionStorage.removeItem(sessionKey);
};

const closeEditor = () => {
  showEditor.value = !showEditor.value;
  emits("close-editor");
};

const getSessionKey = (field) => {
  return field ? `${field.name}-${props.data.id}` : null;
};

//--------------------- BASE TEXT LOGIC
const openOverlay = (data, fieldIndex) => {
  // showOverlay.value = data;
  expandedFieldIndex.value = fieldIndex;
  emits("text-expand");
};
const closeOverlay = () => {
  emits("text-close-expand");
};
const formatText = (text) => {
  return showOverlay.value ? text : text.replace(/<\/?[^>]+(>|$)/g, "");
};

// Load the content from sessionStorage when the component is mounted
onMounted(() => {
  const editorField = props.fields.find((field) => field.type === "editor");
  const sessionKey = getSessionKey(editorField);
  if (sessionKey) {
    const savedContent = sessionStorage.getItem(sessionKey);
    if (savedContent) {
      // tempEditorText.value = savedContent;
      tempEditorText.value = `<span class='text-warning'>Draft: </span>${savedContent}`;
    }
  }
});

watch(
  () => props.isShowEditor,
  (newVal) => {
    showEditor.value = newVal;
  }
);
watch(
  () => props.isExpandingText,
  (newVal) => {
    showOverlay.value = newVal;
  }
);
</script>

<style lang="scss" scoped>
.blinking-cursor {
  font-weight: 200;
  font-size: 25px;
  color: #000000;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
</style>
