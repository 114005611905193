<script setup>
import { defineAsyncComponent, computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    deafult: null
  },
  classes: {
    type: String,
    required: true,
    default:
      "fill-blue-500 hover:fill-red-500 stroke-1 stroke-blue-500 hover:stroke-red-500"
  },
  subFolderName: {
    type: String,
    default: "icons"
  }
});

const iconName = computed(() => {
  try {
    if (!props.name) {
      return null;
    }
    return defineAsyncComponent(
      async () =>
        await import(`../../assets/${props.subFolderName}/${props.name}.svg`)
    );
  } catch (error) {
    console.log(error);
    return null;
  }
});
</script>

<template>
  <component :is="iconName" :class="props.classes" />
</template>
