// useCascadeSelect.js
import { ref } from "vue";

export function useCascadeSelect() {
  // Reactive reference to store cascade select menu elements
  const cascadeRefs = ref([]);

  // Function to show the selected menu and close all others
  const handleShow = (index) => {
    closeAllExcept(index);
  };

  // Function to close all menus except the one at the specified index
  const closeAllExcept = (index) => {
    cascadeRefs.value.forEach((ref, i) => {
      if (ref && i !== index) {
        ref.hide();
      }
    });
  };

  // Function to handle hide events if needed
  const handleHide = (index) => {
    // Handle the hide event if needed
  };

  // Function to set the reference of a cascade menu
  const setCascadeRef = (index, el) => {
    if (el) {
      cascadeRefs.value[index] = el;
    }
  };

  return {
    cascadeRefs,
    handleShow,
    handleHide,
    setCascadeRef
  };
}
