<template>
  <div
    v-if="route.query._embed !== 'true'"
    class="px-6 sm:px-12 sticky top-0 shrink-0 bg-grey4 transition-all"
  >
    <div
      class="py-4 sm:border-b sm:border-grey3"
      :class="
        auth.currentUser
          ? 'h-20'
          : route.name == 'signin' || route.name == 'request-account'
            ? 'h-20'
            : 'h-[151px] sm:h-[106px]'
      "
    >
      <div
        v-if="auth.currentUser"
        class="gap-x-4 sm:gap-x-6 sm:justify-between justify-start gap-4 max-w-[calc(1500px-3rem)] m-auto h-full grid grid-cols-12"
      >
        <!--MOBILE ONLY-->
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden col-span-1"
          @click="openSidebar"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            ></path>
          </svg>
        </button>
        <!---->
        <div
          class="flex col-span-4 lg:col-span-5 text-start gap-x-3 items-center"
        >
          <button @click="undoLink" v-if="!!sectionProperties.undo">
            <base-vite-icon
              name="arrow-left"
              classes="stroke-black stroke-2 w-5 h-5"
            />
          </button>

          <h1
            :class="[
              sectionProperties.size,
              'font-bold overflow-hidden whitespace-nowrap text-ellipsis'
            ]"
          >
            {{ sectionProperties.title }}
          </h1>
        </div>
        <div class="flex gap-x-4 lg:gap-x-6 col-span-7 h-full">
          <global-search class="child flex" />
          <div
            style="max-width: 40%"
            class="flex items-center gap-x-4 lg:gap-x-6 child justify-end shrink-[8]"
            :class="{ hide: route.name === 'search' }"
          >
            <!-- Separator -->
            <div
              class="hidden lg:block lg:h-full lg:w-px lg:bg-grey3 rounded-lg"
              aria-hidden="true"
            ></div>

            <base-teleport
              v-if="
                userAuthStore.getters.getFullProfileInformation?.Claims.mtb &&
                !userAuthStore.state.isDemoModeActive
              "
            />
            <mtb-avatar />
          </div>
        </div>
      </div>
      <div
        v-else
        class="gap-x-4 flex flex-wrap sm:flex-nowrap sm:gap-x-6 sm:justify-between justify-start gap-y-2 sm:gap-y-4 max-w-[calc(1500px-3rem)] m-auto h-full"
      >
        <div
          class="lg:hidden flex justify-between w-full sm:w-auto border-b border-grey3 sm:border-0 pb-2 sm:pb-0"
        >
          <button
            type="button"
            class="-m-2.5 p-2.5 text-gray-700 col-span-1"
            @click="openSidebar"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              ></path>
            </svg>
          </button>
          <button
            type="button"
            @click="toggle"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <base-vite-icon
              name="profile"
              role="button"
              classes="stroke-2 w-4 h-4 fill-none stroke-black sm:hidden block"
            ></base-vite-icon>
          </button>
          <Menu :model="items" ref="menu" id="overlay_menu" :popup="true">
            <template #item="{ item, props }">
              <router-link v-slot="{ href, navigate }" :to="item.route" custom>
                <a
                  v-tooltip="item.tooltip"
                  :href="href"
                  v-bind="props.action"
                  @click="navigate"
                  class="flex gap-x-2 items-center"
                >
                  <span class="text-sm">{{ item.label }}</span>
                </a>
              </router-link>
            </template>
          </Menu>
        </div>

        <!---->
        <div
          class="flex w-full justify-between items-center bg-data2-light rounded-lg p-2 gap-2"
          v-if="
            route.name != GLOBAL_REQUEST_ACCOUNT && route.name != GLOBAL_SIGNIN
          "
        >
          <div
            class="flex flex-col items-start"
            v-if="route.meta.banner === 'startup'"
          >
            <h1 class="text-lg font-bold text-grey1 text-start">
              Startups: Got Solutions?
            </h1>
            <h2 class="text-base text-grey1 text-start lg:block hidden">
              Join MTB Ecosystem to connect with corporate innovation needs.
              Sign up or sign in to view & apply to all challenges!
            </h2>
          </div>
          <div
            class="flex flex-col items-start"
            v-if="route.meta.banner === 'corporate'"
          >
            <h1 class="text-lg font-bold text-grey1 text-start">
              Corporate: Seeking Innovation Solutions?
            </h1>
            <h2 class="text-base text-grey1 text-start lg:block hidden">
              Connect with Startups in MTB Ecosystems and achieve your
              innovation goals. Sign up to collaborate and innovate!
            </h2>
          </div>
          <div
            class="flex flex-col items-start"
            v-if="route.meta.banner === 'institution'"
          >
            <h1 class="text-lg font-bold text-grey1 text-start">
              Ecosystem builder: Empower your Innovation Ecosystem!
            </h1>
            <h2 class="text-base text-grey1 text-start lg:block hidden">
              Join MTB Ecosystem, support and promote your ecosystem growth and
              boost their innovation capabilities!
            </h2>
          </div>

          <div class="flex gap-x-4 items-center">
            <base-button
              label="Sign in"
              :outlined="true"
              class="sm:block hidden"
              severity="primary"
              @click="goToSignin"
            />
            <base-button
              class="sm:block hidden"
              :label="
                route.meta.banner === 'startup'
                  ? 'Sign up & Apply'
                  : route.meta.banner === 'corporate' &&
                      route.name == 'open-challenges'
                    ? 'Sign up & Create a Challenge'
                    : 'Sign up'
              "
              severity="primary"
              @click="goToSignup"
            />
            <base-button
              class="sm:hidden block"
              :label="
                route.meta.banner === 'startup'
                  ? 'Sign up & Apply'
                  : route.meta.banner === 'corporate' &&
                      route.name == 'open-challenges'
                    ? 'Sign up & Create a Challenge'
                    : 'Sign up'
              "
              size="small"
              severity="primary"
              @click="goToSignup"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import BaseTeleport from "@/modules/mtbers/components/base-teleport.vue";
import { bus } from "@/main.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useChallengesStore } from "../stores/challenges-store.js";
import Menu from "primevue/menu";
import { auth } from "@/plugins/firebase.js";
import { GLOBAL_SIGNIN, GLOBAL_REQUEST_ACCOUNT } from "../router.js";

const menu = ref();
const route = useRoute();
const router = useRouter();
const userAuthStore = useUserAuthStore();

const challengeStore = useChallengesStore();

const openSidebar = () => {
  bus.emit("open-menu");
};
const sectionProperties = ref({});
/**
 * undo if
 * - institution profile -->list of institution
 * - startup-profile --> query has the context
 * - challenges --> list of challenges
 */
//TODO: put it in a helper function to share
function undoLink() {
  //TODO: rework on this logic, can we use route.matched?
  if (route.meta && route.meta.undoLink) {
    return router.push({ name: route.meta.undoLink });
  } else if (route.meta && route.meta.undoPath) {
    return router.push({ path: route.meta.undoPath });
  } else if (router.options.history.state.back) {
    return router.back();
  } else {
    return router.push({ path: "/" });
  }
}

const getChallengeById = (id) => {
  const challenge = challengeStore.getters.getAccountChallenges.find(
    (challenge) => {
      return challenge.Id === id;
    }
  );
  const name = challenge?.Name;
  const title =
    challenge?.Challenge_State__c == "Draft" ? `Draft: ${name}` : name;

  return title || "";
};
const isMyProfile = computed(() => {
  return route.params?.id === userAuthStore.state.accountInfo?.id;
});

const items = computed(() => [
  {
    label: "Sign in",
    route: {
      name: GLOBAL_SIGNIN
    }
  },

  {
    label: "Sign up",
    route: { name: GLOBAL_REQUEST_ACCOUNT }
  }
]);
const toggle = (event) => {
  menu.value.toggle(event);
};
watchEffect(() => {
  if (
    route.name === "institution-profile" ||
    route.name === "startup-profile"
  ) {
    sectionProperties.value = {
      title: isMyProfile.value
        ? "My Profile"
        : route.name === "institution-profile"
          ? "Ecosystem Partner Profile"
          : "Startup Profile",
      undo: !isMyProfile.value,
      size: isMyProfile.value ? "text-3xl" : "text-2xl"
    };

    // challenges case
  } else if (
    (route.path.split("/").includes("challenge") ||
      route.path.split("/").includes("challenge-details")) &&
    route.path.split("/").includes("corporate")
  ) {
    sectionProperties.value = {
      title: getChallengeById(route.params?.id),
      undo: true,
      size: "text-2xl"
    };
  } else {
    sectionProperties.value = {
      title: route.meta.title,
      undo: route.meta.undo,
      size: route.meta.size
    };
  }
});
function goToSignin() {
  router.push({ name: GLOBAL_SIGNIN });
}
function goToSignup() {
  router.push({ name: GLOBAL_REQUEST_ACCOUNT });
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
}
.child {
  animation: 0.2s out-in forwards;

  &.hide {
    animation: 0.2s in-out forwards;
  }
}
@keyframes in-out {
  0% {
    transform: translateX(0%);
    width: 100%;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    width: 0px;
    opacity: 0;
  }
}
@keyframes out-in {
  0% {
    transform: translateX(100%);
    width: 0px;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    width: 100%;
    opacity: 1;
  }
}
</style>
