<template>
  <div>
    <!-- Title and subtitle -->
    <!-- <p class="text-gray-500 mb-4">Hide, show and add new custom fields</p> -->

    <!-- Search Input -->
    <div class="mb-4">
      <!-- <InputText v-model="searchQuery" placeholder="Search" class="w-full" /> -->
    </div>

    <!-- Toggle Switches for Columns -->
    <div class="space-y-3">
      <div
        v-for="(column, index) in filteredColumns"
        :key="index"
        class="flex justify-between items-center"
      >
        <span>{{ column.name }}</span>
        <!-- <ToggleButton
          v-model="column.visible"
          :onLabel="''"
          :offLabel="''"
          :onIcon="null"
          :offIcon="null"
          :class="['w-12', column.visible ? 'bg-blue-500' : 'bg-gray-200']"
        /> -->
      </div>
    </div>

    <!-- Add new field input -->
    <div class="mt-4 flex items-center space-x-2">
      <!-- <InputText v-model="newField" placeholder="Field Name" class="w-full" /> -->
      <i v-if="isValidField" class="pi pi-check text-green-500"></i>
    </div>

    <!-- Save Button -->
    <div class="mt-6">
      <!-- <Button
        label="Save"
        class="w-full p-button-primary"
        @click="saveSettings"
      /> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const searchQuery = ref("");
const newField = ref("");
const isValidField = computed(() => newField.value.trim().length > 0);

const columns = ref([
  { name: "Startup Name", visible: true },
  { name: "Challenge Name", visible: true },
  { name: "Advisor", visible: true },
  { name: "Advisor", visible: true },
  { name: "Reason", visible: true },
  { name: "Applied Date", visible: false },
  { name: "Suggest To Corp", visible: false }
]);

const filteredColumns = computed(() => {
  if (!searchQuery.value) return columns.value;
  return columns.value.filter((column) =>
    column.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const saveSettings = () => {
  console.log("Columns:", columns.value);
  console.log("New field:", newField.value);
};
</script>

<style scoped>
/* Additional custom styling (if necessary) */
</style>
