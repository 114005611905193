<template>
  <div>
    <!-- Share through Social medias -->

    <div class="mb-4">
      <h3 class="text-lg font-medium mb-2">Share through</h3>
      <div class="flex space-x-8">
        <base-button
          v-for="platform in platforms"
          :ref="platform.name"
          :key="platform.name"
          :text="true"
          :icon="platform.icon"
          :iconClass="platform.class"
          @click="shareSocialMedia(platform)"
        />
        <!-- <div
          class="teams-share-button flex flex-row items-center justify-center"
          :data-href="sharingLink"
        >
          <a
            class="flex items-center justify-center w-9 h-9"
            target="ms-teams-share-popup"
            @click="shareViaTeams"
            href="javascript:void(0);"
          >
            <base-vite-icon name="teams" classes="w-9 h-9" />
          </a>
        </div> -->
      </div>
    </div>
    <!-- Share through link -->
    <div class="mb-4">
      <h3 class="text-lg font-medium mb-3">Sharing link</h3>
      <div
        class="flex items-center justify-between border-grey3 border-1 p-2 rounded-lg"
      >
        <span class="truncate max-w-[2/5]">{{ genericShareLink }}</span>
        <div class="flex gap-2">
          <base-button
            v-if="!buttonClicked.copy"
            size="small"
            :outlined="true"
            severity="grey3"
            label="Copy"
            icon="copy"
            iconClass="w-4 h-4 stroke-grey1 stroke-2"
            @click="copyLink"
          />
          <base-button
            v-else
            size="small"
            :outlined="true"
            severity="success"
            label="Copied"
            icon="copy"
            iconClass="w-4 h-4 stroke-success stroke-2"
          />
          <base-button
            size="small"
            :outlined="true"
            severity="grey3"
            label="Preview"
            icon="preview"
            iconClass="w-4 h-4 stroke-grey1 stroke-2"
            @click="openPreviewLink"
          />
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="isAdvisor">
      <h3 class="text-lg font-medium mb-2 text-mtb">
        Choose utm_source and utm_campaing (advisor only)
      </h3>
      <div class="flex gap-4 w-full">
        <base-single-select
          class="w-full"
          v-model="utmSource"
          :options="utmSourceOptions"
          placeholder="Select a utm source"
        />
        <base-input-text
          class="w-full"
          v-model="utmCampaign"
          type="input-text"
          placeholder="Fill the utm campaign"
        />
      </div>
    </div>
    <!-- Embed code - internal only -->
    <div class="mb-4" v-if="embedCode">
      <h3 class="text-lg font-medium mb-3">Embed Code</h3>
      <div
        class="flex items-center justify-between border-grey3 border-1 p-2 rounded-lg gap-4"
      >
        <!-- <textarea
          readonly
          :value="embedCode"
          rows="2"
          class="w-full border-none focus:ring-0"
        ></textarea> -->
        <span class="truncate max-w-[2/5]">{{ embedCode }}</span>

        <div class="flex gap-2">
          <base-button
            v-if="!buttonClicked.embedCodeCopy"
            size="small"
            :outlined="true"
            severity="grey3"
            label="Copy"
            icon="copy"
            iconClass="w-4 h-4 stroke-grey1 stroke-2"
            @click="copyEmbedCode"
          />
          <base-button
            v-else
            size="small"
            :outlined="true"
            severity="success"
            label="Copied"
            icon="copy"
            iconClass="w-4 h-4 stroke-success stroke-2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, onMounted } from "vue";
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { auth } from "@/plugins/firebase";
const dialogRef = inject("dialogRef");
const userAuthStore = useUserAuthStore();
const utmCampaign = ref("");
const utmSource = ref("");
const utmSourceOptions = ref([
  { value: "Linkedin", label: "Linkedin" },
  { value: "Facebook", label: "Facebook" },
  { value: "Twitter", label: "Twitter" },
  { value: "Instagram", label: "Instagram" },
  { value: "Whatsapp", label: "Whatsapp" },
  { value: "Email", label: "Email" },
  { value: "Slack", label: "Slack" },
  { value: "Other", label: "Other" }
]);
const modalData = computed(() => {
  return dialogRef.value.data;
});
const isAdvisor = computed(() => {
  return (
    userAuthStore.getters.isMtbUser && !userAuthStore.state.isDemoModeActive
  );
});
const userId = computed(() =>
  auth.currentUser
    ? isAdvisor.value
      ? //if we use the id for the advisor we use the firestore uid
        "advisor"
      : userAuthStore.state?.userInfo?.Id
    : "unauth"
);
const genericShareLink = computed(() => {
  return getLinkWithUTM(sharingLink.value);
});
const getUtmSource = (platformName) => {
  return platformName?.toLowerCase() || utmSource.value?.label || "share";
};

const getUtmCampaign = () => {
  return utmCampaign.value || userId.value;
};
const getLinkWithUTM = (link, platformName) => {
  const source = getUtmSource(platformName);
  const campaign = getUtmCampaign();
  const separator = link.includes("?") ? "&" : "?";
  return `${link}${separator}utm_source=${source}&utm_campaign=${campaign}`;
};

// Define reactive variables
const platforms = ref([
  { name: "Gmail", icon: "gmail", class: "w-[42px] h-12" },
  { name: "LinkedIn", icon: "linkedin_color", class: "w-9 h-9" },
  { name: "Twitter", icon: "twitter", class: "w-9 h-9" }
  // { name: "Slack", icon: "slack", class: "w-9 h-9" }
  // { name: "Teams", icon: "teams", class: "w-9 h-9" }
]);

const sharingLink = computed(() => {
  return modalData.value.sharingUrl;
});
//UTM source embed utm campaing get from text || advisor || user
const embedLink = computed(() => {
  return modalData.value.embedUrl;
});
const embedCode = computed(() => {
  if (!embedLink.value) {
    return null;
  }

  const embedUrlWithUtm = getLinkWithUTM(embedLink.value, "iframe");
  const embedIframeCode = `<iframe src="${embedUrlWithUtm}" frameborder="0" allowfullscreen style="width: 100%; height: 100%"></iframe>`;

  return embedIframeCode;
});

const buttonClicked = ref({
  copy: false,
  preview: false,
  embedCodeCopy: false
});

// Define methods
const shareSocialMedia = (platform) => {
  const urlWithUtm = getLinkWithUTM(sharingLink.value, platform.name);
  if (platform.name === "Gmail") {
    shareViaGmail(urlWithUtm);
  } else if (platform.name === "Twitter") {
    const testUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlWithUtm)}`;
    window.open(testUrl, "_blank");
  } else if (platform.name == "LinkedIn") {
    //THis url can't add text, only the url is available
    const url =
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
      encodeURIComponent(urlWithUtm);

    // //THis url can add text
    // const testUrl = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(text)} ${sharingLink.value}`;

    window.open(url, "_blank");
  }

  mixpanelMtbTracking({
    eventName: "Share",
    properties: {
      share_source: platform.name,
      page_name: modalData.value?.pageName,
      share_url: getLinkWithUTM(
        modalData.value.mixpanelTrackingUrl || modalData.value.sharingUrl,
        platform.name
      )
    }
  });
};

const copyLink = () => {
  buttonClicked.value.copy = !buttonClicked.value.copy;
  navigator.clipboard.writeText(genericShareLink.value);
  setTimeout(() => {
    buttonClicked.value.copy = !buttonClicked.value.copy;
  }, 2000);
};

const copyEmbedCode = () => {
  buttonClicked.value.embedCodeCopy = !buttonClicked.value.embedCodeCopy;
  navigator.clipboard.writeText(embedCode.value);
  setTimeout(() => {
    buttonClicked.value.embedCodeCopy = !buttonClicked.value.embedCodeCopy;
  }, 2000);
};
const openPreviewLink = () => {
  window.open(encodeURI(sharingLink.value));
};

const shareViaGmail = (urlWithUtm) => {
  // const subject = encodeURIComponent(
  //   "Check out this Open Innovation Challenge!"
  // );

  const body = encodeURIComponent(`${urlWithUtm}`);

  const url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${body}&ui=2&tf=1&pli=1`;

  window.open(url, "sharer", "toolbar=0,status=0,width=648,height=395");
};

const closeModal = () => {
  dialogRef.value.close();
};
const shareViaTeams = (event) => {
  event.preventDefault(); // Prevent default link behavior

  window.open(
    `https://teams.microsoft.com/share?href=${encodeURIComponent(sharingLink.value)}&amp;referrer=app.mtbecosystem.com`,
    "ms-teams-share-popup",
    "width=700,height=600"
  );
  return false;
};
</script>

<style scoped>
button {
  transition: color 0.2s;
}
</style>
