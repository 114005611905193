<template>
  <div class="h-full w-full">
    <div class="h-full flex flex-col px-6 py-6">
      <!-- ACTION BAR -->
      <div class="flex flex-wrap items-center justify-start gap-8 pb-4 pt-4">
        <div v-for="action in actions" :key="action.name">
          <base-button
            v-if="action.type === 'button'"
            :severity="action.severity()"
            :size="action?.size"
            :text="action.text()"
            :label="action.label()"
            :icon="action.icon"
            :iconClass="action.iconClass()"
            @click="action.clicked()"
          />

          <base-dropdown
            v-if="action.type === 'dropdown'"
            :titleIcon="action.icon"
            :title="action.label()"
            :header="action?.header"
            titleStyle="text-primary font-bold text-base"
            :titleIconStyle="action.iconClass()"
            :icon-style="action.iconClass()"
            :isIconOnly="true"
            :filter="action?.filter"
            :isCustomDropdown="
              ['manageFields', 'sortBy', 'groupBy'].includes(action.name)
                ? true
                : false
            "
            optionLabel="name"
            :options="action.dropdownOptions()"
            :defaultSelectedOption="action.defaultSelectedOption"
            :sortSelectedValue="action.sortSelectedValue"
            @on-toggle="onToggleColumn"
            @on-sort-change="onSortColumn"
            @update:modelValue="handleDropdownSelection"
          />
        </div>
      </div>
      <!-- TABLE -->
      <div class="table-wrapper prevent-select" tabindex="0">
        <table id="resizeMe" class="table" ref="tableElement">
          <thead
            ref="tableHeader"
            class="sticky top-0 z-50 border-y border-y-1 border-grey3"
          >
            <tr>
              <!-- Render dynamic column headers with resizable and draggable functionality -->
              <th
                v-for="(column, index) in columns?.filter(
                  (col) => col.isColumnVisible
                )"
                :key="column.field"
                :class="{
                  'sticky-column': column.frozenColumn,
                  '!border-r-grey5 border-r-2': isLastFrozenColumn(
                    column,
                    index
                  ),
                  '!border-r-primary':
                    droppingIndex == index &&
                    originalColumnIndex < droppingIndex,
                  '!border-l-primary':
                    droppingIndex == index &&
                    originalColumnIndex > droppingIndex
                }"
                class="px-2 py-3 bg-grey4 hover:bg-[#e9e9f0] border-grey3 border-1 border-s-grey4 last:border-e-grey4"
                :style="getColumnStyle(column, 'header')"
              >
                <!--  @click="selectColumn(index)" -->
                <div class="relative items-center flex w-full h-[25px]">
                  <div
                    :draggable="
                      !isResizing &&
                      column?.reorderColumn !== false &&
                      !column.frozenColumn
                    "
                    @dragstart="!isResizing && startDrag(index)"
                    @dragover.prevent
                    @dragenter="!isResizing && dragEnter(index)"
                    @drop="!isResizing && dropColumn(index)"
                    @dragend="endDrag"
                    :style="{
                      cursor: column.frozenColumn ? 'default' : 'grab'
                    }"
                    class="font-bold text-base flex items-center justify-between h-[25px] w-full"
                  >
                    <span class="truncate">{{ column.header }}</span>
                    <div class="flex items-center justify-end">
                      <!-- sorting icon -->
                      <base-vite-icon
                        name="arrow-up"
                        v-if="
                          tableSort.some(
                            (ele) =>
                              ele.id === column.field && ele.direction == 'asc'
                          )
                        "
                        @click="sortCallback(column, 'desc')"
                        role="button"
                        classes="stroke-secondary stroke-2 w-4 h-4 bg-secondary-light me-2"
                      ></base-vite-icon>
                      <base-vite-icon
                        name="arrow-down"
                        v-else-if="
                          tableSort.some(
                            (ele) =>
                              ele.id === column.field && ele.direction == 'desc'
                          )
                        "
                        @click="sortCallback(column, 'asc')"
                        role="button"
                        classes="stroke-secondary stroke-2 w-4 h-4 bg-secondary-light me-2"
                      ></base-vite-icon>
                      <!-- frozen icon -->
                      <base-vite-icon
                        name="pin"
                        v-if="column.frozenColumn"
                        @click="freezeColumnCallback(column)"
                        :role="column.reorderColumn ? 'button' : null"
                        :classes="`w-4 h-4 me-2 ${
                          column.reorderColumn
                            ? 'fill-secondary bg-secondary-light'
                            : 'fill-grey2 bg-grey3'
                        }
                  `"
                      ></base-vite-icon>
                      <base-cascadeselect
                        dropdownicon="chevron-down"
                        iconClasses="stroke-black stroke-2 w-5 h-5 hover:bg-primary hover:stroke-white rounded-sm"
                        :calendarOptions="getTableHeaderConfig(column)"
                        :internalTableHeader="true"
                        @update:modelValue="
                          (selectedValue) => {
                            if (!selectedValue) {
                              return;
                            }
                            handleColumnSelection(selectedValue, column);
                          }
                        "
                        @show="handleShow(index)"
                        @hide="handleHide(index)"
                        :ref="(el) => setCascadeRef(index, el)"
                      />
                    </div>
                  </div>

                  <div class="resizer mt-[-13px] mr-[-8px]"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody ref="tableBody">
            <!-- Render grouped rows if there is a group by column -->
            <template v-if="dataTableFormatted.length === 0">
              <div
                class="bg-white sticky top-[50px] left-0 z-10 lg:w-[calc(100vw-11rem)] w-[calc(100vw-6rem)] 2xl:w-[calc(100vw-(100vw-1452px))]"
              >
                <fallback-empty-state
                  :isRow="false"
                  message=" No results found matching the selected criteria."
                />
              </div>
            </template>

            <template v-if="groupedRows">
              <template
                v-for="(group, groupKey) in groupedRows"
                :key="groupKey"
              >
                <!-- Group header row -->
                <div
                  class="bg-white sticky top-[50px] left-0 z-10 lg:w-[calc(100vw-11rem)] w-[calc(100vw-6rem)] 2xl:w-[calc(100vw-(100vw-1452px))]"
                >
                  <div
                    class="w-full border-b border-b-1 border-grey3 p-2 font-bold text-left sticky"
                    style="left: 0; right: 0"
                  >
                    <base-checkbox-single
                      v-if="!calculateNonMultiselectGroup.includes(groupKey)"
                      class="align-sub me-3"
                      label=""
                      name="multiselection"
                      type="checkbox"
                      checkboxListClass="flex-col"
                      :model-value="allCheckedState[groupKey]"
                      :showErrorMessage="false"
                      @update:model-value="
                        (value) => selectEntireCategory(value, groupKey)
                      "
                    />
                    <div
                      v-if="
                        isMultiSelectionRow[groupKey] &&
                        props.groupAllActionConfig.length > 0
                      "
                      class="inline-flex items-center align-sub gap-x-3 mr-3"
                    >
                      <base-button
                        v-for="action in props.groupAllActionConfig"
                        :key="action.name"
                        v-tooltip.top="action.tooltip()"
                        class=""
                        :size="action.size"
                        :label="action.label"
                        :icon="action.icon"
                        :iconClass="action.iconClass"
                        :text="true"
                        @click="action.click(group, selectedRows)"
                      />
                    </div>
                    <span
                      ><span class="capitalize">{{ groupByColumn }}</span
                      >: {{ groupKey }} ({{ group.length }})</span
                    >
                    <base-vite-icon
                      @click="toggleGroup(groupKey)"
                      role="button"
                      name="chevron-right"
                      :classes="`inline-block w-5 h-5 stroke-black stroke-2 ms-2 ${!collapsedGroups[groupKey] ? 'hidden' : ''}`"
                    />
                    <base-vite-icon
                      @click="toggleGroup(groupKey)"
                      role="button"
                      name="chevron-down"
                      :classes="`inline-block w-5 h-5 stroke-black stroke-2 ms-2 ${collapsedGroups[groupKey] ? 'hidden' : ''}`"
                    />
                  </div>
                </div>
                <!-- Grouped rows -->
                <!-- @contextmenu.prevent="
                      showContextMenu(
                        $event,
                        groupKey,
                        rowIndex,
                        colIndex,
                        group
                      )
                    " -->
                <template v-if="!collapsedGroups[groupKey]">
                  <tr
                    v-for="(row, rowIndex) in renderedRows[groupKey]"
                    :key="rowIndex"
                    :id="
                      'id_' +
                      groupKey
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .replace(/[^\w]/g, '') +
                      '_' +
                      rowIndex
                    "
                    class="table-row"
                    :class="[
                      selectedRows.some((ele) => ele.id == row.id)
                        ? 'table-row-selected'
                        : ''
                    ]"
                  >
                    <!--  @mousedown="
                        handleMouseDown(
                          $event,
                          groupKey,
                          rowIndex,
                          colIndex,
                          column
                        )
                      "
                      @mousemove="
                        extendSelection(groupKey, rowIndex, colIndex, column)
                      "
                      @mouseup="
                        handleMouseUp($event, groupKey, rowIndex, colIndex)
                      " -->

                    <td
                      v-for="(column, colIndex) in columns?.filter(
                        (col) => col.isColumnVisible
                      )"
                      :key="`${groupKey}-${rowIndex}-${colIndex}`"
                      :class="[
                        zIndexRows[rowIndex],
                        column.frozenColumn && 'sticky-column',
                        isSelectedCell(groupKey, rowIndex, colIndex) &&
                          'selected-cell',
                        isHighlightedCell(groupKey, rowIndex, colIndex) &&
                          'highlighted-cell',
                        isLastFrozenColumn(column, colIndex) &&
                          '!border-r-grey5 border-r-2'
                      ]"
                      class="border-1 border-s-0 border-gray3 last:border-e-0 border-t-0"
                      :style="getColumnStyle(column)"
                    >
                      <!-- Render cell based on column.field -->

                      <base-table-body-cell
                        class="body-cell"
                        :data="row"
                        :fields="getFieldConfig(column, groupKey)"
                        :field="column.field"
                        :containerWith="column?.width - 16"
                        :size="rowHeight"
                        :group="groupByColumn"
                        :is-show-editor="
                          isEditingCell(rowIndex, column.field, row)
                        "
                        :is-expanding-text="
                          isExpandingOverlay(rowIndex, column.field, row)
                        "
                        @row-selected="handleSingleRowSelect"
                        @show-editor="showEditor(rowIndex, column.field, row)"
                        @close-editor="closeEditor"
                        @save-editor="
                          (value) => handleSaveTextEditor(value, column, row)
                        "
                        @text-expand="
                          handleRowZIndex(rowIndex, column.field, row)
                        "
                        @text-close-expand="closeTextExpand"
                      />
                    </td>
                  </tr>
                </template>
              </template>
            </template>

            <!-- Render non-grouped rows -->
            <template v-else>
              <tr v-for="(row, rowIndex) in dataTableFormatted" :key="rowIndex">
                <td
                  v-for="(column, colIndex) in columns?.filter(
                    (col) => col.isColumnVisible
                  )"
                  class="border-1 border-s-0 border-gray3 last:border-e-0 border-t-0"
                  :style="getColumnStyle(index)"
                  :key="`${groupKey}-${rowIndex}-${colIndex}`"
                  :class="[
                    zIndexRows[rowIndex],
                    column.frozenColumn && 'sticky-column',
                    isSelectedCell(groupKey, rowIndex, colIndex) &&
                      'selected-cell',
                    isHighlightedCell(groupKey, rowIndex, colIndex) &&
                      'highlighted-cell',
                    isLastFrozenColumn(column, colIndex) &&
                      '!border-r-grey5 border-r-2'
                  ]"
                >
                  <base-table-body-cell
                    class="body-cell"
                    :data="row"
                    :fields="column.fieldCellConfig"
                    :field="column.field"
                    :containerWith="column?.width - 16"
                    :size="rowHeight"
                    :group="groupByColumn"
                    :is-show-editor="isEditingCell(rowIndex, column.field, row)"
                    :is-expanding-text="
                      isExpandingOverlay(rowIndex, column.field, row)
                    "
                    @row-selected="handleSingleRowSelect"
                    @show-editor="showEditor(rowIndex, column.field, row)"
                    @close-editor="closeEditor"
                    @save-editor="
                      (value) => handleSaveTextEditor(value, column, row)
                    "
                    @text-expand="handleRowZIndex(rowIndex, column.field, row)"
                    @text-close-expand="closeTextExpand"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <!-- Context Menu -->

        <div
          v-if="contextMenuVisible"
          class="context-menu"
          :style="{
            top: `${contextMenuPosition.y}px`,
            left: `${contextMenuPosition.x}px`
          }"
        >
          <ul>
            <li @click="copyCell">Copy</li>
            <li @click="pasteCell">Paste</li>
            <li @click="clearCell">Clear</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  nextTick,
  toRefs,
  onBeforeUnmount
} from "vue";
import { bus } from "@/main.js";
import { makeUniqueArray } from "@global/helpers/array.js";
import { getSortLabel } from "@global/constants/sort-label.js";

import {
  convertFiltersToUrlQuery,
  convertSortToUrlQuery,
  convertGroupByToUrlQuery,
  mapUrlQueryToState
} from "@global/helpers/url.js";
import { useRoute, useRouter } from "vue-router";
import { useSortModal } from "@global/hooks/use-sort-modal.js";
const { showSort } = useSortModal();
// Vue Router hooks
const route = useRoute();
const router = useRouter();
// Props for column configuration and table data
const props = defineProps({
  columnConfig: {
    type: Array,
    default: () => [],
    required: true
  },
  tableData: {
    type: Array,
    default: () => [],
    required: true
  },
  groupRowsBy: {
    type: String,
    default: "",
    require: true
  },
  nonMultiselectGroup: {
    type: Array,
    default: () => ["Accepted", "Rejected"]
  },
  multiselectRowConfig: {
    type: Object,
    default: () => {
      () => true;
    }
  },
  defaultSort: {
    type: Array,
    default: () => [
      {
        id: "startup",
        name: "Startup",
        direction: "asc"
      }
    ]
  },
  groupAllActionConfig: {
    type: Array,
    default: () => []
  }
});
//--------------------- GENERAL VARIABLES
// Define headers and rows dynamically
const columns = ref(
  props.columnConfig.map((col) => ({
    ...col,
    isColumnVisible: true
  }))
);
const rowHeight = ref("short");
// Group by column state
const groupByColumn = ref(props.groupRowsBy);
// Collapsed group state for expand/collapse functionality
const collapsedGroups = ref({});
// Variables to store the state of dragging and resizing
let draggedColumnIndex = null;
let targetColumnIndex = null;
const droppingIndex = ref(null);
const originalColumnIndex = ref(null);
let isResizing = false; // New flag to track resizing
const selectedRows = ref([]);
const { tableData } = toRefs(props);

// Group by column (object with full details)
const selectedRowGroupByConfig = computed(
  () =>
    props.columnConfig.find(
      (column) => column.groupByConfig?.displayName === groupByColumn.value
    )?.groupByConfig
);
//Table data after formatting
const dataTableFormatted = computed(() => {
  if (process.env.NODE_ENV === "development") {
    console.log("@@@computed data updated");
  }
  return tableData.value.map((item) => {
    return {
      ...item,
      actions: selectedRows.value.some((row) => row.id === item.id)
    };
  });
});

//--------------------- GROUP BY COLUMN LOGIC START
const groupedRows = computed(() => {
  if (!groupByColumn.value) {
    return null; // No grouping if groupBy is not set
  }

  const columnGroupedBy = columns.value.find(
    (c) => c.field === groupByColumn.value
  );
  // Create a dictionary of groups
  const groups = dataTableFormatted.value.reduce((acc, dataItem) => {
    const groupKey =
      dataItem[columnGroupedBy?.field] &&
      typeof dataItem[columnGroupedBy?.field] == "object" &&
      "name" in dataItem[columnGroupedBy?.field]
        ? dataItem[columnGroupedBy?.field]?.name || "--"
        : dataItem[columnGroupedBy?.field];

    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(dataItem);
    return acc;
  }, {});
  if (process.env.NODE_ENV === "development") {
    console.log("@@groups", groups);
  }

  // Custom sorting logic for group keys, "Pending" should appear first
  const customGroupOrder = (a, b) => {
    if (a === "Pending") return -1; // "Pending" comes first
    if (b === "Pending") return 1; // "Pending" comes first

    // Other sorting rules (alphabetical by default)
    return a.toString().localeCompare(b.toString(), undefined, {
      numeric: true, // Handle numerical strings properly
      sensitivity: "base" // Case-insensitive comparison
    });
  };

  // Sort the group keys based on custom order and create sorted object
  const sortedGroups = Object.keys(groups)
    .sort(customGroupOrder)
    .reduce((acc, key) => {
      acc[key] = groups[key];
      return acc;
    }, {});

  return sortedGroups;
});

const toggleGroup = (groupKey) => {
  collapsedGroups.value[groupKey] = !collapsedGroups.value[groupKey];
  nextTick(() => initializeResizableColumns());
};

//--------------------- COLUMN STYLE (DEFAULT WIDTH + FROZEN) MANAGEMENT
const getColumnStyle = (column, type) => {
  const index = columns.value.findIndex((col) => col.field === column.field);
  const width = column.width || 150; // Default to 150px if no width is set
  let left = 0;

  for (let i = 0; i < index; i++) {
    if (columns.value[i].frozenColumn) {
      left += parseInt(columns.value[i].width || 150);
    }
  }

  return column.frozenColumn
    ? {
        position: "sticky",
        left: `${left}px`,
        width: `${width}px`,
        zIndex: type === "header" ? 3 : 2
      }
    : {
        width: `${width}px`,
        zIndex: 1
      };
};
const zIndexRows = ref({});
const activeExpandText = ref(null);
const handleRowZIndex = (rowIndex, columnField, row) => {
  zIndexRows.value[rowIndex] = "z-[5]";
  activeExpandText.value = { rowIndex, columnField, id: row.id };
};
const closeTextExpand = () => {
  activeExpandText.value = null;
};
function isExpandingOverlay(rowIndex, columnField, row) {
  return (
    activeExpandText.value &&
    activeExpandText.value.rowIndex === rowIndex &&
    activeExpandText.value.columnField === columnField &&
    activeExpandText.value.id === row?.id
  );
}

//--------------------- SELECT CELL LOGIC
const selectedCells = ref(new Set()); // Store selected cells as "rowIndex-colIndex"
const isSelecting = ref(false);
const startCell = ref({ groupKey: null, rowIndex: null, colIndex: null });

// Helper function to generate cell key
const generateCellKey = (groupKey, rowIndex, colIndex) =>
  `${groupKey}-${rowIndex}-${colIndex}`;
// Check if cell is selected
const isSelectedCell = (groupKey, rowIndex, colIndex) => {
  return selectedCells.value.has(generateCellKey(groupKey, rowIndex, colIndex));
};
// Check if cell is part of selection range
const isHighlightedCell = (groupKey, rowIndex, colIndex) => {
  if (!startCell.value.groupKey || !isSelecting.value) return false;
  const {
    groupKey: startGroup,
    rowIndex: startRow,
    colIndex: startCol
  } = startCell.value;
  const {
    groupKey: endGroup,
    rowIndex: endRow,
    colIndex: endCol
  } = getEndCell();

  return (
    groupKey === startGroup &&
    groupKey === endGroup &&
    rowIndex >= Math.min(startRow, endRow) &&
    rowIndex <= Math.max(startRow, endRow) &&
    colIndex >= Math.min(startCol, endCol) &&
    colIndex <= Math.max(startCol, endCol)
  );
};

const isLastFrozenColumn = (column, colIndex) => {
  const lastColumn = columns.value
    .filter((col) => col.frozenColumn && col.isColumnVisible)
    .map((ele, index) => index)
    .sort((a, b) => b - a)[0];
  return lastColumn == colIndex;
};
// Get the end cell position based on current selection
const getEndCell = () => {
  const selectedArray = Array.from(selectedCells.value);
  const lastSelected = selectedArray[selectedArray.length - 1];
  if (!lastSelected) return startCell.value;
  const [groupKey, rowIndex, colIndex] = lastSelected.split("-");
  return { groupKey, rowIndex: Number(rowIndex), colIndex: Number(colIndex) };
};

// Start cell selection
const startSelection = (groupKey, rowIndex, colIndex, column) => {
  if (
    !column.selectable ||
    event.target.closest('[data-table-no-select="true"]') ||
    Object.keys(allCheckedState.value)
      .filter((key) => allCheckedState.value[key])
      .includes(groupKey)
  ) {
    selectedCells.value.clear();
    return;
  }

  isSelecting.value = true;
  selectedCells.value.clear();
  startCell.value = { groupKey, rowIndex, colIndex };
  selectedCells.value.add(generateCellKey(groupKey, rowIndex, colIndex));
};
// Extend cell selection
const extendSelection = (groupKey, rowIndex, colIndex, column) => {
  if (!isSelecting.value || !column.selectable) return;
  selectedCells.value.clear();

  const {
    groupKey: startGroup,
    rowIndex: startRow,
    colIndex: startCol
  } = startCell.value;
  const endRow = rowIndex;
  const endCol = colIndex;

  // Only select within the same group
  if (groupKey !== startGroup) return;

  for (
    let r = Math.min(startRow, endRow);
    r <= Math.max(startRow, endRow);
    r++
  ) {
    for (
      let c = Math.min(startCol, endCol);
      c <= Math.max(startCol, endCol);
      c++
    ) {
      selectedCells.value.add(generateCellKey(groupKey, r, c));
    }
  }
};
// Handle Mouse Down - Only for left button click
const handleMouseDown = (event, groupKey, rowIndex, colIndex, column) => {
  if (event.button === 0) {
    startSelection(groupKey, rowIndex, colIndex, column);
  }
};

// Handle Mouse Up - Check for left or right click
const handleMouseUp = (event, groupKey, rowIndex, colIndex) => {
  if (event.button === 0) {
    endSelection();
  }
  if (event.button === 2 && selectedCells.value.size > 0) {
    showContextMenu(event, groupKey, rowIndex, colIndex);
  }
};

// End cell selection
const endSelection = () => {
  if (process.env.NODE_ENV === "development") {
    console.log("End selection !");
  }
  isSelecting.value = false;
};

// Context menu state
const contextMenuVisible = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });
const rightClickedCell = ref({
  groupKey: null,
  rowIndex: null,
  colIndex: null
});
const copiedData = ref([]); // Store copied data in a reactive variable

// Show context menu on right-click
const showContextMenu = (event, groupKey, rowIndex, colIndex) => {
  if (selectedCells.value.size === 0) return;
  rightClickedCell.value = { groupKey, rowIndex, colIndex };
  contextMenuVisible.value = true;

  // Correctly calculate the position including any scrolling offsets
  const containerRect = event.target
    .closest(".table-wrapper")
    .getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  contextMenuPosition.value = {
    x: event.clientX - containerRect.left + scrollLeft,
    y: event.clientY - containerRect.top + scrollTop
  };
};

// Hide context menu
const hideContextMenu = () => {
  contextMenuVisible.value = false;
};

// Copy cell content
const copyCell = () => {
  if (selectedCells.value.size === 0) return; // No cells selected
  copiedData.value = []; // Clear previous copied data
  const groupedData = {};

  // Organize selected cells into rows and columns for easier processing
  selectedCells.value.forEach((cellKey) => {
    const [groupKey, rowIndex, colIndex] = cellKey.split("-");
    if (process.env.NODE_ENV === "development") {
      console.log("==>groupKey", groupKey, rowIndex, colIndex);
    }
  });

  // Feedback or additional logic for copying (optional)
  //   console.log("Data copied:", copiedData.value);

  hideContextMenu();
  //   alert(`Copied: ${clipboardData.value}`);
};

// Paste cell content
const pasteCell = () => {
  //   if (!clipboardData.value) return;
  //   const { groupKey, rowIndex, colIndex } = rightClickedCell.value;
  //   //   props.groupedRows[groupKey][rowIndex][props.columns[colIndex].field] =
  //   //     clipboardData.value;
  //   hideContextMenu();
  //   alert(`Pasted: ${clipboardData.value}`);
};

// Clear cell content
const clearCell = () => {
  const { groupKey, rowIndex, colIndex } = rightClickedCell.value;
  //   props.groupedRows[groupKey][rowIndex][props.columns[colIndex].field] = "";
  hideContextMenu();
};
// Reference to the table element
const tableElement = ref(null);
const tableHeader = ref(null);
const tableBody = ref(null);
const tableRowGroupHeader = ref(null);
// Deselect all cells when clicking outside the table or selected cells
const handleClickOutside = (event) => {
  // ||
  // (tableHeader.value !== event.target && event.target !== tableBody.value) ||
  // event.target === tableRowGroupHeader.value

  if (!tableElement?.value?.contains(event.target)) {
    selectedCells.value.clear();
  } else if (tableRowGroupHeader?.value) {
    if (process.env.NODE_ENV === "development") {
      console.log("DEBUG click out side!", tableRowGroupHeader?.value);
    }
  }
};

// Select all cells in the clicked column
const selectColumn = (colIndex) => {
  selectedCells.value.clear();
  Object.keys(groupedRows.value).forEach((groupKey) => {
    const groupRows = groupedRows.value[groupKey];
    groupRows.forEach((_, rowIndex) => {
      selectedCells.value.add(generateCellKey(groupKey, rowIndex, colIndex));
    });
  });
  if (process.env.NODE_ENV === "development") {
    console.log("@@@@selectedCells", selectedCells.value);
  }
};

//--------------------- RESIZE COLUMN LOGIC
const initializeResizableColumns = () => {
  const table = document.getElementById("resizeMe");
  // Check if the table element exists
  if (!table) {
    return; // Exit early if table does not exist
  }
  let cols = Array.from(table.querySelectorAll("th"));

  const updateTableWidth = () => {
    table.style.width =
      cols.reduce((sum, col) => sum + (parseInt(col.style.width) || 0), 0) +
      "px";
  };

  cols.forEach(function (col) {
    let resizer = col.querySelector(".resizer");
    if (!resizer) {
      resizer = document.createElement("div");
      resizer.classList.add("resizer");
      col.appendChild(resizer);
    }

    resizer.style.height = `${table.offsetHeight}px`;
    createResizableColumn(col, resizer, updateTableWidth);
  });

  updateTableWidth();
};

const createResizableColumn = function (col, resizer, updateTableWidth) {
  let x = 0;
  let w = parseInt(window.getComputedStyle(col).width, 10);
  col.style.width = `${w}px`;

  const mouseDownHandler = function (e) {
    x = e.clientX;
    w = parseInt(window.getComputedStyle(col).width, 10);

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
    resizer.classList.add("resizing");
  };

  const mouseMoveHandler = function (e) {
    const dx = e.clientX - x;
    const newWidth = w + dx;
    const minWidth = 110;

    if (newWidth > minWidth) {
      col.style.width = `${newWidth}px`;

      const table = document.getElementById("resizeMe");
      let cols = Array.from(table.querySelectorAll("th")); // Refresh columns dynamically
      const columnIndex = cols.indexOf(col); // Get the current index based on DOM

      columns.value[columnIndex].width = newWidth;

      const rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        const td = row.children[columnIndex]; // Adjust the corresponding TD width
        if (td) {
          td.style.width = `${newWidth}px`;
        }
      });

      updateTableWidth();
    }
  };

  const mouseUpHandler = function () {
    resizer.classList.remove("resizing");
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  resizer.addEventListener("mousedown", mouseDownHandler);
};
//--------------------- EDITOR UNIQUE OPEN LOGIC
const activeEditor = ref(null); // To track the active editor { rowIndex, columnField }
//Check if there is the cell is in editing mode
function isEditingCell(rowIndex, columnField, row) {
  return (
    activeEditor.value &&
    activeEditor.value.rowIndex === rowIndex &&
    activeEditor.value.columnField === columnField &&
    activeEditor.value.id === row?.id
  );
}
//If one editor is opening, assign the active editor value
function showEditor(rowIndex, columnField, row) {
  if (!activeEditor.value) {
    activeEditor.value = { rowIndex, columnField, id: row.id };
  }
}
//Return the editor to the original state
function closeEditor() {
  activeEditor.value = null;
}
function handleSaveTextEditor(value, column, rowData) {
  const index = tableData.value.findIndex((ele) => ele.id === rowData.id);
  //Assign local data so we don't need to wait for the update data from the backend
  tableData.value[index] = {
    ...rowData,
    [column.field]: {
      index: tableData.value[index][column.field]?.index || value,
      name: value
    }
  };
  const editorField = column
    .fieldCellConfig()
    .find((item) => item.type === "editor");
  editorField.saveTextEditor(value, rowData[column.field], rowData);
}

//--------------------- FILTER MODAL LOGIC
const numberOfFilters = ref(0);
import { useFilterModal } from "@/global/hooks/use-filter-modal.js";
const { showFilters } = useFilterModal();
import { useFilterLogic } from "@global/hooks/use-filter-logic.js";
const filters = ref(
  props.columnConfig.reduce((acc, column) => {
    if (column.filterConfig) {
      acc[column.field] = {
        value: null,
        ...column.filterConfig
      };
    }

    return acc;
  }, {})
);
const { openFilterModal } = useFilterLogic(filters, showFilters, null);

//--------------------- SORT LOGIC
const tableSort = ref([
  {
    id: "startup",
    name: "Startup",
    direction: "asc"
  }
]);
//--------------------- ROW SELECTION LOGIC
const allCheckedState = ref(
  Object.keys(groupedRows.value).reduce((acc, curr) => {
    if (!props.nonMultiselectGroup?.includes(curr)) {
      acc[curr] = false;
    }
    return acc;
  }, {})
);
const selectEntireCategory = (checked, selectedGroup) => {
  const selectedRowsClone = [...selectedRows.value];

  //checked = true => selected all rows and set state of that row to true
  if (checked) {
    selectedRows.value = dataTableFormatted.value.filter(
      (row) =>
        row[groupByColumn.value] === selectedGroup ||
        row[groupByColumn.value]?.name === selectedGroup
    );
    allCheckedState.value[selectedGroup] = true;
  } else if (allCheckedState.value[selectedGroup]) {
    //allCheck = true => remove all the selected rows in the group and set state to false
    selectedRows.value = selectedRowsClone.filter((row) => {
      const value = row[groupByColumn.value]?.name || row[groupByColumn.value];
      return value !== selectedGroup;
    });
    allCheckedState.value[selectedGroup] = false;
  }
};

const handleSingleRowSelect = (modelValue, rowData) => {
  const selectedRowsClone = [...selectedRows.value];
  const selectedGroup =
    rowData[groupByColumn.value]?.name || rowData[groupByColumn.value];

  if (modelValue) {
    //add it to the selectedRows
    selectedRows.value = makeUniqueArray(
      [...selectedRows.value, rowData],
      (a, b) => a.id === b.id
    );

    //Check if all rows are selected
    if (isAllRowSelected(selectedGroup)) {
      allCheckedState.value[selectedGroup] = true;
    }
  } else {
    //If one row is unselected => the group is not selected
    allCheckedState.value[selectedGroup] = false;
    selectedRows.value = selectedRowsClone.filter(
      (row) => row.id !== rowData.id
    );
  }
};

const isAllRowSelected = (selectedGroup) => {
  const allItemsPerGroup = dataTableFormatted.value.filter((ele) => {
    const value = ele[groupByColumn.value]?.name || ele[selectedRows.value];

    return value === selectedGroup;
  }).length;

  const allSelectedItemsPerGroup = selectedRows.value.filter((ele) => {
    const value = ele[groupByColumn.value]?.name || ele[selectedRows.value];
    return value === selectedGroup;
  }).length;
  // if (process.env.NODE_ENV === "development") {
  //   console.log("DEBUG: allItemsPerGroup", allItemsPerGroup);
  //   console.log("DEBUG: allSelectedItemsPerGroup", allSelectedItemsPerGroup);
  // }

  return allItemsPerGroup === allSelectedItemsPerGroup;
};
const isMultiSelectionRow = computed(() => {
  return Object.keys(groupedRows.value).reduce((acc, curr) => {
    if (!props.nonMultiselectGroup?.includes(curr)) {
      const allSelectedItemsPerGroup = selectedRows.value.filter((ele) => {
        const value = ele[groupByColumn.value]?.name || ele[selectedRows.value];
        return value === curr;
      }).length;
      const allItemsPerGroup = dataTableFormatted.value.filter((ele) => {
        const value = ele[groupByColumn.value]?.name || ele[selectedRows.value];

        return value === curr;
      }).length;

      acc[curr] =
        (allSelectedItemsPerGroup === allItemsPerGroup &&
          allItemsPerGroup > 0) ||
        allSelectedItemsPerGroup > 1;
    }
    return acc;
  }, {});
});

const getFieldConfig = (column, groupKey) => {
  return column.fieldCellConfig(isMultiSelectionRow.value[groupKey]);
};
const calculateNonMultiselectGroup = computed(() => {
  return Object.keys(groupedRows.value).filter((groupKey) => {
    return groupedRows.value[groupKey].every((ele) => {
      return props.multiselectRowConfig.visible(ele);
    });
  });
});
//--------------------- MENU/DROPDOWN ITEM SELECTED EVENT
const handleColumnSelection = (selectedOption, column) => {
  selectedOption.optionClicked(column, selectedOption);
};
const handleDropdownSelection = (selectedOption) => {
  if (selectedOption) {
    selectedOption.handleSelection(selectedOption);
  }
};
//--------------------- GROUP BY COLUMN
const groupByCallback = (column) => {
  groupByColumn.value = column.groupByConfig?.displayName;
  initializeGroups();
};
// Initialize groups (open first group, close others)
const initializeGroups = () => {
  const groupKeys = Object.keys(groupedRows.value);
  if (groupKeys.length > 0) {
    // Open the first group and collapse others
    groupKeys.forEach((groupKey, index) => {
      collapsedGroups.value[groupKey] = index !== 0;
    });
  }
};
//--------------------- SORT BY COLUMN
const updateSort = (sort, column) => {
  tableSort.value = [sort];
  const columnIndex = columns.value.findIndex((col) => col.field === column);
  console.log("@@ sort column", columns.value);
  columns.value[columnIndex].sortConfig.currentDirection = {
    order: sort.direction,
    label: getSortLabel(
      columns.value[columnIndex]?.sortConfig?.type,
      sort.direction
    )
  };
};
//sort call back by the header menu only
const sortCallback = (column, direction) => {
  console.log("@@enter sort callback");
  const sort = {
    id: column.field,
    name: column.header,
    direction: direction
  };
  updateSort(sort, column.field);
};
//sort call back by action dropdown menu ONLY when changing the direction through the button
const onSortColumn = (sortDirection, selectedOption) => {
  const sort = {
    id: selectedOption.id,
    name: selectedOption.name,
    direction: sortDirection
  };
  updateSort(sort, selectedOption.id);
};
//sort call back by the action dropdown menu selection is handle in the config file
//--------------------- FILTER BY COLUMN
const filterCallback = (column) => {
  openFilterModal(column.filterConfig);
};
//---------------------  URL BIND SORT FILTER GROUPBY
const urlFilterQuery = computed(() => convertFiltersToUrlQuery(filters.value));
const urlSortQuery = computed(() => convertSortToUrlQuery(tableSort.value));
const urlGroupByQuery = computed(() =>
  convertGroupByToUrlQuery(groupByColumn.value)
);
// Call the helper function to map the URL query to the state
function updateFilterSortFromUrl() {
  const {
    filters: parsedFilters,
    tableSort: parsedTableSort,
    groupBy: parsedGroupBy
  } = mapUrlQueryToState(route.query);
  if (process.env.NODE_ENV === "development") {
    console.log("@@@filters", parsedFilters);
    console.log("@@@tableSort", parsedTableSort);
    console.log("@@@groupBy", parsedGroupBy);
  }
  if (parsedFilters && Object.keys(parsedFilters).length > 0) {
    Object.keys(parsedFilters).forEach((key) => {
      const filteredValues = parsedFilters[key].value;
      //case 1: if the key contains date
      if (key?.includes("date")) {
        filters.value[key].value = filteredValues;
      } else {
        const selectedOption = filters.value[key].options.filter((ele) =>
          filteredValues?.includes(ele.index)
        );

        filters.value[key].value = selectedOption;
      }
    });
  }
  if (parsedTableSort && parsedTableSort.length > 0) {
    tableSort.value = parsedTableSort;
  }
  if (parsedGroupBy) {
    groupByColumn.value = parsedGroupBy;
  }
}
//--------------------- FREEZE COLUMNS
const freezeColumnCallback = (column, option) => {
  if (!column.reorderColumn) {
    return;
  }
  const columnIndex = columns.value.findIndex(
    (col) => col.field === column.field
  );
  const lastFrozenColumnIndex = columns.value
    .filter(
      (col) =>
        col.frozenColumn && col.field !== column.field && col.isColumnVisible
    )
    .map((ele, index) => index)
    .sort((a, b) => b - a)[0];

  draggedColumnIndex = columnIndex;
  targetColumnIndex = lastFrozenColumnIndex + 1;
  dropColumn();
  endDrag();
  nextTick(() => {
    const index = columns.value.findIndex((col) => col.field === column.field);
    const isFrozen = !columns.value[index].frozenColumn;
    columns.value[index] = {
      ...columns.value[index],
      frozenColumn: isFrozen
    };
  });
};

//--------------------- HIDE COLUMNS
const onToggleColumn = (checked, column) => {
  const columnIndex = columns.value.findIndex((col) => col.field === column);
  columns.value[columnIndex].isColumnVisible = checked;
  nextTick(() => initializeResizableColumns());
};
const hideColumnCallback = (column, option) => {
  onToggleColumn(false, column.field);
};
//--------------------- RE-ORDER COLUMNS
const moveColumnCallback = (column, option) => {
  const columnIndex = columns.value.findIndex(
    (col) => col.field === column.field
  );
  draggedColumnIndex = columnIndex;
  targetColumnIndex =
    option.id === "move-column-left" ? columnIndex - 1 : columnIndex + 1;
  dropColumn();
  endDrag();
};
const startDrag = (index) => {
  draggedColumnIndex = index;
};

const dragEnter = (index) => {
  targetColumnIndex = index;
  droppingIndex.value = targetColumnIndex;
  originalColumnIndex.value = draggedColumnIndex;
};

const dropColumn = () => {
  if (
    draggedColumnIndex !== null &&
    targetColumnIndex !== null &&
    draggedColumnIndex !== targetColumnIndex
  ) {
    if (columns.value[targetColumnIndex].frozenColumn) {
      draggedColumnIndex = null;
      targetColumnIndex = null;
      return;
    }
    // Reorder the columnConfig array
    const tempColumn = columns.value[draggedColumnIndex];
    columns.value.splice(draggedColumnIndex, 1);
    columns.value.splice(targetColumnIndex, 0, tempColumn);

    draggedColumnIndex = null;
    targetColumnIndex = null;

    // Reinitialize resizers after reordering columns
    nextTick(() => initializeResizableColumns());
  }
};

const endDrag = () => {
  draggedColumnIndex = null;
  targetColumnIndex = null;
  originalColumnIndex.value = null;
  droppingIndex.value = null;
};

//--------------------- CONFIG FILE (HEADER, ACTIONS)
import { createTableHeaderConfig } from "@global/configs/table-header-config.js";
const tableHeaderConfig = createTableHeaderConfig({
  columns,
  sortCallback: sortCallback,
  filterCallback: filterCallback,
  groupByCallback,
  moveColumnCallback: moveColumnCallback,
  freezeColumnCallback: freezeColumnCallback,
  hideColumnCallback: hideColumnCallback
});
const getTableHeaderConfig = (column) => {
  return tableHeaderConfig.value.reduce((acc, item) => {
    if (item.visible(column)) {
      acc = [...acc, { ...item, name: item.label(column) }];
    }
    return acc;
  }, []);
};

import { createTableActionConfig } from "@global/configs/table-action-config.js";
const actions = computed(() =>
  createTableActionConfig({
    columns,
    numberOfFilters,
    tableSort,
    groupByColumn,
    selectedRowGroupByConfig,
    openFilterModal,
    showSortMenu: showSort,
    showGroupMenu: true,
    props,
    rowHeight,
    customActions: [], // Add customActions parameter,
    sortCallback: updateSort,
    groupByCallback
  })
);

const displayedRows = ref({});
const batchSize = 20;
let observer = {};
const renderedRows = computed(() => {
  return Object.keys(groupedRows.value).reduce((acc, groupKey) => {
    // Initialize accumulator for each groupKey if not present
    if (!acc[groupKey]) acc[groupKey] = [];

    // If displayedRows exist for the groupKey, create a Set for quick lookup
    if (displayedRows.value[groupKey]) {
      const displayedRowIds = new Set(
        displayedRows.value[groupKey].map((item) => item.id)
      );

      // Filter out rows in groupedRows based on displayedRowIds Set
      acc[groupKey] = groupedRows.value[groupKey].filter((ele) =>
        displayedRowIds.has(ele.id)
      );
    } else {
      // If no displayedRows, use the entire groupedRows
      acc[groupKey] = groupedRows.value[groupKey];
    }

    return acc;
  }, {});
});

const renderBatch = (groupKey, startIndex) => {
  if (!groupedRows.value[groupKey] || groupedRows.value[groupKey].length < 20)
    return;

  const endIndex = Math.min(
    startIndex + batchSize,
    groupedRows.value[groupKey].length
  );

  if (!displayedRows.value[groupKey]) {
    displayedRows.value[groupKey] = [];
  }
  displayedRows.value[groupKey].push(
    ...groupedRows.value[groupKey].slice(startIndex, endIndex)
  );

  nextTick(() => {
    const lastRenderedItem = document.querySelector(
      `#id_${groupKey
        .toLowerCase()
        .replace(/\s+/g, "")
        .replace(/[^\w]/g, "")}_${endIndex - 1}`
    );

    if (lastRenderedItem) {
      observer[groupKey].observe(lastRenderedItem);
      if (process.env.NODE_ENV === "development") {
        console.log("@@@@ render batch");
      }
    }
  });

  if (endIndex >= groupedRows.value[groupKey].length) {
    observer[groupKey].disconnect();
  }
};

const initObserver = (groupKey) => {
  observer[groupKey] = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const lastItemIndex = displayedRows.value[groupKey].length;
        observer[groupKey].unobserve(entry.target); // Stop observing the current item
        renderBatch(groupKey, lastItemIndex);
      }
    });
  });
};
//--------------------- HANDLE CASCADE SELECT MENU BEHAVIOUR (on click on another dropdown will close the menu) START
import { useCascadeSelect } from "@global/hooks/use-cascade-select.js";
const { cascadeRefs, handleShow, handleHide, setCascadeRef } =
  useCascadeSelect();

//--------------------- TABLE SETTING
const tableStateKey = "tableSettings"; // Key to store settings in sessionStorage

// Function to save only specific properties (frozenColumn, isColumnVisible, reorderColumn)
const saveTableSettings = () => {
  const tableSettings = columns.value.map((col) => ({
    field: col.field,
    frozenColumn: col.frozenColumn,
    isColumnVisible: col.isColumnVisible,
    reorderColumn: col.reorderColumn
  }));
  console.log("@@@@save setting", tableSettings);
  sessionStorage.setItem(tableStateKey, JSON.stringify(tableSettings));
};

// Function to load settings and merge them with the initial columns
const loadTableSettings = () => {
  const savedSettings = sessionStorage.getItem(tableStateKey);
  if (savedSettings) {
    const settings = JSON.parse(savedSettings);
    console.log("@@@@load settings", settings);
    columns.value = settings.map((col) => {
      const column = columns.value?.find((item) => item.field === col.field);
      return {
        ...column,
        ...(col || {})
      };
    });
  }
};

// Re-initialize resizers after mounting and whenever groupBy changes
onMounted(() => {
  initializeGroups();
  updateFilterSortFromUrl();
  nextTick(() => initializeResizableColumns());
  bus.on("filter-applied", (data) => {
    numberOfFilters.value = data;
  });
  //   document.querySelector(".table-wrapper").focus();
  document.addEventListener("click", handleClickOutside);
  loadTableSettings(); // Load saved settings on mount
});
// Remove event listener before unmount
onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

// Hide context menu on clicking outside
window.addEventListener("click", hideContextMenu);

// Watch for changes in groupedRows or groupByColumn and reapply resizing logic
watch([groupByColumn, groupedRows], () => {
  nextTick(() => initializeResizableColumns());
});
watch(
  collapsedGroups.value,
  (newVal, oldVal) => {
    Object.keys(newVal).map((key) => {
      if (!newVal[key]) {
        initObserver(key);
        renderBatch(key, displayedRows.value[key]?.length || 0);
      }
    });
  },
  { immediate: true }
);

watch(
  [urlFilterQuery, urlSortQuery, urlGroupByQuery],
  () => {
    // Create an array to hold valid query segments
    const querySegments = [];

    // Only add the filter query if it exists and is not empty
    if (urlFilterQuery.value) {
      querySegments.push(urlFilterQuery.value);
    }

    // Only add the sort query if it exists and is not empty
    if (urlSortQuery.value) {
      querySegments.push(urlSortQuery.value);
    }
    // Only add the group-by query if it exists and is not empty
    if (urlGroupByQuery.value) {
      querySegments.push(urlGroupByQuery.value);
    }

    // Join the segments with "&" to form the full query string
    const queryString = querySegments.join("&");

    // Decode the query string to see the human-readable version
    if (queryString) {
      const decodedQueryString = decodeURIComponent(queryString);
      if (process.env.NODE_ENV === "development") {
        console.log("Human-readable Query String:", decodedQueryString);
      }
    }
    // Convert the query string back into an object for Vue Router (if any query is present)
    const queryObject = queryString
      ? Object.fromEntries(new URLSearchParams(queryString))
      : {};

    // Update the URL with the query parameters (only if there is any query)
    if (Object.keys(queryObject).length > 0) {
      router.replace({ query: queryObject });
    } else {
      // Clear query params if no filters or sort
      router.replace({ query: {} });
    }
    initializeGroups();
  },
  { immediate: true }
);
// Watch for changes in columns and automatically save settings if they differ from the initial state
watch(
  columns,
  (newVal) => {
    console.log(
      "@@ setting change",
      hasColumnChanges(newVal, props.columnConfig)
    );
    if (hasColumnChanges(newVal, props.columnConfig)) {
      saveTableSettings(); // Save settings only if there are changes
    }
  },
  { deep: true }
); // Deep watch to track changes within the objects
// Helper function to check if there are changes between the original and current columns
const hasColumnChanges = (current, original) => {
  return current.some((col, index) => {
    const test = original.find((ele) => ele.field === col.field);
    return col.frozenColumn !== test.frozenColumn;
  });
};
</script>

<style scoped>
.table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0;
}
/*
.table,
.table th,
.table td {
  border: 1px solid #ccc;
}
*/
.table th,
.table td {
  padding: 0;
  min-width: 100px;
}

/* .sticky-column {
  z-index: 3;
} */

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.resizer:hover,
.resizing {
  border-right: 2px solid #0d2697;
  z-index: 9999;
}

.table-wrapper {
  overflow-x: auto;
  position: relative;
  height: calc(100% - 51px);
  /* border-collapse: separate; */
}
.selected-cell {
  background-color: #007bff;
}

.highlighted-cell {
  background-color: rgba(0, 123, 255, 0.2);
}
.context-menu {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.context-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.context-menu li {
  padding: 10px;
  cursor: pointer;
}
.context-menu li:hover {
  background-color: #f0f0f0;
}
.table-row:hover .body-cell,
.table-row-selected .body-cell {
  background-color: #e9e9f0;
}

.table-row:hover td {
  z-index: 5 !important;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
