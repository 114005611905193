//MTBers path only
import { auth } from "@/plugins/firebase.js";
export const GLOBAL_SIGNIN = "signin";
export const MTBER_ROUTES = [
  {
    path: "/settings",
    name: "settings",
    component: () => import("./views/mtb-settings.vue"),
    redirect: () => {
      return { name: "settings-corporates" };
    },
    children: [
      {
        path: "corporates",
        name: "settings-corporates",
        component: () => import("./views/mtb-settings-corporate.vue")
      },
      {
        path: "mtb-users",
        name: "settings-mtb-users",
        component: () => import("./views/mtb-settings-users-mtb.vue")
      },
      {
        path: "problematic-users",
        name: "settings-problematic-users",
        component: () => import("./views/mtb-settings-users-problematic.vue")
      },
      {
        path: "institution",
        name: "settings-institutions",
        component: () => import("./views/mtb-settings-institution.vue")
      },
      {
        path: "startup",
        name: "settings-startups",
        component: () => import("./views/mtb-settings-startup.vue")
      },
      {
        path: "users",
        name: "settings-users",
        component: () => import("./views/mtb-settings-users-general.vue")
      }
    ],
    meta: {
      navbar: true,
      auth: true
    }
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("./views/mtb-signup.vue"),
    meta: {
      navbar: true,
      auth: true
    }
  },
  {
    path: "/challenges-applications",
    name: "challenges-applications",
    component: () => import("./views/mtb-applications.vue"),
    meta: {
      navbar: true,
      auth: true
    }
  },
  {
    path: "/account-requests-dashboard",
    name: "account-requests-dashboard",
    component: () => import("./views/mtb-account-requests-dashboard"),
    meta: {
      navbar: true,
      title: "Account Requests",
      auth: true
    }
  },
  {
    path: "/pending-meetings",
    name: "pending-meetings",
    component: () => import("./views/mtb-pending-meetings.vue"),
    meta: {
      navbar: true,
      auth: true
    }
  },
  {
    path: "/matching/:id?",
    name: "matchingRequest",
    component: () => import("./views/mtb-matching"),
    meta: {
      navbar: true,
      title: "Arrange Meetings",
      size: "text-3xl",
      auth: true
    }
  },
  {
    path: "/matching-calendars/:section?",
    name: "matching-calendars",
    component: () => import("./views/mtb-matching-calendar.vue"),
    meta: {
      navbar: true,
      title: "Matching Calendar",
      size: "text-3xl",
      auth: true
    }
  }
].map((route) => {
  return {
    ...route,
    meta: {
      ...route.meta,
      navbar: true,
      auth: true
    },
    async beforeEnter(from, to, next) {
      const isMtber = (await auth.currentUser?.getIdTokenResult())?.claims?.mtb;
      console.log("#####auth", isMtber);
      if (!auth.currentUser) {
        next({ name: GLOBAL_SIGNIN });
      } else if (!isMtber) {
        next(false);
      } else {
        next();
      }
    }
  };
});
