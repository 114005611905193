import { functionsEU, functionsUS } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";

export async function getApplicationsLikes(startupId) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "applyToChallenges-loadApplicationsLikes"
    );
    const result = await httpsCallableFunction({ accountId: startupId });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getDislikedChallenges(startupId, hasLiked) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-getLikeDislikeChallengeV3"
    );
    const result = await httpsCallableFunction({
      startupId: startupId,
      hasLiked: hasLiked
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function setLikeDislikeChallenges(data) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      hasLiked: {
        presence: { allowEmpty: false },
        type: "boolean"
      },
      isSuggestedFromRialto: {
        presence: { allowEmpty: false },
        type: "boolean"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-setDislikedChallengesV3"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteLikeDislikeChallenges(data) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-deleteDislikedChallengesV3"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
