<template>
  <div class="flex items-center">
    <Dropdown
      :modelValue="selectedOption"
      :options="dropdownOptions"
      optionLabel="name"
      :placeholder="props?.title"
      :pt="dropdownPt"
      :disabled="isDisable"
      :filter="props?.filter"
      @update:modelValue="updateSelectedValue"
    >
      <template #header>
        <div
          v-if="header"
          class="flex flex-col justify-start bg-white items-start rounded-t-2xl gap-y-1"
        >
          <span class="font-bold text-black text-left">{{
            header?.title
          }}</span>
          <span class="text-sm text-grey1">{{ header?.subTitle }}</span>
        </div>
      </template>
      <template #dropdownicon>
        <base-vite-icon
          class="ml-3"
          v-if="!isDisable"
          :name="props.icon"
          :classes="iconStyle"
        />
        <span else></span>
      </template>
      <template #value="slotProps">
        <div
          v-if="slotProps.value && !props.isIconOnly"
          class="flex align-items-center"
        >
          <div>{{ slotProps.value.name }}</div>
        </div>
        <div v-else class="flex items-center justify-center">
          <div>
            <base-vite-icon
              class="mr-2"
              v-if="titleIcon"
              :name="props.titleIcon"
              :classes="props.titleIconStyle"
            />
          </div>
          <span>
            {{ slotProps.placeholder }}
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div
          class="flex flex-row items-center gap-2 w-full"
          :class="[props.isCustomDropdown ? 'justify-between' : '']"
        >
          <base-vite-icon
            v-if="option.icon"
            :name="option.icon"
            :classes="option.iconClass"
          />
          <p
            :class="[
              option.titleClass,
              selectedOption?.id === option?.id && 'font-bold'
            ]"
            v-html="option.name"
          ></p>

          <InputSwitch
            v-if="option.showInput"
            class="scale-75"
            v-model="inputSwitchValue[option.id]"
            @click.stop="handleInputSwitch(option)"
          />

          <base-button
            v-if="option.sort && selectedOption?.id === option?.id"
            :label="option.sortDirection.label"
            :text="true"
            @click="handSorting(option)"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script setup>
import Dropdown from "primevue/dropdown";
import { ref, watch, toRefs, onMounted, computed, onUnmounted } from "vue";
import { bus } from "@/main.js";
import { default as support } from "@share/commonSupport.js";
import { sortArray } from "@global/helpers/util.js";

const props = defineProps({
  options: {
    type: Array,
    default: null,
    validator: (value) => {
      return (
        value.every((element) =>
          ["name", "id"].every((key) => key in element && element[key])
        ) || !value
      );
    }
  },
  title: {
    default: ""
  },
  titleStyle: {
    type: String,
    default: "text-base text-black"
  },
  iconStyle: {
    type: String,
    default: "fill-none stroke-grey2 w-5 h-5"
  },
  defaultSelectedOption: {
    type: Object,
    default: () => {}
  },
  disable: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: "chevron-down"
  },
  titleIcon: {
    type: String,
    default: ""
  },
  titleIconStyle: {
    type: String,
    default: "fill-none stroke-grey2 w-5 h-5"
  },
  isIconOnly: {
    type: Boolean,
    default: false
  },
  filter: {
    type: Boolean,
    default: false
  },
  header: {
    type: Object,
    default: null
  },
  isCustomDropdown: {
    type: Boolean,
    default: false
  },
  //sort to move the selected value to the top of the dropdown list
  sortSelectedValue: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue", "on-toggle", "on-sort-change"]);
const selectedOption = ref(props.defaultSelectedOption);
const sortSelected = ref(null);

const dropdownOptions = computed(() => {
  return props.sortSelectedValue
    ? sortArray(
        props.options.map((ele) => ({
          ...ele,
          selected: selectedOption.value.id === ele.id
        })),
        { key: "selected", descending: true }
      )
    : props.options;
});
const inputSwitchValue = ref(
  dropdownOptions.value?.reduce((acc, curr) => {
    acc[curr.id] = curr.inputSwitch;
    return acc;
  }, {})
);

const isDisable = computed(
  () => props.disable || support.isDataFalsyOrEmpty(props.options)
);
//when toggle the visibility of the field
const handleInputSwitch = (option) => {
  inputSwitchValue.value[option.id] = !inputSwitchValue.value[option.id];
  emit("on-toggle", inputSwitchValue.value[option.id], option.id);
};
//when change the sort direction
const handSorting = (option) => {
  console.log("===>option", option);
  const tempSort = option?.sortDirection?.order === "asc" ? "desc" : "asc";
  emit("on-sort-change", tempSort, option);
};

const updateSelectedValue = (newValue) => {
  selectedOption.value = newValue;
  emit("update:modelValue", newValue);
};

watch(
  () => dropdownOptions.value,
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal))
      console.log("change value of input switch!!! ");
    inputSwitchValue.value = newVal?.reduce((acc, curr) => {
      acc[curr.id] = curr.inputSwitch;
      return acc;
    }, {});
  }
);

watch(
  () => props.defaultSelectedOption,
  (newValue) => {
    selectedOption.value = newValue;
  }
);

onMounted(() => {
  bus.on("reset-option", (value) => {
    selectedOption.value = value;
  });
});
onUnmounted(() => {
  bus.off("reset-option");
});

const dropdownPt = {
  root: {
    class: [
      "border-none w-full inline-flex relative select-none items-center",
      isDisable.value ? "cursor-normal" : "cursor-pointer"
    ]
  },
  //Dropdown title
  input: {
    class: [
      "focus:outline-none focus:shadow-none", //important
      "text-ellipsis whitespace-nowrap capitalize",
      props.titleStyle
    ]
  },
  //icon arrow down
  trigger: { class: [props.options ? "w-8" : "hidden"] },
  //Dropdown menu including filter
  panel: {
    class: [
      "shadow-mtb-card rounded-2xl bg-white absolute top-[150px]",
      "px-4 py-3"
    ]
  },
  wrapper: {
    class: [
      "inline-flex flex-col justify-start items-start items-start gap-5 overflow-auto",
      "bg-white w-full",
      props.header && "pt-4"
    ]
  },
  header: {
    class: [
      props.isCustomDropdown &&
        "py-3 text-grey2 mt-0 rounded-tl-2xl rounded-tr-2xl"
    ]
  },
  item: ({ context }) => {
    return {
      class: [
        "cursor-pointer overflow-hidden relative whitespace-nowrap",
        { "font-normal text-grey2": !context.selected },
        { "font-bold text-black": context.selected }
      ]
    };
  },
  list: {
    class: ["list-none m-0 flex flex-col gap-3 w-full", "gap-4 w-full"]
  },
  transition: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};
</script>

<style lang="scss" scoped></style>
