<template>
  <div class="flex flex-column items-start gap-2">
    <div
      v-if="props.label"
      class="flex flex-row justify-between items-center w-full"
    >
      <label
        :for="`${name}_${contextUuid}`"
        :class="[isInvalid ? 'text-alert' : 'text-black']"
        class="text-sm font-medium capitalize"
        >{{ props?.label }}</label
      >
      <slot />
    </div>

    <InputText
      v-if="props.type === 'input-text'"
      :class="[isInvalid ? 'border-alert' : 'border-grey3']"
      :id="`${name}_${contextUuid}`"
      :modelValue="inputValue"
      :pt="inputtextPt"
      :placeholder="placeholder"
      :disabled="props.disabled"
      @blur="setTouched(true)"
      @update:modelValue="onChange"
      :data-cy="'input-text-' + props.name.toLocaleLowerCase()"
    />
    <Password
      v-else-if="props.type === 'input-password'"
      :class="[isInvalid ? 'border-alert' : 'border-grey3']"
      :id="`${name}_${contextUuid}`"
      v-model="inputValue"
      :pt="password"
      toggleMask
      :disabled="props.disabled"
      :data-cy="'input-text-' + props.name.toLocaleLowerCase()"
    />

    <small
      v-if="isInvalid"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
      :data-cy="'input-text-error-' + props.name.toLocaleLowerCase()"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import { toRefs, computed } from "vue";
import { useField } from "vee-validate";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  value: {
    type: String,
    default: ""
  },
  formCount: {
    type: Number,
    default: 0
  },
  type: {
    type: String,
    default: "input-text"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ""
  }
});

const { name, value, isDraftValidated } = toRefs(props);
const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched,
  handleChange
} = useField(name, undefined, {
  initialValue: value,
  validateOnMount: true
});
//ONLY SHOW THE ERROR MESSAGE IF
//1. field is dirty and has a error message
//2. A Save draft
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});
const onChange = (value) => {
  handleChange(value);
  emit("update:modelValue", value);
};

// watchEffect(() => {
//   emit("update:modelValue", inputValue);
// });

const inputtextPt = _inputtext;
const password = _password;
</script>
<script>
const _inputtext = {
  root: ({ props, context }) => ({
    class: [
      "m-0 w-full",
      "text-black border-1 rounded-lg transition-colors duration-200 appearance-none rounded-lg",
      {
        "focus:outline-none focus:border-primary": !context.disabled,
        "opacity-60 select-none pointer-events-none cursor-default":
          context.disabled
      },
      {
        "text-lg px-4 py-4": props.size == "large",
        "text-xs px-2 py-2": props.size == "small",
        "p-3 text-base": props.size == null
      }
    ]
  })
};
const TRANSITIONS = {
  overlay: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};
const _password = {
  root: ({ props }) => ({
    class: [
      "inline-flex relative w-full",
      {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled
      }
    ]
  }),
  input: () => {
    return {
      root: "m-0 w-full text-black border-1 rounded-lg transition-colors duration-200 appearance-none rounded-lg focus:outline-none focus:border-primary p-3 text-base border-grey3"
    };
  },

  panel: "hidden",
  showicon: {
    class: ["absolute top-1/2 -mt-2", "right-3 text-grey3"]
  },
  hideicon: {
    class: ["absolute top-1/2 -mt-2", "right-3 text-grey3"]
  },
  transition: TRANSITIONS.overlay
};
</script>

<style lang="scss" scoped></style>
