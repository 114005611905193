<template>
  <div class="relative py-6 h-full">
    <!--=========== LEFT PART =============-->

    <div
      class="flex items-start px-6 gap-14 self-stretch flex-1 h-full overflow-y-scroll"
      v-if="accountDetails"
    >
      <div
        class="info-wrapper flex flex-col items-start pt-2 gap-8 flex-1 self-stretch"
      >
        <!-- INST NAME + LOGO -->
        <!-- <div class="flex items-center gap-4 w-full">
          <Skeleton v-if="isLoadingAccount" class="w-8 h-8 mb-0"></Skeleton>
          <base-logo
            v-else
            :companyId="accountDetails.Id"
            classes="w-8 h-8 flex-none"
          />
          <Skeleton v-if="isLoadingAccount" class="w-40 h-8 mb-0"></Skeleton>
          <div
            v-else
            class="w-[calc(100%-48px)] flex justify-between gap-x-4 items-center"
          >
            <span
              class="text-2xl sm:text-3xl font-bold overflow-hidden text-ellipsis text-start"
              >{{ accountDetails?.Name }}
            </span>

            <div class="divide-x flex items-center justify-center">
              <div class="pe-4" v-if="isMyProfile && !isLoadingAccount">
                <base-button
                  size="small"
                  label="Edit Profile"
                  iconClass="h-5 w-5"
                  icon="edit"
                  severity="primary"
                  @click="editProfile"
                />
              </div>

              <div class="ps-4">
                <base-button
                  :text="true"
                  icon="share_fill"
                  iconClass="w-6 h-6 fill-primary"
                  @click="openShareModal"
                />
              </div>
            </div>
          </div>
        </div> -->
        <base-profile-action-buttons
          :isLoading="isLoadingAccount"
          :isMyProfile="isMyProfile"
          :auth="props.auth"
          :companyId="accountDetails.Id"
          :company-name="accountDetails.Name"
          companyType="partner"
          @editProfile="editProfile"
          @share="openShareModal"
        />
        <mtb-account-details
          :isLoading="isLoadingAccount"
          :accountDetails="accountDetails"
          :accountMainContact="accountMainContact"
          accountType="institution"
          :sidebar="false"
        />
        <base-institution-profile-sponsorships
          :baseRouteName="props.baseRouteName"
          :institutionId="props.institutionId"
          :isMyProfile="props.isMyProfile"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { useRouter, useRoute } from "vue-router";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import {
  ref,
  watch,
  defineAsyncComponent,
  computed,
  onMounted,
  onUnmounted,
  onBeforeMount
} from "vue";
import { bus } from "@/main.js";
import { useAccountDetail } from "@/global/hooks/use-account-details.js";
import { useDialog } from "primevue/usedialog";
import { getFullEmployeesList } from "@/global/services/accounts-service.js";
const institutionModalEditProfile = defineAsyncComponent(
  () =>
    import(
      "@/modules/institutions/components/institution-modal-edit-profile.vue"
    )
);

const router = useRouter();
const route = useRoute();
const userAuthStore = useUserAuthStore();
const accountsStore = useAccountsStore();
const dialog = useDialog();
const allContacts = ref([]);
const accountDetails = ref([]);
const accountContacts = ref([]);
const accountMainContact = ref({});
const isLoadingAccount = ref(true);

const props = defineProps({
  auth: {
    type: Boolean,
    default: false
  },
  isMyProfile: {
    type: Boolean,
    default: false
  },
  institutionId: {
    type: String,
    required: true
  },
  baseRouteName: {
    type: String,
    required: true
  }
});
const { getAccountDetail } = useAccountDetail();
watch(
  () => props.institutionId,
  async (val, oldVal) => {
    if (val && val !== oldVal) {
      await loadAccountDetails();
      mixpanelTrack();
    }
  },
  { immediate: true }
);

onMounted(() => {
  bus.on("reload-account-details", () => loadAccountDetails(true));
  const currentInstitution = accountsStore.state.institutionsList.find(
    ({ id }) => props.institutionId == id
  );
  if (!currentInstitution) {
    accountsStore.actions.setInstitutionsList();
  }
});
onUnmounted(() => {
  bus.off("reload-account-details");
});

async function loadAccountDetails(force) {
  isLoadingAccount.value = true;
  const [account, contacts] = await Promise.all([
    getAccountDetail(props.institutionId, !!force),
    props.isMyProfile
      ? getFullEmployeesList(props.institutionId)
      : Promise.resolve([])
  ]);

  allContacts.value = contacts;
  accountDetails.value = account.accountDetails.value;

  if (props.isMyProfile) {
    accountContacts.value = account.accountContacts.value;
    accountMainContact.value = account.accountMainContact.value;
  } else {
    accountContacts.value = [];
    accountMainContact.value = {};
  }
  isLoadingAccount.value = false;
}
function mixpanelTrack() {
  const regex = /\?a=[0-9A-Za-z]{15,18}/;
  //track the event if it's not my profile and the prev route wasn't the sidebar
  if (!props.isMyProfile && !regex.test(router.options.history.state["back"])) {
    const forMixpanel = {
      visitor_id: userAuthStore.state?.accountInfo?.id,
      visitor_name: userAuthStore.state?.accountInfo?.name,
      visitor_type: userAuthStore.state?.accountInfo?.type,
      visited_id: accountDetails.value?.Id,
      visited_name: accountDetails.value?.Name,
      suggested: false
    };
    mixpanelMtbTracking({
      eventName: "Institution Profile View",
      properties: forMixpanel
    });
  }
}
function editProfile() {
  if (!props.auth || !props.isMyProfile) {
    return;
  }
  dialog.open(institutionModalEditProfile, {
    props: {
      class: "w-[90vw] md:w-[670px] ",
      header: "Edit Profile",
      modal: true,
      draggable: false
    },
    data: {
      details: {
        ...accountDetails.value
      },
      contacts: [...allContacts.value]
    }
  });
}
import { userShareModal } from "@global/hooks/use-share-modal.js";
import { getUrl } from "@global/helpers/url.js";
const { showShareModal } = userShareModal();

const openShareModal = () => {
  showShareModal({
    ...accountDetails.value,
    modalTitle: accountDetails.value?.Name,
    Name: accountDetails.value?.Name,
    pageName: "Ecosystem Partner Profile",
    sharingUrl: getUrl(
      `partner-profile?sharableId=${accountDetails.value.Id}&_share=true`
    ),
    embedUrl: getUrl(
      `partner-profile/${accountDetails.value.Id}?_share=true&_embed=true`
    )
  });
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  width: 100%;
  @media (min-width: 1280px) {
    max-width: 48%;
  }
}
</style>
