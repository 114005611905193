<template>
  <!-- Admin dropdown -->
  <div v-if="!!userInfo" class="account bg-transparent">
    <button
      type="button"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
    >
      <!-- MIGRATION TO-DO: change avatar icon in the future, this is a placeholder -->
      <base-avatar
        :name="userName"
        :class="{
          'click-me':
            !userAuthStore.getters.isDemoAccount &&
            userAuthStore.getters.isMtbUser
        }"
      />
    </button>
    <Menu :model="items" ref="menu" id="overlay_menu" :popup="true">
      <template #item="{ item, props }">
        <router-link
          v-if="item.condition && item.route"
          v-slot="{ href, navigate }"
          :to="item.route"
          custom
        >
          <a
            v-tooltip="item.tooltip"
            :href="href"
            v-bind="props.action"
            @click="navigate"
            class="flex gap-x-2 items-center"
          >
            <div
              v-if="item.icon"
              :class="[
                ` flex items-center justify-center w-5 h-5 rounded-full`,
                { 'bg-mtb': item.mtb }
              ]"
            >
              <base-vite-icon
                v-if="item.icon"
                :name="item.icon"
                :classes="item.iconClass"
              />
            </div>
            <span class="text-sm">{{ item.label }}</span>
          </a>
        </router-link>
        <a
          v-tooltip="item.tooltip"
          v-else-if="item.condition && item.url"
          :href="item.url"
          :target="item.target"
          v-bind="props.action"
          class="flex gap-x-2 items-center"
        >
          <div
            v-if="item.icon"
            :class="[
              ` flex items-center justify-center w-5 h-5 rounded-full`,
              { 'bg-mtb': item.mtb }
            ]"
          >
            <base-vite-icon
              v-if="item.icon"
              :name="item.icon"
              :classes="item.iconClass"
            />
          </div>

          <span class="text-sm">{{ item.label }}</span>
        </a>
      </template>
      <template #end>
        <div
          v-if="
            userAuthStore.getters.isDemoAccount &&
            userAuthStore.getters.isMtbUser
          "
          class="text-gray-700 dark:text-white/80 py-2 px-3 select-none cursor-pointer flex gap-x-2 items-center no-underline overflow-hidden relative"
        >
          <span class="text-sm">Demo Mode</span>
          <InputSwitch
            :modelValue="isDemoModeActive"
            @update:modelValue="toggleDemoMode()"
            @click="toggleDemoMode()"
          />
        </div>
        <div
          class="text-gray-700 dark:text-white/80 py-2 px-3 select-none cursor-pointer flex items-center no-underline overflow-hidden relative"
        >
          <router-link
            :to="{ name: GLOBAL_SIGNIN }"
            class="text-sm"
            @click="logout"
          >
            Logout
          </router-link>
        </div>
      </template>
    </Menu>
  </div>
</template>
<script setup>
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { auth } from "@plugins/firebase.js";
import { computed, ref } from "vue";
import Menu from "primevue/menu";
import InputSwitch from "primevue/inputswitch";
import { GLOBAL_SIGNIN } from "@/global/router.js";
import localforage from "localforage";
const userAuthStore = useUserAuthStore();

const menu = ref();

const userInfo = computed(() => {
  return userAuthStore.getters.getFullProfileInformation;
});
const userName = computed(() => {
  return `${userInfo.value.FirstName} ${userInfo.value.LastName}`;
});
const isDemoModeActive = computed(() => {
  return userAuthStore.state.isDemoModeActive;
});

const items = computed(() => [
  {
    label: "My Preferences",
    route: "/preferences",
    condition:
      !userInfo.value?.Claims?.mtb && userInfo.value?.type !== "institution"
  },

  {
    label: "Onboard to Ecosystem",
    route: "/signup",
    icon: "add",
    iconClass: "w-3 h-3 stroke-white stroke-[3px]",
    mtb: true,
    tooltip:
      "Give access to the platform to startups, corporates and institutions. Create new account or ad a new user.",
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    label: "Account Creation Requests",
    route: "/account-requests-dashboard",
    icon: "profile",
    iconClass: "w-3 h-3 stroke-white stroke-[3px]",
    tooltip: "View requests to join the platform and manage approvals.",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    separator:
      (!userInfo.value?.Claims?.mtb &&
        userInfo.value?.type !== "institution") ||
      (userInfo.value?.Claims?.mtb && !isDemoModeActive.value)
  },
  {
    label: "Rialto Chat",
    url: "https://chat.mtbecosystem.com",
    icon: "ai-sparkle",
    iconClass: "w-3 h-3 fill-white",
    tooltip:
      "Get inspired, Search for startups or create challenges having a chat with RialtoAI.",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    label: "Batch Account Import",
    url: "https://mindthebridge.retool.com/apps/030d11ea-77f0-11ee-9c4c-97310c0fca43/Rialto/RIALTO%20-%20BATCH%20IMPORT",
    icon: "ai-sparkle",
    iconClass: "w-3 h-3 fill-white",
    tooltip:
      "Use Rialto to efficiently create new Salesforce Startup accounts by uploading either a CSV file or simply providing Name and Website.",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    separator: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    label: "Challenge Applications",
    route: "/challenges-applications?sort=%2Bstartup&group=status",
    icon: "kanban",
    iconClass: "w-3 h-3 ",
    mtb: true,
    tooltip:
      "Evaluate and review startup applications submitted for challenges.",
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    label: "List of Challenges",
    route: "/open-challenges",
    icon: "goal",
    iconClass: "w-3 h-3 ",
    tooltip:
      "Explore all currently available open challenges within the ecosystem.",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    separator: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },

  {
    label: "Arrange a Meeting",
    route: "/matching",
    icon: "team",
    tooltip: "Easily set up a new meeting (no account related).",
    iconClass: "w-3 h-3 fill-white stroke-white",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },

  {
    label: "Matching Calendars",
    route: "/matching-calendars/active",
    icon: "calendar_empty",
    tooltip: "Access, create, and edit the matching account of a corporate.",
    iconClass: "w-3 h-3 stroke-white stroke-[3px]",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    separator: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },
  {
    label: "Settings",
    route: "/settings",
    icon: "settings",
    iconClass: "w-3 h-3 stroke-white stroke-[3px]",
    mtb: true,
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  },

  {
    label: "Support/Report Bug",
    url: "https://form.asana.com/?k=8rfmKVgrSozOauL-TDBs9w&d=1200100519042997",
    icon: "comment",
    iconClass: "w-3 h-3 stroke-white stroke-[3px]",
    mtb: true,
    tooltip:
      "Assist the development team by reporting any issues you encounter or providing suggestions to enhance MTB Ecosystem.",
    condition: userInfo.value?.Claims?.mtb && !isDemoModeActive.value,
    target: "_blank"
  },
  {
    separator: userInfo.value?.Claims?.mtb && !isDemoModeActive.value
  }
]);

function toggleDemoMode() {
  userAuthStore.actions.setDemoMode(!isDemoModeActive.value);
}

const toggle = (event) => {
  menu.value.toggle(event);
};

function logout() {
  auth.signOut();
  localforage.clear();
}
</script>
<style scoped>
.click-me {
  border: 0rem;
  animation: pulse 2.5s 3;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px rgba(28, 57, 185, 0.21);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(28, 57, 185, 0.21);
  }
  100% {
    box-shadow: 0 0 0 1px rgba(28, 57, 185, 0.21);
  }
}
</style>
