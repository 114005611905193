<template>
  <div class="flex flex-row justify-end pb-6" v-if="!preview">
    <base-button
      class="ms-auto mr-4"
      severity="primary"
      :text="true"
      :label="numberOfFilters === 0 ? 'Filter' : `Filter (${numberOfFilters})`"
      icon="filter"
      :iconClass="`w-4 h-4 stroke-2 ${
        numberOfFilters === 0 ? 'stroke-primary' : 'fill-primary'
      }`"
      :stopClick="false"
      @click="showFilterss"
    />
  </div>

  <div v-if="loadingRef" class="mt-4 flex flex-col gap-y-2">
    <div
      v-for="index in preview ? 2 : 4"
      :key="index"
      class="flex w-full h-[85px]"
    >
      <Skeleton class="w-full h-full"></Skeleton>
    </div>
  </div>
  <div
    class="mt-4 flex flex-col gap-y-2"
    v-else-if="appStateStore.state.isError?.ecosystemPublishedChallengesList"
  >
    <fallback-error @reload="reload" />
  </div>
  <div
    v-else-if="applicationsRef.length === 0"
    class="mt-4 flex flex-col gap-y-2"
  >
    <fallback-empty-state
      message="
        Your startups have not submitted a challenge application yet
      "
    />
  </div>
  <div
    v-else
    class="w-full flex flex-col justify-end items-center"
    :class="preview ? 'h-full' : 'h-[calc(100%-43px)]'"
  >
    <DataTable
      ref="table"
      :sortField="sort?.sortField"
      :sortOrder="sort?.sortDirection"
      :value="applicationsRef"
      scrollHeight="flex"
      :paginator="!props.preview && !isChromium"
      :paginatorTemplate="
        !props.preview && !isChromium
          ? 'PrevPageLink CurrentPageReport NextPageLink'
          : null
      "
      :currentPageReportTemplate="
        !props.preview && !isChromium ? '{currentPage} of {totalPages}' : null
      "
      :virtualScrollerOptions="
        preview || !isChromium
          ? null
          : {
              itemSize: 85
            }
      "
      :rows="!props.preview && !isChromium ? numberOfCards : null"
      scrollable
      dataKey="id"
      v-model:filters="filters"
      filterDisplay="menu"
      @row-click="openSidebar"
      :pt="{
        ...{
          root: {
            class:
              'relative w-full flex flex-col justify-between h-full whitespace-nowrap font-bold text-ellipsis gap-2'
          }
        },
        ...getTableStyle()
      }"
    >
      <!-- Startup Name -->
      <Column
        field="startupName"
        filterField="startupName.name"
        style="width: 20%"
        frozen
        alignFrozen="left"
        :preview="preview"
      >
        <template #header v-if="!preview">
          <base-table-filter-multiselect
            :filterModel="filters.startupName.value"
            :sortingModel="sort"
            field="startupName.name"
            staticLabel="Startup Name"
            :options="filters.startupName.options"
            @updateFilter="
              (value) => {
                filters.startupName.value = value;
              }
            "
            @updateSort="
              (value) => {
                sort = value ? value : preSort;
              }
            "
          />
        </template>
        <template #body="{ data }">
          <div
            class="flex flex-row items-center justify-start gap-4"
            v-tooltip.top="preview ? 'Startup Name' : null"
          >
            <base-logo :companyId="data.accountId" classes="w-8 h-8" />
            <div class="flex items-center gap-2">
              <p class="font-bold text-base text-black capitalize">
                {{ data?.startupName?.name || "--" }}
              </p>
              <base-sponsorship-badge :id="data.accountId" />
            </div>
          </div>
        </template>
      </Column>
      <!-- Long arrow icon -->
      <Column style="width: 15%" :preview="preview">
        <template #header v-if="!preview"></template>
        <template #body>
          <base-vite-icon name="arrow-long" classes="stroke-success" />
        </template>
      </Column>
      <!-- Challenge visibility -->
      <Column style="width: 5%" :preview="preview">
        <template #header v-if="!preview"></template>
        <template #body="{ data }">
          <base-vite-icon
            v-if="
              !isCorporateChallengeVisible(
                {
                  Challenge_Visibility__c:
                    data.challengeDetails?.Challenge_Visibility__c,
                  Challenge_Corporate_Sponsor__c:
                    data.challengeDetails?.Challenge_Corporate_Sponsor__c
                },
                { StageName: data.stageName }
              )
            "
            :name="
              visibilityMapping[data.challengeDetails?.Challenge_Visibility__c]
                ?.icon
            "
            :classes="`${
              visibilityMapping[data.challengeDetails?.Challenge_Visibility__c]
                ?.iconClasses
            } h-5 w-5`"
          />
          <base-logo
            v-else
            classes="w-8 h-8"
            :companyId="data.challengeDetails?.Challenge_Corporate_Sponsor__c"
          />
        </template>
      </Column>
      <!-- Challenge Name -->
      <Column
        filterField="challengeName.name"
        :preview="preview"
        style="width: 35%"
      >
        <template #header v-if="!preview">
          <base-table-filter-multiselect
            :filterModel="filters['challengeName'].value"
            :sortingModel="sort"
            field="challengeName.name"
            staticLabel="Challenge Name"
            :options="filters['challengeName'].options"
            @updateFilter="
              (value) => {
                filters['challengeName'].value = value;
              }
            "
            @updateSort="
              (value) => {
                sort = value ? value : preSort;
              }
            "
          />
        </template>
        <template #body="{ data }">
          <div
            class="flex flex-col justify-center items-start"
            v-tooltip.top="preview ? 'Challenge Name' : null"
          >
            <div class="flex gap-2 items-center">
              <p
                class="capitalize font-bold text-base text-black truncate w-full max-w-[250px]"
              >
                {{ data.challengeDetails ? data.challengeDetails.Name : "--" }}
              </p>
              <base-sponsorship-badge :id="data.challengeDetails?.Id" />
            </div>
            <p class="capitalize font-normal text-sm text-grey1">
              {{
                data.challengeDetails &&
                isCorporateChallengeVisible(
                  {
                    Challenge_Visibility__c:
                      data.challengeDetails?.Challenge_Visibility__c,
                    Challenge_Corporate_Sponsor__c:
                      data.challengeDetails?.Challenge_Corporate_Sponsor__c
                  },
                  { StageName: data.stageName }
                )
                  ? data.challengeDetails?.Challenge_Corporate_Sponsor__r?.Name
                  : ""
              }}
            </p>
          </div>
        </template>
      </Column>
      <!-- Challenge Objective -->
      <Column
        filterField="challengeObjective.name"
        :preview="preview"
        style="width: 20%"
      >
        <template #header v-if="!preview">
          <base-table-filter-multiselect
            :filterModel="filters['challengeObjective'].value"
            field="challengeObjective.name"
            staticLabel="Challenge Objective"
            :options="filters['challengeObjective'].options"
            :sortable="false"
            @updateFilter="
              (value) => {
                filters['challengeObjective'].value = value;
              }
            "
          />
        </template>
        <template #body="{ data }">
          <div
            class="mtb-chip"
            v-tooltip.top="preview ? 'Challenge Objective' : null"
            :class="
              objectiveMapping[data.challengeDetails?.Challenge_Objective__c]
                ?.parentClasses || objectiveMapping.default.parentClasses
            "
          >
            <base-vite-icon
              :name="
                objectiveMapping[data.challengeDetails?.Challenge_Objective__c]
                  ?.icon || objectiveMapping.default.icon
              "
              :classes="
                objectiveMapping[data.challengeDetails?.Challenge_Objective__c]
                  ?.iconClasses || objectiveMapping.default.iconClasses
              "
            />
            <span class="truncate w-full">{{
              objectiveMapping[data.challengeDetails?.Challenge_Objective__c]
                ?.label || data.challengeDetails.Challenge_Objective__c
            }}</span>
          </div>
        </template>
      </Column>
      <!-- arrow  -->
      <Column style="width: 2rem" frozen alignFrozen="right" :preview="preview">
        <template #header v-if="!preview"></template>
        <template #body>
          <span class="cursor-pointer flex justify-end">
            <base-vite-icon
              class="w-1/5"
              name="arrow-short-right"
              classes="fill-none stroke-2 stroke-grey2 w-5 h-5"
            />
          </span>
        </template>
      </Column>
      <template #empty>
        No application match your filter criteria. Please adjust your filters
        and try again.
      </template>
    </DataTable>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useScreenHeight } from "@global/hooks/use-screen-height.js";
import { FilterMatchMode } from "primevue/api";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";

import {
  visibilityMapping,
  objectiveMapping
} from "@global/configs/challenge-mapping.js";
import { bus } from "@/main.js";
import { useAppStateStore } from "../stores/app-state-store.js";
import { useFetch } from "../hooks/use-fetch.js";
import { useLoading } from "../hooks/use-loading.js";
import { makeUniqueArray } from "@global/helpers/array.js";
import { isCorporateChallengeVisible } from "@global/helpers/util.js";

const {
  isGlobalUserInfoLoading,
  isStartupOpportunitiesLoading,
  isAllChallengesListLoading,
  isStartupListLoading,
  isCorporateListLoading
} = useLoading();

import { useTablePassthrough } from "@global/hooks/use-table-passthrough.js";
const { getTableStyle } = useTablePassthrough();

const props = defineProps({
  applications: {
    type: Array,
    default: () => []
  },
  preview: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});
const storesActions = useFetch();
const accountStore = useAccountsStore();
const challengeStore = useChallengesStore();
const appStateStore = useAppStateStore();
const isChromium = !!window.chrome;
const applicationsRef = computed(() => {
  return (
    props.applications?.reduce((acc, curr) => {
      acc = [
        ...acc,
        {
          ...curr,
          startupName: {
            name: curr.startupName,
            index: curr.accountId
          },
          challengeDetails: {
            Challenge_Objective__c: curr.challengeObjective,
            Challenge_Visibility__c: curr.challengeVisibility,
            Name: curr.challengeName,
            Id: curr.challengeId,
            Challenge_Corporate_Sponsor__c: curr.corporateId,
            Challenge_Corporate_Sponsor__r: {
              Name: curr.corporateName,
              Id: curr.corporateId
            }
          },
          challengeName: {
            index: curr.challengeId,
            name: curr.challengeName
          },
          challengeObjective: {
            index: curr.challengeObjective,
            name:
              objectiveMapping[curr.challengeObjective]?.label ||
              curr.challengeObjective
          }
        }
      ];

      return acc;
    }, []) || []
  );
});

const filters = ref({
  startupName: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() =>
      makeUniqueArray(
        applicationsRef.value.map((ele) => ele.startupName),
        (a, b) => a.index === b.index
      ).sort((a, b) =>
        a.name === b.name ? a.index - b.index : a.name.localeCompare(b.name)
      )
    ),
    name: "startupName",
    modalLabel: "Startup",
    filterType: "multiselect",
    filterUrlKey: "startupName",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return (
          //Case: option are not the same between tabs
          applicationsRef.value.find(({ accountId }) => accountId === item)
            ?.startupName
        );
      });
    }
  },
  challengeName: {
    value: null,
    matchMode: FilterMatchMode.IN,

    options: computed(() => {
      return makeUniqueArray(
        applicationsRef.value.map((ele) => {
          return ele.challengeName;
        }),
        (a, b) => a.index === b.index
      );
    }),
    name: "challengeName",
    modalLabel: "Challenge Name",
    filterType: "multiselect",
    filterUrlKey: "challengeName",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return applicationsRef.value.find(
          ({ challengeDetails }) => challengeDetails.Name === item
        )?.challengeName;
      });
    }
  },
  challengeObjective: {
    value: null,
    matchMode: FilterMatchMode.IN,
    options: computed(() => {
      return makeUniqueArray(
        applicationsRef.value.map((ele) => {
          return ele.challengeObjective;
        }),
        (a, b) => a.index === b.index
      );
    }),
    name: "challengeObjective",
    modalLabel: "Challenge Objective",
    filterType: "multiselect",
    filterUrlKey: "challengeObjective",
    parseFilterUrl: (data) => {
      return data.map((item) => {
        return applicationsRef.value.find(
          ({ challengeDetails }) =>
            challengeDetails.Challenge_Objective__c === item
        )?.challengeObjective;
      });
    }
  }
});
const loadingRef = computed(() => {
  return (
    props.isLoading ||
    isGlobalUserInfoLoading.value ||
    isStartupListLoading.value ||
    isCorporateListLoading.value ||
    isStartupOpportunitiesLoading.value ||
    isAllChallengesListLoading.value
  );
});
import { useFilterUrlBind } from "@global/hooks/use-filter-url-bind.js";

useFilterUrlBind(filters, loadingRef);

const preSort = {
  sortField: "startupName",
  sortDirection: 1
};
const sort = ref(preSort);
const numberOfCards = useScreenHeight(85, 130);

const openSidebar = (e) => {
  e.originalEvent.stopImmediatePropagation();
  bus.emit("open-application-detail-sidebar", {
    opportunityId: e.data.id,
    challengeId: e.data.challengeId
  });
};

function reload() {
  challengeStore.actions.setEcosystemPublishedChallengesList();
}
import { useFilterModal } from "@/global/hooks/use-filter-modal.js";
const { showFilters } = useFilterModal();
const numberOfFilters = ref(0);
import { useFilterLogic } from "@global/hooks/use-filter-logic.js";
const { openFilterModal, forceExport } = useFilterLogic(filters, showFilters);

const showFilterss = () => {
  openFilterModal();
};
onMounted(() => {
  challengeStore.actions.setEcosystemPublishedChallengesList();
  setTimeout(() => {
    forceExport();
  }, 100);
  bus.on("filter-applied", (data) => {
    numberOfFilters.value = data;
  });
});
onUnmounted(() => {
  bus.off("filter-applied");
});
</script>
