import { createRouter, createWebHistory } from "vue-router";
import { GLOBAL_ROUTES } from "@/global/router.js";
import { MTBER_ROUTES } from "@/modules/mtbers/router.js";
import { CORPORATE_MOUDULE_ROUTES } from "@/modules/corporates/router.js";
import { STARTUP_MODULE_ROUTES } from "@/modules/startups/router.js";
import { INSTITUTION_MOUDULE_ROUTES } from "@/modules/institutions/router.js";
import { mixpanelMtbTracking } from "./mixpanel";
import debounce from "lodash/debounce";

export const routes = [
  ...MTBER_ROUTES,
  ...CORPORATE_MOUDULE_ROUTES,
  ...STARTUP_MODULE_ROUTES,
  ...INSTITUTION_MOUDULE_ROUTES,
  ...GLOBAL_ROUTES
];

export const createNewRouter = (
  history = createWebHistory(),
  routes = [
    ...MTBER_ROUTES,
    ...CORPORATE_MOUDULE_ROUTES,
    ...STARTUP_MODULE_ROUTES,
    ...INSTITUTION_MOUDULE_ROUTES,
    ...GLOBAL_ROUTES
  ]
) => {
  return createRouter({
    history,
    routes,
    scrollBehavior(to, from) {
      if (to.name === from.name) {
        return;
      }
      return {
        top: 0,
        behavior: "smooth"
      };
    }
  });
};
const router = createNewRouter();

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    setTimeout(() => {
      mixpanelMtbTracking({
        eventName: "Visited Page",
        properties: {
          visited_page_name: to.name,
          visited_page_path: to.path
        }
      });
    }, 500);
  }
});

export default router;
