<template>
  <div
    v-if="sectionIsVisible"
    class="mtb-card flex flex-col justify-between items-start flex-1 self-stretch xl:absolute static top-6 xl:h-[calc(100%-48px)] xl:w-[48%] xl:right-[24px] w-full"
  >
    <div
      class="scrolling-container p-8 flex flex-col flex-1 gap-4 self-stretch h-full"
    >
      <mtb-tab
        v-if="tabVisibility"
        :tabs-config="tabsConfig"
        classes="gap-4 mb-4"
      >
        <template #startup-portfolio>
          <div class="h-full overflow-y-scroll">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
              v-if="
                !!(
                  isGlobalUserInfoLoading ||
                  isInstitutionListLoading ||
                  isStartupListLoading
                )
              "
            >
              <base-card-small-skeleton v-for="n in 4" :key="n" />
            </div>
            <div v-else-if="mySponsoredStartups.length == 0">
              <fallback-upgrade
                message="You have no startups in your Ecosystem. Would you like to add them?"
              />
            </div>
            <div v-else-if="activeStartups.length == 0">
              <fallback-activate-sponsorship
                message="You haven't activated any premium slots for the startups in your Ecosystem yet."
                :route="PARTNER_STARTUPS_ROUTE_NAME"
              />
            </div>
            <div
              v-else
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
            >
              <base-card-small
                v-for="startup in activeStartups"
                :key="startup.companyId"
                :chip-config="getChip(startup.companyId, 'startup')"
                :companyId="startup.companyId"
                :sub-title="
                  accountsStore.state.accountFullDetails[startup.companyId]
                    ?.HQ_Country__c || ''
                "
                card-type="startup"
                :title="
                  accountsStore.state.accountFullDetails[startup.companyId].Name
                "
                :forceOpenSidebar="false"
                :forceRouting="
                  route.query._embed
                    ? {
                        name: 'startup-profile',
                        params: {
                          id: startup.companyId
                        }
                      }
                    : null
                "
                :isNew="false"
                :ctaButtonConfig="null"
              />
            </div>
          </div>
        </template>
        <template #corporate-partners>
          <div class="h-full overflow-y-scroll">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
              v-if="
                !!(
                  isGlobalUserInfoLoading ||
                  isInstitutionListLoading ||
                  isCorporateListLoading
                )
              "
            >
              <base-card-small-skeleton v-for="n in 4" :key="n" />
            </div>
            <div v-else-if="mySponsoredCorporates.length == 0">
              <fallback-upgrade
                message="You have no corporate partners in your Ecosystem. Would you like to add them?"
              />
            </div>
            <div v-else-if="activeCorporates.length == 0">
              <fallback-activate-sponsorship
                message="You haven't activated any premium slots for the corporates in your Ecosystem yet."
                :route="PARTNER_CORPORATES_ROUTE_NAME"
              />
            </div>
            <div
              v-else
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
            >
              <base-card-small
                v-for="corporate in activeCorporates"
                :key="corporate.companyId"
                :companyId="corporate.companyId"
                :chip-config="getChip(corporate.companyId, 'corporate')"
                :sub-title="
                  accountsStore.state.accountFullDetails[corporate.companyId]
                    ?.HQ_Country__c || ''
                "
                card-type="corporate"
                :title="
                  accountsStore.state.accountFullDetails[corporate.companyId]
                    .Name
                "
                :disabled="route.query._embed"
                :isNew="false"
                :ctaButtonConfig="null"
              />
            </div>
          </div>
        </template>
      </mtb-tab>
      <div
        v-else-if="activeCorporates?.length !== 0"
        class="h-full flex flex-col gap-4"
      >
        <span class="flex font-bold text-base text-primary mb-2 left-align">
          Corporate Partners
        </span>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 h-full overflow-y-scroll"
          style="height: calc(100% - 48px); overflow: auto"
          v-if="
            !!(
              isGlobalUserInfoLoading ||
              isInstitutionListLoading ||
              isCorporateListLoading
            )
          "
        >
          <base-card-small-skeleton v-for="n in 4" :key="n" />
        </div>
        <div
          v-else
          class="flex items-start content-start gap-4 flex-wrap flex-1 h-full overflow-y-scroll"
          style="height: calc(100% - 48px); overflow: auto"
        >
          <base-card-small
            v-for="corporate in activeCorporates"
            :key="corporate.companyId"
            :companyId="corporate.companyId"
            :chip-config="getChip(corporate.companyId, 'corporate')"
            :sub-title="
              accountsStore.state.accountFullDetails[corporate.companyId]
                ?.HQ_Country__c || ''
            "
            card-type="corporate"
            :title="
              accountsStore.state.accountFullDetails[corporate.companyId].Name
            "
            :disabled="route.query._embed"
            :isNew="false"
            :ctaButtonConfig="null"
          />
        </div>
      </div>
      <div
        v-else-if="activeStartups?.length !== 0"
        class="h-full flex flex-col gap-4"
      >
        <span class="flex font-bold text-base text-primary mb-2 text-left">
          Startup Portfolio
        </span>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
          style="overflow: auto"
          v-if="!!(isInstitutionListLoading || isStartupListLoading)"
        >
          <base-card-small-skeleton v-for="n in 4" :key="n" />
        </div>
        <div
          v-else
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-4 pb-2"
          style="overflow: auto"
        >
          <base-card-small
            v-for="startup in activeStartups"
            :key="startup.companyId"
            :chip-config="getChip(startup.companyId, 'startup')"
            :companyId="startup.companyId"
            :sub-title="
              accountsStore.state.accountFullDetails[startup.companyId]
                ?.HQ_Country__c || ''
            "
            card-type="startup"
            :title="
              accountsStore.state.accountFullDetails[startup.companyId]?.Name
            "
            :forceOpenSidebar="false"
            :forceRouting="
              route.query._embed
                ? {
                    name: 'startup-profile',
                    params: {
                      id: startup.companyId
                    }
                  }
                : null
            "
            :isNew="false"
            :ctaButtonConfig="null"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineAsyncComponent, watch, toRef } from "vue";
import { useAccountsStore } from "../stores/accounts-store.js";
import { useRoute } from "vue-router";
const FallbackActivateSponsorship = defineAsyncComponent(
  () =>
    import(
      "@/modules/institutions/components/fallback-activate-sponsorship.vue"
    )
);
import {
  PARTNER_CORPORATES_ROUTE_NAME,
  PARTNER_STARTUPS_ROUTE_NAME
} from "@/modules/institutions/router.js";
import { useLoading } from "../hooks/use-loading.js";
const {
  isGlobalUserInfoLoading,
  isInstitutionListLoading,
  isStartupListLoading,
  isCorporateListLoading
} = useLoading();
const route = useRoute();
const accountsStore = useAccountsStore();
const props = defineProps({
  baseRouteName: {
    type: String,
    required: true
  },
  institutionId: {
    type: String,
    required: true
  },
  isMyProfile: {
    type: Boolean,
    default: false
  }
});
const institutionId = toRef(props.institutionId);
import { useParnerSponsorship } from "@global/hooks/use-parner-sponsorship.js";
const {
  activeStartups,
  activeCorporates,
  mySponsoredStartups,
  mySponsoredCorporates,
  findStartup
} = useParnerSponsorship(institutionId);

const tabVisibility = computed(() => {
  return (
    //tabs are visible if the profile is mine or if there are some active sponsorships
    props.isMyProfile ||
    (activeCorporates.value?.length !== 0 && activeStartups.value?.length !== 0)
  );
});
const sectionIsVisible = computed(() => {
  //if it's my profile always visible
  if (props.isMyProfile) {
    return true;
  } else if (
    //if the institution has at least 1 active partnership
    activeCorporates.value.length !== 0 ||
    activeStartups.value.length !== 0
  ) {
    return true;
  } else {
    return false;
  }
});

function getChip(id, type) {
  const insdustrySector =
    accountsStore.state.accountFullDetails[id]?.Industry_Sector__c;
  const industryVertical =
    accountsStore.state.accountFullDetails[id]?.Industry_Verticals__c;
  const technologies =
    accountsStore.state.accountFullDetails[id]?.Technologies__c;
  const keywords = accountsStore.state.accountFullDetails[id]?.Keywords__c;
  const concatenatedString = [
    insdustrySector,
    industryVertical,
    technologies,
    keywords
  ]
    .filter((str) => str)
    .join(", ");
  if (!concatenatedString) {
    return null;
  }
  const formattedTecnologies = concatenatedString
    .replaceAll(";", ", ")
    .replaceAll("\n", ", ");
  return {
    label: formattedTecnologies,
    chipClasses: "bg-data1-light font-normal",
    icon: "ai-sparkle",
    iconClasses: "fill-data1 w-4 h-4"
  };
}

const tabsConfig = computed(() => {
  return [
    {
      label: "Startup Portfolio",
      id: "startup-portfolio",
      route: {
        name: props.baseRouteName,
        params: {
          ...(props.baseRouteName !== "institution-my-profile" && {
            id: props.institutionId
          }),
          section: "startup-portfolio"
        },
        query: route.query
      }
    },
    {
      label: "Corporate Partners",
      id: "corporate-partners",
      route: {
        name: props.baseRouteName,
        params: {
          ...(props.baseRouteName !== "institution-my-profile" && {
            id: props.institutionId
          }),
          section: "corporate-partners"
        },
        query: route.query
      }
    }
  ];
});
</script>
