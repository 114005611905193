import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
import { default as constants } from "@share/envConstant.js";

export async function getEcosystemPublishedChallenges(
  isDemoAccount,
  companyId,
  onlyPublic
) {
  try {
    const validationRules = {
      isDemoAccount: {
        presence: { allowEmpty: false },
        type: "boolean"
      }
    };
    const validationErrors = validate({ isDemoAccount }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const isTestAccount = constants.ECOSYSTEM_TEST_ACCOUNTS.includes(companyId);

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getChallengesInfo-getAllPublishedChallengesV3"
    );
    const payload = {
      isDemoAccount,
      isTestAccount,
      onlyPublic
    };

    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getChallengesFromCorporateId(
  corporateId,
  challengeStage
) {
  try {
    //validation
    const validationRules = {
      corporateId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ corporateId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const response = await httpsCallable(
      functionsEU,
      "getChallengesInfo-getAllCorporateChallengesV3"
    )({
      corporateId,
      challengeStage
    });
    return response?.data || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSingleChallengeInfo(data) {
  try {
    //validation
    const validationRules = {
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const payload = {
      challengeId: data.challengeId
    };

    const response = await httpsCallable(
      functionsEU,
      "corporatesChallengesV3-getChallenge"
    )(payload);

    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
