import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useAppStateStore } from "@/global/stores/app-state-store.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { getApplicationsLikes } from "../services/applications-service.js";
import { STATE_APPLICATIONS_LIKES } from "@/global/constants/loading-state.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";

import { encryptLocalStorage } from "@/plugins/pinia.js";

export const useStartupApplicationsStore = defineStore(
  "startup-applications",
  () => {
    const appStateStore = useAppStateStore();
    const opportunitiesStore = useOpportunitiesStore();
    const { errorManager } = userErrorHandling();
    const state = reactive({
      applicationsLikes: [],
      availableApplications: 0
    });

    const getters = reactive({
      getApplicationsLikes: computed(() => {
        if (!state.applicationsLikes) {
          return [];
        }
        return state.applicationsLikes;
      }),
      getAvailableApplications: computed(() => {
        // -------------VARIABLES THAT CAN BE MODIFIED ---------------------
        const maxPerTimePeriod = 10;
        const monthsInPeriod = 2;
        // -------------VARIABLES THAT CAN BE MODIFIED ---------------------

        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const thisTimePeriod = Math.ceil(currentMonth / monthsInPeriod);

        const startDate = new Date(
          now.getFullYear(),
          (thisTimePeriod - 1) * monthsInPeriod,
          1
        );
        const endDate = new Date(
          now.getFullYear(),
          thisTimePeriod * monthsInPeriod,
          0
        );

        const timePeriod = {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          monthsInPeriod: monthsInPeriod,
          thisTimePeriod: thisTimePeriod
        };

        const appliedTimePeriod =
          opportunitiesStore.state.startupOpportunities.filter(
            (opportunity) => {
              return (
                opportunity.leadSource === "Ecosystem - Startup Application" &&
                new Date(opportunity.appliedDate) >= startDate &&
                new Date(opportunity.appliedDate) <= endDate
              );
            }
          ).length;

        const availableTimePeriod = maxPerTimePeriod - appliedTimePeriod;

        return {
          timePeriod: timePeriod,
          maxPerTimePeriod: maxPerTimePeriod,
          appliedTimePeriod: appliedTimePeriod,
          availableTimePeriod: availableTimePeriod
        };
      })
    });

    const actions = reactive({
      //all application + challenge with opportunities + like + dislike
      setApplicationsLikes: async (startupId) => {
        try {
          appStateStore.actions.setStartProcess(STATE_APPLICATIONS_LIKES);
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD LIKES for user: ", startupId);
          }
          const applicationsLikes =
            (await getApplicationsLikes(startupId)) || [];
          state.applicationsLikes = applicationsLikes;
        } catch (error) {
          appStateStore.actions.setError(STATE_APPLICATIONS_LIKES, true);
          errorManager({
            error: error,
            functionPath: "setApplicationsLikes",
            userErrorMessage: "Unable to load applications likes"
          });
        } finally {
          appStateStore.actions.setLoading(STATE_APPLICATIONS_LIKES, false);
        }
      },
      reset: () => {
        state.applicationsLikes = null;
      }
    });

    return {
      state,
      actions,
      getters
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
