<template>
  <CascadeSelect
    v-model="selectedItem"
    :options="props.calendarOptions"
    :optionGroupLabel="props.optionGroupLabel"
    :optionGroupChildren="props.optionGroupChildren"
    :pt="cascadeselectPt"
    @click.stop
    @show="handleShow"
    @hide="handleHide"
    ref="cascadeSelect"
  >
    <template #value>
      <span></span>
    </template>
    <template #dropdownicon>
      <div class="flex flex-row items-center justify-center gap-2">
        <span
          v-if="props.label"
          :class="[
            `text-${props.severity}`,
            'text-base font-bold whitespace-nowrap',
            props.iconPos === 'right' ? 'order-1' : 'order-2'
          ]"
          >{{ props.label }}</span
        >
        <div
          :class="[
            'py-3 flex items-center justify-center',
            props.iconPos === 'right' ? 'order-2' : 'order-1',
            props.label.length == 0 ? 'w-[20px]' : 'w-auto'
          ]"
          @click="isMenuOpen = !isMenuOpen"
        >
          <base-vite-icon :name="props.dropdownicon" :classes="iconClasses" />
        </div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center gap-2">
        <base-vite-icon
          v-if="slotProps.option?.icon"
          :name="slotProps.option?.icon"
          :classes="slotProps.option?.iconClass"
        />
        <span :class="slotProps.option?.titleClass">{{
          slotProps.option.name
        }}</span>
        <base-vite-icon
          v-if="slotProps.option?.options"
          name="chevron-right"
          classes="w-4 h-4 stroke-2 stroke-grey2"
        />
      </div>
    </template>
    <template #optiongroupicon></template>
  </CascadeSelect>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import CascadeSelect from "primevue/cascadeselect";

const props = defineProps({
  calendarOptions: {
    type: Array,
    default: () => []
  },
  optionGroupLabel: {
    type: String,
    default: "name"
  },
  optionGroupChildren: {
    type: Array,
    default: () => ["options"]
  },
  dropdownicon: {
    type: String,
    default: "menu"
  },
  iconPos: {
    type: String,
    default: "right"
  },
  iconClasses: {
    type: String,
    default: "fill-none stroke-2 stroke-grey2 w-5 h-5"
  },
  panelClass: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  severity: {
    type: String,
    default: "black"
  },
  internalTableHeader: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue", "click", "hide", "show"]);
const cascadeSelect = ref(null);
const selectedItem = ref();

function handleShow() {
  emit("show");
}
function handleHide() {
  emit("hide");
  selectedItem.value = null;
}
const hide = () => {
  cascadeSelect.value?.hide();
};

watch(selectedItem, (newVal) => {
  emit("update:modelValue", newVal);
});

defineExpose({ hide });

const cascadeselectPt = {
  root: "border-none",
  label: "hidden",
  input: ({ props }) => {
    return {
      class: [
        "focus:outline-none focus:shadow-none", //important
        "text-ellipsis whitespace-nowrap capitalize",
        props.inputStyle
      ]
    };
  },
  panel: () => {
    return {
      class: ["rounded-2xl shadow-mtb-card bg-white"]
    };
  },
  wrapper: {
    class: [
      "inline-flex flex-col justify-start items-start items-start gap-5 overflow-auto",
      "bg-white rounded-2xl",
      !props.internalTableHeader && "pl-6 pr-14 py-6",
      props.internalTableHeader && "overflow-visible"
    ]
  },
  list: {
    class: [
      "list-none m-0 flex flex-col",
      props.internalTableHeader ? "gap-0" : "gap-5"
    ]
  },
  item: ({ context }) => {
    return {
      class: [
        "bg-transparent border-grey3",
        props.internalTableHeader &&
          "border-b-1 border-b last:border-0 hover:!bg-primary-light px-6 py-3 relative first:border-0 first:rounded-t-2xl last:rounded-b-2xl"
      ]
    };
  },
  content: ({ context }) => {
    return {
      class: [
        "cursor-pointer overflow-hidden relative whitespace-nowrap flex flex-row items-center gap-2",
        { "font-normal text-grey2": !context.focused },
        { "font-bold text-black bg-transparent": context.focused }
      ]
    };
  },
  sublist: () => {
    return {
      class: ["bg-white rounded-2xl mt-1"]
    };
  }
};
</script>

<style lang="scss" scoped></style>
