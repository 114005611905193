<template>
  <div
    v-click-outside="
      () => {
        visibleRight = false;
      }
    "
  >
    <Sidebar v-model:visible="visibleRight" position="right" @click.stop>
      <template #header>
        <div v-if="isLoading" class="flex items-center gap-3 mr-auto">
          <!-- Skeleton for header -->
          <Skeleton class="mb-2 h-8 w-8"></Skeleton>
          <Skeleton width="300px" class="h-6"></Skeleton>
        </div>
        <div
          v-else-if="challenge"
          class="flex items-center gap-3 justify-between w-full"
        >
          <div class="flex items-center gap-3 mr-auto">
            <base-vite-icon
              v-if="
                !isCorporateChallengeVisible(
                  {
                    Challenge_Visibility__c: challenge.Challenge_Visibility__c,
                    Challenge_Corporate_Sponsor__c:
                      challenge.Challenge_Corporate_Sponsor__c
                  },
                  opportunity
                )
              "
              :name="visibilityMapping[challenge.Challenge_Visibility__c].icon"
              :classes="`${
                visibilityMapping[challenge.Challenge_Visibility__c].iconClasses
              } h-7 w-7`"
            />
            <base-logo
              v-else
              classes="w-8 h-8"
              :companyId="challenge.Challenge_Corporate_Sponsor__c"
            />
            <p class="font-bold text-xl truncate w-[300px]">
              {{ challenge.Name }}
            </p>
          </div>

          <router-link
            v-if="openNewPage"
            :to="openNewPage"
            @click="visibleRight = false"
          >
            <base-vite-icon
              name="arrows-pointing-out"
              classes="stroke-black stroke-0 w-5 h-5 cursor-pointer me-3"
            />
          </router-link>
        </div>
      </template>
      <template #closeicon>
        <base-vite-icon name="close" classes="stroke-black stroke-2 w-6 h-6" />
      </template>
      <div v-if="isLoading">
        <!-- Skeleton for main content -->
        <div class="mt-4">
          <Skeleton width="30%" height="20px" class="mb-8"></Skeleton>
          <Skeleton width="50%" height="20px" class="mb-3"></Skeleton>
          <Skeleton width="75%" height="20px" class="mb-6"></Skeleton>
          <Skeleton width="50%" height="20px" class="mb-3"></Skeleton>
          <Skeleton width="75%" height="20px" class="mb-3"></Skeleton>
        </div>
      </div>
      <div v-else>
        <div v-if="!challenge && !application && !opportunity">
          <fallback-error :reload-visible="false" :isRow="false" />
        </div>
        <mtb-tab
          v-else
          :frontSpace="false"
          :tabs-config="tabsConfig"
          classes="mt-4"
          @currentActiveIndex="(e) => (activeIndex = e)"
          :defaultIndex="defaultTabIndex"
        >
          <template #filterButton>
            <div class="mr-4 flex" v-if="isApplyVisible">
              <!-- Filter button-->
              <base-button
                severity="primary"
                :text="true"
                label="
              Apply
            "
                icon="add"
                :iconClass="`w-4 h-4 stroke-2 stroke-primary
            `"
                :stopClick="false"
                @click="applyToChallenge(challenge)"
              />
            </div>
          </template>
          <template #challenge-info>
            <div class="flex flex-col gap-4 mt-8" v-if="challenge">
              <div
                v-if="
                  isCorporateChallengeVisible({
                    Challenge_Visibility__c: challenge.Challenge_Visibility__c,
                    Challenge_Corporate_Sponsor__c:
                      challenge.Challenge_Corporate_Sponsor__c
                  })
                "
                class="flex flex-col gap-1.5"
              >
                <p class="font-bold text-lg text-black">Challenge Owner</p>
                <p class="text-grey1 text-base flex items-center">
                  <base-logo
                    classes="h-8 w-8 me-2"
                    :companyId="challenge.Challenge_Corporate_Sponsor__c"
                  />
                  {{ challenge.Challenge_Corporate_Sponsor__r.Name }}
                </p>
                <div class="flex gap-1.5 items-center">
                  <base-vite-icon
                    name="globe"
                    classes="fill-primary stroke-0 w-4 h-4"
                  />
                  <a
                    class="text-start truncate w-[15rem] underline text-grey1 text-base"
                    :href="challenge.Challenge_Corporate_Sponsor__r?.Website"
                    target="_blank"
                    >{{ challenge.Challenge_Corporate_Sponsor__r?.Website }}</a
                  >
                </div>
              </div>

              <div class="flex flex-col gap-1.5">
                <p class="font-bold text-lg text-black">Status</p>
                <div>
                  <div
                    class="mtb-chip font-bold text-sm w-fit"
                    :class="
                      statusMapping[challenge?.Challenge_State__c].parentClasses
                    "
                  >
                    <span class="text-base font-normal text-left">{{
                      challenge["Challenge_State__c"]
                    }}</span>
                  </div>
                </div>
              </div>

              <div
                v-for="field in filteredFields"
                :key="field"
                class="flex flex-col gap-1.5"
              >
                <p class="font-bold text-lg text-black">
                  {{ field.label }}
                </p>

                <div v-if="field.id == 'Challenge_Objective__c'">
                  <div
                    :class="`mtb-chip text-sm ${
                      objectiveMapping[challenge[field.id]]?.parentClasses ||
                      objectiveMapping.default.parentClasses
                    }`"
                  >
                    <base-vite-icon
                      :name="
                        objectiveMapping[challenge[field.id]]?.icon ||
                        objectiveMapping.default.icon
                      "
                      :classes="`${
                        objectiveMapping[challenge[field.id]]?.iconClasses ||
                        objectiveMapping.default.iconClasses
                      } h-4 w-4`"
                    />{{
                      objectiveMapping[challenge[field.id]]?.label ||
                      challenge[field.id]
                    }}
                  </div>
                </div>
                <p class="text-grey1 text-base" v-else>
                  {{ formatValue(field, challenge[field.id]) }}
                </p>
              </div>
            </div>
          </template>
          <template #startup-application>
            <div class="mt-8 flex flex-col items-start justify-center gap-4">
              <div class="flex flex-col gap-1.5">
                <div class="flex gap-2 items-center">
                  <p class="font-bold text-lg text-black">
                    Why are you applying?
                  </p>
                  <base-vite-icon
                    v-if="isApplicationEditable"
                    role="button"
                    @click="applyToChallenge(challenge, application)"
                    name="edit"
                    classes="stroke-grey1 stroke-2 w-4 h-4"
                  />
                </div>

                <p
                  v-html="application.whyFit || '--'"
                  class="text-grey1 text-base"
                ></p>
              </div>
              <div
                class="flex flex-col gap-1.5"
                v-if="application.pitchdeckURL"
              >
                <div class="flex gap-2 items-center">
                  <p class="font-bold text-lg text-black">Pitch Deck</p>
                  <!-- TODO: add conditoon here to show -->
                  <base-vite-icon
                    v-if="isApplicationEditable"
                    role="button"
                    @click="applyToChallenge(challenge, application)"
                    name="edit"
                    classes="stroke-grey1 stroke-2 w-4 h-4"
                  />
                </div>

                <div class="inline-flex gap-2">
                  <base-vite-icon
                    name="document"
                    classes="stroke-primary stroke-2 w-4 h-4"
                  />

                  <a
                    @click="getPitchdeck(application.pitchdeckURL)"
                    role="button"
                    class="text-grey1 text-base underline"
                    target="_blank"
                    >Pitch-deck.pdf</a
                  >
                </div>
              </div>

              <base-comments-index
                :pitchdeck="false"
                :readOnly="true"
                :startupId="application.AccountId"
                :isSidebar="true"
                :opportunityId="application.Id"
                :selectedChallenge="challenge?.Id"
              />
            </div>
          </template>
          <template #startup-opportunity>
            <div class="flex gap-6 flex-col mt-6">
              <div class="flex gap-2 flex-col">
                <p class="font-bold text-lg text-black">Status</p>

                <base-chip
                  class="w-fit"
                  :chipConfig="
                    stageMapping.stageMappingConfig.stageName[
                      opportunity.StageName
                    ]
                  "
                />
              </div>
              <div
                class="flex gap-4"
                v-if="
                  opportunity.matchingCalendarStatus === 'ONLINE' &&
                  opportunity.stageName === 'Selected'
                "
              >
                <p class="font-bold text-lg text-black">Meeting Details</p>
                <base-button
                  v-if="
                    opportunity.matchingCalendarStatus === 'ONLINE' &&
                    opportunity.stageName === 'Selected'
                  "
                  :text="true"
                  icon="add"
                  iconClass="w-4 h-4 stroke-primary stroke-2"
                  severity="primary"
                  label="Schedule Meeting"
                  @click="goToReschedule(opportunity.Id, 'book')"
                />
              </div>

              <div
                v-if="opportunity.Ecosystem_Meeting_Date__c"
                class="flex gap-2 flex-col"
              >
                <p class="font-bold text-md text-black">Meeting Date</p>
                <p class="text-grey1 text-base">
                  {{
                    format(
                      new Date(opportunity.Ecosystem_Meeting_Date__c),
                      "dd MMM yyyy"
                    )
                  }}
                </p>
              </div>

              <div
                v-if="
                  opportunity.Ecosystem_Meeting_Link__c &&
                  opportunity.TimeScope !== 'In the past'
                "
                class="flex gap-4 flex-row items-center"
              >
                <p class="font-bold text-md text-black">Meeting Link</p>
                <!-- TODO: condition to render it -->
                <base-button
                  :text="true"
                  severity="primary"
                  label="Join Meeting"
                  @click="joinMeeting(opportunity.Ecosystem_Meeting_Link__c)"
                />
              </div>
              <!-- TODO: bring the logic of schedule meeting -->
            </div>

            <base-comments-index
              :opportunityId="opportunity.Id"
              :isSidebar="true"
              :startupId="opportunity.AccountId"
              :selectedChallenge="challenge.Id"
              :read-only="true"
            />
          </template>
          <template #startup-profile>
            <mtb-account-details
              :nameVisible="true"
              :isLoading="false"
              :accountType="startup.accountType"
              :accountDetails="startup.accountDetails"
              :accountMainContact="startup.accountMainContacts"
              :accountContacts="startup.accountContacts"
              :accountSponsorDetails="startup.accountSponsorDetails"
              :sidebar="true"
            />
          </template>
        </mtb-tab>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import Sidebar from "primevue/sidebar";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watchEffect,
  computed,
  watch
} from "vue";
import {
  visibilityMapping,
  statusMapping
} from "@global/configs/challenge-mapping.js";
import { getApplicationNotes } from "@/global/services/comments-service";
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { bus } from "@/main.js";
import { format } from "date-fns";
import { getSingleChallengeInfo } from "@/global/services/challenges-service.js";
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { checkDateStatus } from "@global/helpers/date.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { useChallengeDetails } from "../hooks/use-challenge-details.js";
import { objectiveMapping } from "../configs/challenge-mapping.js";
import { useUserAuthStore } from "@global/stores/user-auth-store.js";
import { getOpportunityPitchdeck } from "@global/services/pitchdeck-service.js";
import { getOpportunity } from "@global/services/opportunities-service.js";
import { auth } from "@/plugins/firebase.js";
import { useStartupApplicationModal } from "@startups/hooks/use-startup-application-modal.js";
const { showApplicationModal } = useStartupApplicationModal();
import { isCorporateChallengeVisible } from "@global/helpers/util.js";
import { formatMeetingObject } from "@global/helpers/formatObject.js";
import { getApp } from "firebase/app";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL
} from "firebase/storage";
import { useAccountDetail } from "@global/hooks/use-account-details.js";
import { getLastPitchDeck } from "@/global/services/accounts-service.js";
const { getAccountDetail } = useAccountDetail();
const activeIndex = ref(0);
const visibleRight = ref(false);
const challenge = ref(null);
const application = ref(null);
const opportunity = ref(null);
const startup = ref(null);
const challengeStore = useChallengesStore();
const opportunityStore = useOpportunitiesStore();
const userAuthStore = useUserAuthStore();
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const isLoading = ref(true);
const openNewPage = computed(() => {
  if (tabsConfig.value[activeIndex.value]?.id == "challenge-info") {
    if (
      !challenge.value?.Challenge_Corporate_Sponsor__r?.Id ||
      !challenge.value?.Id
    ) {
      return false;
    }

    return {
      name: `challenge-open`,
      params: {
        corporateId: challenge.value.Challenge_Corporate_Sponsor__r.Id,
        id: challenge.value.Id
      }
    };
  } else if (tabsConfig.value[activeIndex.value]?.id == "startup-profile") {
    if (!startup.value?.accountDetails?.Id) {
      return false;
    }
    return {
      name: `startup-profile`,
      params: {
        id: startup.value.accountDetails.Id
      }
    };
  }
  return false;
});
const tabsConfig = computed(() => {
  return [
    {
      label: "Challenge Info",
      id: "challenge-info"
    },
    ...(application.value
      ? [
          {
            label:
              isMyProfile.value && !userAuthStore.getters.isMtbUser
                ? "My Application"
                : "Startup Application",
            id: "startup-application"
          }
        ]
      : []),
    ...(opportunity.value
      ? [
          {
            label: "My Business Opportunity",
            id: "startup-opportunity"
          }
        ]
      : []),
    ...(startup.value
      ? [
          {
            label: "Startup Profile",
            id: "startup-profile"
          }
        ]
      : [])
  ];
});
const isMyProfile = computed(() => {
  return (
    auth &&
    application.value?.AccountId === userAuthStore.state?.accountInfo?.id
  );
});
const isApplyVisible = computed(() => {
  return (
    !application.value &&
    userAuthStore.state?.accountInfo?.type === "startup" &&
    !opportunity.value
  );
});
const isApplicationEditable = computed(() => {
  return (
    application.value &&
    challenge.value?.Challenge_State__c === "Open" &&
    //TODO: do we want to edit a application bookmarked?
    ["MTB Selected", "MTb Maybe", "Bookmarked"].includes(
      application.value.StageName
    )
  );
});

const { challengesField, formatValue, isMyCorporate } = useChallengeDetails();

const filteredFields = computed(() => {
  if (challenge.value) {
    return challengesField(challenge.value, true);
  }
  return [];
});

const defaultTabIndexRef = ref(null);
const defaultTabIndex = computed(() => {
  if (defaultTabIndexRef.value) {
    return tabsConfig.value.findIndex(
      (ele) => ele.id === defaultTabIndexRef.value
    );
  }
  if (application.value) {
    return tabsConfig.value.findIndex(
      (ele) => ele.id === "startup-application"
    );
  } else if (opportunity.value) {
    return tabsConfig.value.findIndex(
      (ele) => ele.id === "startup-opportunity"
    );
  } else {
    return tabsConfig.value.findIndex((ele) => ele.id === "challenge-info");
  }
});

onMounted(() => {
  setTimeout(() => {
    if (route.query?.c) {
      // visibleRight.value = true;
      // accountId.value = route.query?.a;
      getInfo({ challengeId: route.query?.c, opportunityId: route.query?.o });
    }
  }, 100);
  bus.on("open-application-detail-sidebar", async (data) => {
    getInfo(data);
    defaultTabIndexRef.value = data.defaultTab;
    router.replace({
      ...route,
      query: {
        ...route.query,
        //NOTE: application will be opportunity
        c: data.challengeId,
        o: data.opportunityId,
        s: data.accountId
      }
    });
  });
  bus.on("refresh-sidebar-application", () => {
    getInfo({
      challengeId: route.query?.c,
      opportunityId: route.query?.o,
      accountId: route.query?.s
    });
  });
});
async function checkPitchdeckExists(currentOpportunity) {
  if (userAuthStore.getters.isDemoAccount) {
    application.value.pitchdeckURL = "/0014U000036I4rQQAS/SunSpark_2023-07-20";
  } else {
    const startupId = currentOpportunity.Account?.Id;
    const startupName = currentOpportunity.Account?.Name;
    const challengeId = challenge.value?.Id;
    const appliedDate = currentOpportunity?.Submission_Date__c;

    if (startupName && challengeId && appliedDate) {
      const firebaseApp = getApp();
      const fileName = `/${startupId}/${startupName}_${challengeId}_${appliedDate}`;
      const storage = getStorage(firebaseApp, "gs://mtb_pitch-decks_opps");
      const fileRef = storageRef(storage, fileName);
      try {
        await getDownloadURL(fileRef);
        application.value.pitchdeckURL = fileName;
      } catch (error) {
        console.log("###error", error);
      }
    }
  }
}
const getInfo = async ({ challengeId, opportunityId, accountId }) => {
  try {
    isLoading.value = true;
    challenge.value = null;
    opportunity.value = null;
    application.value = null;
    visibleRight.value = true;
    const [selectedChallenge, selectedOpportunity, startupDetails] =
      await Promise.all([
        getSingleChallengeInfo({ challengeId }),
        //matchingCalendarStatus
        opportunityId
          ? getOpportunity({
              opportunityId,
              opportunityFields: [
                "StageName,Ecosystem_Meeting_Date__c, LeadSource,Id,Ecosystem_Meeting_Link__c,AccountId,Submission_Date__c"
              ]
            })
          : null,
        accountId ? getAccountProfile(accountId) : null
      ]);
    challenge.value = selectedChallenge;
    startup.value = startupDetails;
    const opportunityStage = [
      ...stageMapping.startupkanbanColumnMap["meeting"].includedStage,
      ...stageMapping.startupkanbanColumnMap["evaluation"].includedStage
    ];

    if (
      selectedOpportunity &&
      opportunityStage.includes(selectedOpportunity.StageName)
    ) {
      opportunity.value = formatMeetingObject(
        {
          ...selectedOpportunity,
          Account: selectedOpportunity.InterestedCompAcc__r
        },
        opportunityStore.state.allMatchingCalendars,
        "sidebar"
      );
    } else if (selectedOpportunity) {
      application.value = selectedOpportunity;
      application.value.whyFit = (
        await getApplicationNotes({
          accountId: userAuthStore.state.accountInfo?.id,
          opportunityId: selectedOpportunity.Id
        })
      )?.Text__c;
      checkPitchdeckExists(selectedOpportunity);
    }
  } catch (e) {
    console.log("error", e);
  } finally {
    isLoading.value = false;
  }
};
//TODO: needing to refresh the data
//put all to null
onBeforeUnmount(() => {
  bus.off("open-application-detail-sidebar");
});

const getPitchdeck = async (url) => {
  try {
    const DEMO_PITCH_DECK = "/0014U000036I4rQQAS/SunSpark_2023-07-20";

    const pitchDeckURL = userAuthStore.getters.isDemoAccount.value
      ? DEMO_PITCH_DECK
      : `/${url}`;

    await getOpportunityPitchdeck(pitchDeckURL, false);
  } catch (error) {
    throw error;
  }
};
const joinMeeting = (url) => {
  if (!url) return;
  window.open(encodeURI(url), "_blank");
};
function applyToChallenge(challenge, application) {
  showApplicationModal({
    currentApplication: application ? application : null,
    currentChallenge: { id: challenge.Id, name: challenge.Name }
  });
}

const goToReschedule = (opportunityId, action) => {
  router.push({
    name: "schedule Meeting",
    params: {
      action: action
    },
    query: {
      OPPID: opportunityId
      //TODO: we don't need it, can we remove it?
      // sfid: data.AccountDetail.index,
      // companyname: data.AccountDetail.name
    }
  });
};
watchEffect(() => {
  // NOTE: need to be separated, only whit && condition the watch observe only the condition result not the single variable
  const isVisible = visibleRight.value;
  const queryAccount = route.query?.c;
  if (isVisible && queryAccount) {
    return;
  }
  setTimeout(() => {
    //re-check, should be different
    if (visibleRight.value && route.query?.c) {
      return;
    }
    visibleRight.value = false;
    const updatedQuery = { ...route.query };

    delete updatedQuery?.c;
    delete updatedQuery?.o;
    delete updatedQuery?.s;
    defaultTabIndexRef.value = null;
    router.replace({
      ...route,
      query: updatedQuery
    });
  }, 500);
});
watch(
  () => challenge?.value?.Id,
  (val, oldVal) => {
    if (val && val !== oldVal) {
      mixpanelTrack();
    }
  },
  { immediate: true }
);
function isSuggestion() {
  //it's suggested if it came from the search or if we have some suggestions
  return !!(
    route.name === "search" ||
    challengeStore.state.startupSuggestedChallenges?.find(
      (item) => item.Id === challenge?.value?.Id
    )
  );
}
function mixpanelTrack() {
  const forMixpanel = {
    visitor_id: userAuthStore.state?.accountInfo?.id,
    visitor_name: userAuthStore.state?.accountInfo?.name,
    visitor_type: userAuthStore.state?.accountInfo?.type,
    visited_id: challenge.value?.Challenge_Corporate_Sponsor__c,
    visited_name: challenge.value?.Challenge_Corporate_Sponsor__r?.Name,
    challenge_id: challenge?.value?.Id,
    suggested: isSuggestion()
  };
  mixpanelMtbTracking({
    eventName: "Challenge Exposure",
    properties: forMixpanel
  });
}

async function getAccountProfile(id) {
  const [account, latestDeck] = await Promise.all([
    getAccountDetail(id, true),
    auth.currentUser ? getLastPitchDeck(id) : Promise.resolve([])
  ]);
  return {
    accountDetails: {
      ...account.accountDetails.value,
      pitchDeck: latestDeck
        ? `https://storage.googleapis.com/mtb_pitch-decks/${encodeURIComponent(
            latestDeck.name
          )}`
        : null
    },
    accountType: account.accountType.value,
    accountContacts: userAuthStore.getters.isMtbUser
      ? account.accountContacts.value
      : [],
    accountMainContacts: userAuthStore.getters.isMtbUser
      ? account.accountMainContact.value
      : [],
    accountSponsorDetails: account.accountSponsorDetails.value
  };
}
</script>

<style lang="scss" scoped></style>
