<template>
  <div
    class="relative flex justify-start items-start gap-2 h-full"
    :class="expanded && props.icons.length != 0 ? 'flex-wrap' : ''"
    :style="{ width: props.containerClasses }"
    v-click-outside="handleClickOutside"
  >
    <span
      ref="textRef"
      class="text-base"
      :class="[spanClasses, textClass, props.icons.length != 0 ? '' : 'w-full']"
    >
      <slot>{{ props.text }}</slot>
    </span>
    <div>
      <base-vite-icon
        role="button"
        data-table-no-select="true"
        v-if="isTruncated && props.size != 'fit-content'"
        name="arrows-pointing-out"
        classes="w-4 h-4 fill-black"
        @click.stop="expandText"
      />
    </div>
    <!-- Show the icon only if text is truncated -->

    <slot name="icons"> </slot>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, computed, toRefs } from "vue";

const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  containerClasses: {
    type: String,
    default: "200px"
  },
  size: {
    type: String,
    default: "whitespace-normal"
  },
  spanClasses: {
    type: String,
    default: ""
  },
  icons: {
    type: Array,
    default: () => []
  },
  editable: {
    type: Boolean,
    default: false
  },
  fieldIndex: {
    type: String,
    default: null
  },
  expandedFieldIndex: {
    type: String,
    default: null
  },
  isExpanded: {
    type: Boolean,
    default: false
  }
});
const emits = defineEmits(["expand", "close-expand"]);

const textRef = ref(null);
const isTruncated = ref(false);
const expanded = ref(props.isExpanded);
const { text } = toRefs(props);

const textWithoutHtml = computed(() => {
  console.log("@@@text", text);
  return text.value.replace(/<\/?[^>]+(>|$)/g, "");
});

const checkTruncation = () => {
  if (textRef.value) {
    isTruncated.value = textRef.value.scrollWidth > textRef.value.clientWidth;
  }
};

const expandText = () => {
  expanded.value = !expanded.value;

  if (expanded.value) {
    emits("expand", expanded.value, props.fieldIndex);
  } else {
    emits("close-expand");
  }
};

const textClass = computed(() => {
  if (expanded.value) {
    return "text-wrap";
  }

  switch (props.size) {
    case "short":
      return "whitespace-nowrap truncate self-center";
    case "fit-content":
      return "whitespace-normal truncate self-center";
    case "medium":
      return "whitespace-normal line-clamp-3 truncate self-center";
    default:
      return `${props.size} truncate`;
  }
});

const handleClickOutside = (event) => {
  if (props.expandedFieldIndex === props.fieldIndex) {
    // expanded.value = false;
    emits("close-expand");
  }
};

watch(
  () => props.containerClasses,
  (newClasses, oldClasses) => {
    if (newClasses !== oldClasses) {
      checkTruncation();
    }
  }
);

watch(
  () => props.isExpanded,
  (newVal) => {
    expanded.value = newVal;
  }
);
onMounted(() => {
  checkTruncation();
});
</script>

<style scoped>
/* No additional CSS required as Tailwind handles truncation */
</style>
